import React, { useEffect, useState } from 'react';
// import './tradingview.css';
import { useParams } from 'react-router';
import useLocalStorage from 'use-local-storage';
import { useStoreApi } from '../../../store/storeApi';
import datafeed from './Datafeed';
import { widget } from '../../../charting_library';
import CryptoOverview from '../CryptoOverview';
import { setFeeLinesInDB } from '../../../shared/commonUtils';
import { drawSpread, toggleSpreadVisibility } from '../../../shared/charting';

export default function TvChart(props) {
    const { tokenAddress, trade, tradeleft, activePair, reloadChart } = useStoreApi();
    // const [theme, setTheme] = useLocalStorage('theme', 'dark' ? 'Dark' : 'Light');
    const data = useParams();

    // const lightThemeOverrides = {
    //     'paneProperties.background': '#F7F4ED',
    //     'paneProperties.vertGridProperties.color': '#EBE9E1',
    //     'paneProperties.horzGridProperties.color': '#EBE9E1',
    //     'scalesProperties.textColor': '#586e75',
    //     'mainSeriesProperties.candleStyle.upColor': 'rgb(63, 182, 139)',
    //     'mainSeriesProperties.candleStyle.downColor': '#ec605a',
    //     'scalesProperties.fontSize': 12,
    //     volumePaneSize: 'small'
    // };
    // const darkThemeOverrides = {
    //     'paneProperties.background': '#1c1c27',
    //     'paneProperties.backgroundType': 'solid',
    //     'paneProperties.vertGridProperties.color': '#21212f',
    //     'paneProperties.horzGridProperties.color': '#21212f',
    //     'scalesProperties.textColor': '#c3c2d4',
    //     'mainSeriesProperties.candleStyle.upColor': 'rgb(63, 182, 139)',
    //     'mainSeriesProperties.candleStyle.downColor': '#ec605a',
    //     'mainSeriesProperties.candleStyle.borderUpColor': 'rgb(63, 182, 139)',
    //     'mainSeriesProperties.candleStyle.borderDownColor': '#ec605a',
    //     'paneProperties.topMargin': 10,
    //     'paneProperties.bottomMargin': 7,
    //     'scalesProperties.fontSize': 11,
    //     volumePaneSize: 'small',
    //     'paneProperties.axisProperties.autoScale': true
    // };
    const getTvChart = () => {
        const interval = localStorage.getItem('tvInterval') || '1440'; // shouldn't this be dynamic?
        localStorage.setItem('checked', false);
        const widgetOptions = {
            autosize: true,
            datafeed,
            charts_storage_api_version: '1.1',
            charts_storage_url: 'https://saveload.tradingview.com',
            client_id: 'tradingview.com',
            container: 'tv_chart_container',
            // toolbar_bg: theme === 'dark' ? '#1c1c27' : '#EBE9E1',
            // toolbar_bg: '#1c1c27',
            data_status: 'streaming',
            loading_screen: { backgroundColor: '#1c1c27' },
            allow_symbol_change: true,
            disabled_features: [
                'use_localstorage_for_settings',
                'border_around_the_chart',
                'header_symbol_search',
                'symbol_search_hot_key',
                'header_compare',
                'popup_hints',
                'control_bar',
                'go_to_date',
                'header_saveload',
                'time_frames',
                'timeframes_toolbar'
            ],
            enabled_features: ['hide_left_toolbar_by_default', 'side_toolbar_in_fullscreen_mode', 'header_in_fullscreen_mode'],
            custom_css_url: './tradingview.css',
            fullscreen: false,
            interval,
            library_path: '../../charting_library/',
            locale: 'en',
            studies_overrides: undefined,
            symbol: `${data.id}-bsc`,
            // theme: theme === 'dark' ? 'Dark' : 'Light',
            theme: 'Dark',
            user_id: 'public_user_id',
            timeframe: '6M',
            overrides: {
                'paneProperties.background': '#1c1c27',
                'paneProperties.backgroundType': 'solid',
                // 'paneProperties.vertGridProperties.color': '#21212f',
                // 'paneProperties.horzGridProperties.color': '#21212f'
                // 'scalesProperties.textColor': '#c3c2d4',
                'mainSeriesProperties.candleStyle.upColor': 'rgb(63, 182, 139)',
                'mainSeriesProperties.candleStyle.downColor': '#ec605a',
                'mainSeriesProperties.candleStyle.borderUpColor': 'rgb(63, 182, 139)',
                'mainSeriesProperties.candleStyle.borderDownColor': '#ec605a',
                'paneProperties.topMargin': 10,
                'paneProperties.bottomMargin': 7,
                'scalesProperties.fontSize': 11
                // volumePaneSize: 'small',
                // 'paneProperties.axisProperties.autoScale': true
            }
        };

        // var tvChart = new TradingView.widget(option);
        // tvChart.onChartReady(function () {
        //     tvChart.addCustomCSSFile('css/my-custom-css.css');
        // });
        const tvWidget = new widget(widgetOptions);
        window.tvWidget = tvWidget;
        tvWidget.onChartReady(() => {
            tvWidget.headerReady().then(() => {
                // tvWidget.addCustomCSSFile('./tradingview.css');
                const button = tvWidget.createButton();
                button.setAttribute('title', 'Click to show a notification popup');
                button.classList.add('apply-common-tooltip');
                button.addEventListener('click', () =>
                    tvWidget.showNoticeDialog({
                        title: 'We are getting the charts ready!',
                        body: 'Production-ready charts are in the final stages of development. Thank you for your patience.',
                        callback: () => {
                            // do nothing
                        }
                    })
                );
                // button.innerHTML = `${activePair.symbol0}/USD`;
                button.textContent = 'Chart info';
            });
            tvWidget.headerReady().then(() => {
                const button = tvWidget.createButton();
                button.setAttribute('title', 'Toggle buy/sell tax spread');
                button.addEventListener('click', () => {
                    toggleSpreadVisibility();
                });
                button.textContent = 'Show spread';
            });
            // tvWidget.headerReady().then(() => {
            //     const button = tvWidget.createButton();
            //     button.setAttribute('title', 'Toggle trades');
            //     button.addEventListener('click', () => {
            //         alert('Kage');
            //     });
            //     button.textContent = 'Plot trades';
            // });
            // tvWidget.chart().applyOverrides(darkThemeOverrides);
            tvWidget
                .activeChart()
                .onIntervalChanged()
                .subscribe(null, (interval, timeframeObj) => {
                    localStorage.setItem('tvInterval', interval);
                });
            // tvWidget.addCustomCSSFile('./tradingview.css');
            setFeeLinesInDB([]);
            drawSpread(window.tvWidget.latestClose);
        });
    };

    // useEffect(() => {
    //     if (props.data) {
    //         console.log('window.tvWidget', window.tvWidget);
    //         const theme = props.data === 'dark' ? 'Dark' : 'Light';
    //         const overrides = props.data === 'Dark' ? darkThemeOverrides : lightThemeOverrides;
    //         if (window.tvWidget) {
    //             try {
    //                 window.tvWidget.changeTheme(theme)?.then(() => {
    //                     console.log('then isdsd');
    //                     window.tvWidget.chart().applyOverrides(overrides);
    //                 });
    //             } catch (err) {
    //                 console.log(err);
    //             }
    //         }
    //     }
    // }, [props.data]);

    useEffect(() => {
        if (activePair.dex === 'nullswap') return;
        getTvChart('to');
    }, [activePair, reloadChart]);

    let divClass = 'col-8 pl-0 graph';
    if (trade && tradeleft) {
        // both
        divClass = 'col-7 pl-0 pr-0 graph';
    } else if (trade) {
        divClass = 'col-8 pl-0 pr-0 graph';
    } else if (tradeleft) {
        divClass = 'col-10 pl-0 pr-0 graph';
    } else {
        divClass = 'col-12 pl-0 pr-0 graph';
    }

    return (
        <div className="chartwrapper">
            <div id="tv_chart_container" className="TVChartContainer" />
        </div>
    );
}
