import React, { useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { useStoreApi } from '../../store/storeApi';
import { BSC_URL } from '../../shared/ApiURL';
import { fetchTopTraders, numberWithCommas } from '../../shared/commonUtils';

function BiggestSellers() {
    const [bigSellers, setBigSellers] = useState({});
    const { activePair, tokenAddress, tokenprice } = useStoreApi();
    // useEffect(() => {
    //     if (activePair !== undefined) {
    //         const myHeaders = new Headers();
    //         myHeaders.append('Content-Type', 'application/json');
    //         fetchTopTraders(activePair.pair.address, '2022-09-01T23:59:59', '2022-10-21T23:59:59')
    //             .then((result) => setBigSellers(result.topSellers))
    //             .catch((error) => console.log('error', error));
    //     }
    // }, [activePair]);

    const renderBody = () => {
        if (Array.isArray(bigSellers))
            try {
                return bigSellers?.map((sellers) => {
                    // const avatarSource = createAvatar(style, {
                    //     seed: sellers.transaction.txFrom.address,
                    //     dataUri: true
                    // });
                    const txLink = `${BSC_URL}token/${tokenAddress}?a=${sellers.transaction.txFrom.address}`;
                    return (
                        <tr key={sellers.transaction.hash}>
                            <td
                                style={{
                                    color: 'var(--text-base)',
                                    fontWeight: '100',
                                    fontFamily: 'monotalic',
                                    textTransform: 'uppercase'
                                }}
                            >
                                {/* <img height="10px" width="10px" alt="avatar" src={avatarSource} className="mr-2" /> */}

                                <a href={txLink} target="_blank" rel="noreferrer" className="blue-hover" style={{ cursor: 'pointer' }}>
                                    {sellers.transaction.txFrom.address}
                                </a>
                            </td>
                            <td
                                style={{
                                    color: 'var(--text-base)',
                                    fontWeight: '100',
                                    fontFamily: 'monotalic',
                                    textTransform: 'uppercase'
                                }}
                            >
                                ${numberWithCommas(sellers.sellAmount.toFixed(2))}
                            </td>
                            {/* <td
                                style={{
                                    color: 'var(--text-green)',
                                    fontWeight: '100',
                                    fontFamily: 'monotalic',
                                    textTransform: 'uppercase'
                                }}
                            >
                                ${numberWithCommas((sellers.sellAmount * tokenprice).toFixed(2))}
                            </td> */}
                            {/* <td
                                style={{
                                    color: 'var(--text-base)',
                                    fontWeight: '100',
                                    fontFamily: 'monotalic',
                                    textTransform: 'uppercase'
                                }}
                            >
                                {buyers.count}
                            </td> */}
                            {/* <td
                                style={{
                                    color: 'var(--text-muted)',
                                    fontWeight: '100',
                                    fontFamily: 'monotalic',
                                    textTransform: 'uppercase'
                                }}
                            >
                                {buyers.max_date}
                            </td> */}
                        </tr>
                    );
                });
            } catch (e) {
                console.log(e);
            }
    };

    return (
        <div className="table-responsive txtable">
            {/* <table className="table table-hover table-sm text-muted">
                <thead className="tp-head sticky-top botshadow">
                    <tr>
                        <th style={{ fontWeight: '100', fontSize: '14px' }}>Wallet </th>
                        <th style={{ fontWeight: '100', fontSize: '14px' }}>
                            Total sold{' '}
                            <Badge bg="darkgrey" style={{ color: 'var(--text-base)', fontWeight: '400' }}>
                                {activePair.symbol0}
                            </Badge>
                        </th>
                        <th style={{ fontWeight: '100', fontSize: '14px' }}>Value </th>
                        <th style={{ fontWeight: '100', fontSize: '14px' }}># of trades</th>
                        <th style={{ fontWeight: '100', fontSize: '14px' }}>Last trade</th>
                    </tr>
                </thead>
                <tbody className="bg-darker">
                    
                    {renderBody()}
                </tbody>
            </table> */}
            <div className="fucking-center">
                <h3 className="subtlebigh3">Sellers overview next up</h3>
            </div>
        </div>
    );
}

export default React.memo(BiggestSellers);
