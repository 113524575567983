import React, { useEffect, useState } from 'react';
import { Col, Row, Button, ProgressBar } from 'react-bootstrap';
import { getProgressDataFromDb } from '../../shared/commonUtils';
import { useStoreApi } from '../../store/storeApi';

export default function DisplayVote(props) {
    const { tokenAddress, token } = useStoreApi();
    console.log('props data=======>', props);
    const [voteData, setVoteData] = useState(null);
    const getProgressData = async () => {
        if (tokenAddress) {
            const res = await getProgressDataFromDb(tokenAddress);
            setVoteData(res);
        }
    };

    useEffect(() => {
        getProgressData();
    }, [tokenAddress]);
    return (
        <Col xl={12}>
            <Row
                className="d-flex align-items-center justify-content-center votebar"
                style={{
                    boxShadow: 'none',
                    borderRadius: '12px'
                    // marginTop: "12px",
                    // marginBottom: "12px",
                }}
            >
                <Col xl={12}>
                    <h5 className="display-6 text-center mt-3">You've voted {props.vote.get('voteType') == '1' ? '👍' : '👎 '}</h5>
                    <p className="text-muted text-center mb-0">Update how you feel about {token} tomorrow!</p>
                </Col>
                <Col xl={11} className="mb-3">
                    {voteData ? (
                        <ProgressBar className="mt-2 p-0">
                            <ProgressBar variant="success" now={voteData.goodPercent} key={1} label={`Great ${voteData.goodPercent}%`} />
                            <ProgressBar variant="danger" now={voteData.badPercent} key={2} label={`Meh ${voteData.badPercent}%`} />
                        </ProgressBar>
                    ) : null}
                </Col>
            </Row>
        </Col>
    );
}
