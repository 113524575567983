import React from 'react';
import * as RiIcons from 'react-icons/ri';
import * as FiIcons from 'react-icons/fi';
import { Badge } from 'react-bootstrap';

export const NavigationData = [
    {
        title: 'Charts',
        path: '/token/0x43a0c5eb1763a211aa3c05849a617f2ee0452767/PDX',
        // icon: <RiIcons.RiLineChartLine color="#E0775A" size={20} style={{ marginTop: "-10" }} />,
        cname: 'nav-text',
        name: ''
    }
    // {
    //     title: 'Swap',
    //     path: '/swap',
    //     // icon: <RiIcons.RiLineChartLine color="#E0775A" size={20} style={{ marginTop: "-10" }} />,
    //     cname: 'nav-text',
    //     name: ''
    // }
    // {
    //     title: 'Portfolio',
    //     // bagde: <Badge bg="secondary">New</Badge>,
    //     path: '/',
    //     // icon: <RiIcons.RiBubbleChartLine color="#E0775A" size={20} style={{ marginTop: "-10" }} />,
    //     cname: 'nav-text'
    // },
    // {
    //     title: 'Discover',
    //     path: '/discover',
    //     // icon: <FiIcons.FiHome color="#E0775A" size={20} style={{ marginTop: "-10" }} />,
    //     cname: 'nav-text',
    //     name: ''
    // }
    // {
    //     title: 'Converter',
    //     path: '/converter',
    //     // icon: <FiIcons.FiHome color="#E0775A" size={20} style={{ marginTop: "-10" }} />,
    //     cname: 'nav-text',
    //     name: ''
    // }
    // {
    //     title: 'More',
    //     path: '/tools',
    //     // icon: <RiIcons.RiBubbleChartLine color="#E0775A" size={20} style={{ marginTop: "-10" }} />,
    //     cname: 'nav-text'
    // }
    // {
    //     title: 'Buy BNB',
    //     path: '/ramp',
    //     cname: 'nav-text',
    //     name: 'sell_fiat'
    // }
    // {
    //   title: "Report bug",
    //   path: "",
    //   cname: "nav-text",
    //   name: "report",
    // },
];
