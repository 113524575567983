import { useEffect, useState, useRef } from 'react';
import { Col, Container, OverlayTrigger, ListGroup, Badge, Tooltip, Row } from 'react-bootstrap';
import { TiWarningOutline, TiInputChecked } from 'react-icons/ti';
import { GoVerified, GoUnverified } from 'react-icons/go';
import { FaInfoCircle, FaGasPump } from 'react-icons/fa';
import { BiLinkExternal } from 'react-icons/bi';
import { useStoreApi } from '../../store/storeApi';
import { getEthInstance } from '../../shared/commonUtils';
import { PROXY_URL } from '../../shared/ApiURL';

export default function ContractCheckup() {
    const { buyFee, sellFee, activePair, tokenAddress } = useStoreApi();
    const [isVerified, setIsVerified] = useState();
    const [isHoneypot, setIsHoneypot] = useState();
    const [owner, setOwner] = useState();
    const [isRenounced, setIsRenounced] = useState(false);
    const deadWallets = {
        1: '0x000000000000000000000000000000000000dEaD',
        2: '0x0000000000000000000000000000000000000000',
        3: '0x000000000000000000000000000000000000dead'
    };

    // checks if contract is verified
    useEffect(() => {
        const checkVerification = async () => {
            await tokenAddress;
            if (tokenAddress === null) return;

            await fetch(`${PROXY_URL}/is-valid-contract?token=${tokenAddress}`).then((res) =>
                res.json().then((data) => setIsVerified(data.isValid))
            );
        };
        checkVerification();
    }, [tokenAddress]);

    // checks from honeypot
    useEffect(() => {
        const honeyPotCheck = async () => {
            fetch(`${PROXY_URL}/honeypot/rugcheck?type=pancakeswap&address2=default&tokenAddress=${tokenAddress}`)
                .then((res) => res.json())
                .then((data) => {
                    if (data.data && tokenAddress) {
                        setIsHoneypot(data.data);
                        // console.log(data.data);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        };
        honeyPotCheck();
    }, [tokenAddress]);

    // sets owner
    useEffect(() => {
        const getContractOwner = async () => {
            const instance = await getEthInstance();
            const abi = [
                {
                    constant: true,
                    inputs: [],
                    name: 'owner',
                    outputs: [
                        {
                            name: '',
                            type: 'address'
                        }
                    ],
                    payable: false,
                    type: 'function'
                },
                {
                    inputs: [],
                    payable: false,
                    type: 'constructor'
                }
            ];
            try {
                if (tokenAddress === null) {
                    await tokenAddress;
                }
                if (tokenAddress !== null) {
                    const contractObject = await new instance.eth.Contract(abi, tokenAddress);
                    const activeContract = await contractObject.methods.owner().call().then(setOwner);
                }
            } catch (err) {
                if (err.message.includes('error')) {
                    console.log(err);
                    setOwner('N/A (ERC20 standard not followed)');
                }
            }
        };
        getContractOwner();
    }, [tokenAddress]);

    // supposed to check if ownership is renounced; fail
    useEffect(() => {
        const ownershipStatus = async () => {
            await owner;
            // console.log(deadWallets[1]);
            for (const i = deadWallets; i < deadWallets.lenght; i++) {
                if (owner === deadWallets[i]) {
                    setIsRenounced(true);
                }
            }
        };
        ownershipStatus();
    }, [owner]);

    return (
        <Container fluid className="py-0 tokendetails" style={{ paddingRight: '25px' }}>
            <Row className="mt-0 pt-0 pb-1 tokendetailsrow">
                <ListGroup variant="dark" className="p-0">
                    <ListGroup.Item className="sidebar-listitem">
                        <div>
                            <span className="std-info">Contract</span>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltop-right">Checks if the Smart Contract source code has been verified</Tooltip>}
                            >
                                <span>
                                    <FaInfoCircle className="panel-info" style={{ zIndex: '999', cursor: 'pointer', marginLeft: '5px' }} />
                                </span>
                            </OverlayTrigger>
                        </div>
                        <div>
                            <span>
                                {isVerified === true ? (
                                    <span style={{ color: 'var(--text-green)', fontWeight: '400', fontSize: '13px' }}>
                                        <GoVerified style={{ marginTop: '-9px', marginLeft: '-3px', marginRight: '5px' }} size={15} />
                                        Verified
                                    </span>
                                ) : (
                                    <span style={{ color: 'var(--text-red)', fontWeight: '400', fontSize: '13px' }}>
                                        <GoUnverified style={{ marginTop: '-9px', marginLeft: '-3px', marginRight: '5px' }} size={15} />
                                        Not verified
                                    </span>
                                )}
                            </span>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="sidebar-listitem">
                        <div className="std-info">
                            Honeypot
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltop-right">
                                        Checks if the token is sellable by simulating a trade. Just because a contract is not a honeypot
                                        now, does not mean it cant turn into one.
                                    </Tooltip>
                                }
                            >
                                <span>
                                    <FaInfoCircle className="panel-info" style={{ zIndex: '999', cursor: 'pointer', marginLeft: '5px' }} />
                                </span>
                            </OverlayTrigger>
                        </div>
                        <div>
                            <span>
                                {isHoneypot?.isHoneypot === true ? (
                                    <span style={{ color: 'var(--text-red)', fontWeight: '400', fontSize: '13px' }}>
                                        <GoUnverified style={{ marginTop: '-9px', marginLeft: '-3px', marginRight: '5px' }} size={15} />
                                        Looks like it
                                    </span>
                                ) : (
                                    <span style={{ color: 'var(--text-green)', fontWeight: '400', fontSize: '13px' }}>
                                        <GoVerified style={{ marginTop: '-9px', marginLeft: '-3px', marginRight: '5px' }} size={15} />
                                        {activePair.symbol0} is sellable
                                    </span>
                                )}
                            </span>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="sidebar-listitem">
                        <div className="std-info">
                            Owner
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltop-right">
                                        Checks wether owner has renounced ownership of the contract. For community tokens this is common
                                        practise; most DeFi and utility tokens do not renounce ownership.
                                    </Tooltip>
                                }
                            >
                                <span>
                                    <FaInfoCircle className="panel-info" style={{ zIndex: '999', cursor: 'pointer', marginLeft: '5px' }} />
                                </span>
                            </OverlayTrigger>
                        </div>
                        <div className="std-info">
                            <span>
                                {owner !== undefined ? (
                                    <a className="std-info" style={{ color: 'var(--text-base)', textDecoration: 'none' }} href="loshad">
                                        {(owner || '').slice(0, 6)}...{(owner || '').slice(-4)}
                                        <BiLinkExternal style={{ marginTop: '-10px', marginLeft: '3px' }} />
                                    </a>
                                ) : (
                                    <div className="std-info">No owner found</div>
                                )}{' '}
                            </span>
                        </div>
                    </ListGroup.Item>

                    <ListGroup.Item className="sidebar-listitem">
                        <div className="std-info">
                            Buy fee
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltop-right">
                                        Show how much {activePair.symbol0} is "taxed" on each transaction. Tokens with fees above 25% are
                                        most likely scams.
                                    </Tooltip>
                                }
                            >
                                <span>
                                    <FaInfoCircle className="panel-info" style={{ zIndex: '999', cursor: 'pointer', marginLeft: '5px' }} />
                                </span>
                            </OverlayTrigger>
                        </div>
                        <div>
                            <Badge
                                className="std-info"
                                style={{ fontWeight: '100', fontSize: '13px', color: 'var(--text-base)' }}
                                bg="primary"
                            >
                                {isHoneypot?.buyFee}%
                            </Badge>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="sidebar-listitem">
                        <div className="std-info">
                            Sell fee
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltop-right">
                                        Show how much {activePair.symbol0} is "taxed" on each transaction. Tokens with fees above 25% are
                                        most likely scams.
                                    </Tooltip>
                                }
                            >
                                <span>
                                    <FaInfoCircle className="panel-info" style={{ zIndex: '999', cursor: 'pointer', marginLeft: '5px' }} />
                                </span>
                            </OverlayTrigger>
                        </div>
                        <div>
                            <Badge
                                className="std-info"
                                style={{ fontWeight: '100', fontSize: '13px', color: 'var(--text-base)' }}
                                bg="primary"
                            >
                                {isHoneypot?.sellFee}%
                            </Badge>
                        </div>
                    </ListGroup.Item>
                    {/* <ListGroup.Item
                        className="d-flex justify-content-between align-items-start pl-1 pr-1"
                        style={{ fontSize: '13px', fontWeight: '400' }}
                    >
                        <div>
                            <span style={{ fontWeight: '100' }}>Max tx size</span>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltop-right">
                                        Some tokens have a max transaction limit as an "anti-whale" mechanism.
                                    </Tooltip>
                                }
                            >
                                <span>
                                    <FaInfoCircle className="panel-info" style={{ zIndex: '999', cursor: 'pointer', marginLeft: '5px' }} />
                                </span>
                            </OverlayTrigger>
                        </div>
                        <div>
                            <span style={{ fontWeight: '100' }}>150,000 {activePair.symbol0}</span>
                        </div>
                    </ListGroup.Item> */}
                    <ListGroup.Item className="sidebar-listitem">
                        <div>
                            <span className="std-info">Buy Gas</span>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltop-right">
                                        Our Smart Contract is simulating a trade to determine gas. This can be useful to assess the safety
                                        of a token.
                                    </Tooltip>
                                }
                            >
                                <span className="std-info">
                                    <FaInfoCircle className="panel-info" style={{ zIndex: '999', cursor: 'pointer', marginLeft: '5px' }} />
                                </span>
                            </OverlayTrigger>
                        </div>
                        <div style={{ fontWeight: '400' }}>
                            <FaGasPump
                                style={{ marginTop: '-8.5px', marginRight: '2px', marginLeft: '-1px', color: 'var(--text-base)' }}
                                size={12}
                            />{' '}
                            {isHoneypot?.buyGas} GWEI
                            {/* Buy fee: {buyFee}%, Sell fee: {sellFee}%{' '} */}
                            {/* <Badge style={{ fontSize: '13px', fontWeight: '400', marginLeft: '5px', color: 'var(--text-base)' }}>
                                    <TiWarningOutline style={{ marginTop: '-8px', marginLeft: '-2px' }} size={15} /> Modifiable
                                </Badge> */}
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="sidebar-listitem">
                        <div className="std-info">
                            Sell Gas
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltop-right">
                                        Our Smart Contract is simulating a trade to determine gas. This can be useful to assess the safety
                                        of a token.
                                    </Tooltip>
                                }
                            >
                                <span>
                                    <FaInfoCircle className="panel-info" style={{ zIndex: '999', cursor: 'pointer', marginLeft: '5px' }} />
                                </span>
                            </OverlayTrigger>
                        </div>
                        <div style={{ fontWeight: '400' }}>
                            <FaGasPump
                                style={{ marginTop: '-8.5px', marginRight: '2px', marginLeft: '-1px', color: 'var(--text-base)' }}
                                size={12}
                            />{' '}
                            {isHoneypot?.sellGas} GWEI
                            {/* Buy fee: {buyFee}%, Sell fee: {sellFee}%{' '} */}
                            {/* <Badge style={{ fontSize: '13px', fontWeight: '400', marginLeft: '5px', color: 'var(--text-base)' }}>
                                    <TiWarningOutline style={{ marginTop: '-8px', marginLeft: '-2px' }} size={15} /> Modifiable
                                </Badge> */}
                        </div>
                    </ListGroup.Item>
                    {/* <ListGroup.Item
                    className="d-flex justify-content-between align-items-start pl-1 pr-1"
                    style={{ fontSize: '13px', fontWeight: '400' }}
                >
                    <div>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltop-right">
                                    Checks wether owner has renounced ownership of the contract. For community tokens this is common
                                    practise; most DeFi and utility tokens do not renoounce ownership.
                                </Tooltip>
                            }
                        >
                            <span>
                                <FaInfoCircle className="panel-info" style={{ zIndex: '999', cursor: 'pointer' }} />
                            </span>
                        </OverlayTrigger>
                        Renounced
                    </div>
                    <div>
                        <span style={{ color: 'var(--color-orange)', fontWeight: '400' }}>
                            <TiWarningOutline style={{ marginTop: '-10px', marginLeft: '-3px', marginRight: '3px' }} size={16} />
                            Not renounced
                        </span>
                    </div>
                </ListGroup.Item> */}

                    {/* <ListGroup.Item
                    className="d-flex justify-content-between align-items-start pl-1 pr-1"
                    style={{ fontSize: '13px', fontWeight: '400' }}
                >
                    <div>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltop-right">
                                    The token owners can stop the trades anytime. This feature is often used during token ICO or as an
                                    emergency stop in case of a serious bug. Check with the project team for more info.
                                </Tooltip>
                            }
                        >
                            <span>
                                <FaInfoCircle className="panel-info" style={{ zIndex: '999', cursor: 'pointer' }} />
                            </span>
                        </OverlayTrigger>
                        Pausable
                    </div>
                    <div>
                        <span style={{ color: 'var(--color-orange)', fontWeight: '400' }}>
                            <TiWarningOutline style={{ marginTop: '-10px', marginLeft: '-3px', marginRight: '3px' }} size={16} />
                            This contract is pausable
                        </span>
                    </div>
                </ListGroup.Item> */}
                </ListGroup>
            </Row>
            {/* <Col className="p-4 col-6">
                <div
                    style={{ background: 'var(--background-light)', borderRadius: '8px', border: '1px solid var(--color-border-grey)' }}
                    className="p-3"
                >
                    <h6 className="mt-0 mb-2" style={{ color: 'var(--text-base)', fontWeight: '400' }}>
                        Safety Check by PokeDX.app
                    </h6>
                    <span className="text-muted">Additional safety checks will be available soon.</span>
                </div>
            </Col> */}
        </Container>
    );
}
