const PROXY_URL = process.env.REACT_APP_API_PROXY_URL;
const CURRENCY_CONVERTER_URL = process.env.REACT_APP_API_CURRENCY_CONVERTER_URL;
const CRYPTO_CURRENCY_URL = process.env.REACT_APP_API_CRYPTO_CURRENCY_URL;
const BITQUERY_URL = process.env.REACT_APP_GRAPHQL_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const API_KEY2 = process.env.REACT_APP_API_KEY2;
const BSC_URL = process.env.REACT_APP_BSC_URL;
const PRODUCT_INFO_URL = process.env.REACT_APP_PRODUCT_INFO_API_URL;
const PROJECT_INFO_CREATE_URL = process.env.REACT_APP_PROJECT_INFO_CREATE_URL;
const MORALIS_API_URL = process.env.REACT_APP_MORALIS_API_URL;
const BNB_API_URL = process.env.REACT_APP_BNB_API_URL;
const DEX_TOKEN = process.env.REACT_APP_DEX_TOKEN;
const BSC_GRAPH_URL = process.env.REACT_APP_BSC_GRAPH_URL;
const SWAP_TOKEN_BY_PRICE = process.env.REACT_APP_SWAP_TOKEN_BY_PRICE_URL;
const SWAP_TOKEN_BY_QUOTE = process.env.REACT_APP_SWAP_TOKEN_BY_QUOTE_URL;
const SWAP_TOKENS = process.env.REACT_APP_SWAP_TOKENS;
const BSCSCAN_API_KEY = process.env.REACT_APP_BSCSCAN_API_KEY;
const MORALIS_FIAT_PROD_KEY = process.env.REACT_APP_MORALIS_FIAT_PROD_KEY;
const MORALIS_SERVER_URL = process.env.REACT_APP_MORALIS_SERVER_URL;
const DEX_GURU_URL = process.env.REACT_APP_API_DEX_GURU_URL;
const MORALIS_APP_ID = process.env.REACT_APP_MORALIS_APP_ID;
const STEMP_URL = process.env.REACT_APP_STEMP_URL;
const DATA_SERVICE_URL = process.env.REACT_APP_DATA_SERVICE_URL;
const CDN_URL = process.env.REACT_APP_CDN_URL;
const POKEDX_API_URL = process.env.REACT_APP_POKEDX_API_URL;
const DEFUALT_ADD = '0x43a0c5eb1763a211aa3c05849a617f2ee0452767';
const DEFUALT_TOKEN = 'PDX';
const KYBER_API_URL = process.env.REACT_APP_KYBER_API_URL;
const TOKEN_INFO_URL = process.env.REACT_APP_TOKEN_INFO_URL;
const TWITTER_PUBLIC_KEY = process.env.REACT_APP_TWITTER_PUBLIC_KEY;
const TWITTER_SECRET_KEY = process.env.REACT_APP_TWITTER_SECRET_KEY;
const TWITTER_API_BEARER_TOKEN = process.env.REACT_APP_TWITTER_API_BEARER_TOKEN;

export {
    CURRENCY_CONVERTER_URL,
    CRYPTO_CURRENCY_URL,
    PROXY_URL,
    BITQUERY_URL,
    API_KEY,
    API_KEY2,
    BSC_URL,
    PRODUCT_INFO_URL,
    PROJECT_INFO_CREATE_URL,
    MORALIS_API_URL,
    BNB_API_URL,
    DEX_TOKEN,
    BSC_GRAPH_URL,
    SWAP_TOKEN_BY_PRICE,
    SWAP_TOKEN_BY_QUOTE,
    SWAP_TOKENS,
    BSCSCAN_API_KEY,
    MORALIS_SERVER_URL,
    MORALIS_FIAT_PROD_KEY,
    DEX_GURU_URL,
    MORALIS_APP_ID,
    STEMP_URL,
    DATA_SERVICE_URL,
    CDN_URL,
    DEFUALT_ADD,
    POKEDX_API_URL,
    KYBER_API_URL,
    DEFUALT_TOKEN,
    TOKEN_INFO_URL,
    TWITTER_PUBLIC_KEY,
    TWITTER_SECRET_KEY,
    TWITTER_API_BEARER_TOKEN
};
