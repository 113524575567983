import React, { useEffect, useState } from 'react';
import { Card, Modal, Button, Table } from 'react-bootstrap';
import Web3 from 'web3';
import { useStoreApi } from '../../store/storeApi';
import { numberWithCommas } from '../../shared/commonUtils';

export default function Positions() {
    const [tradesShow, setTradesShow] = useState(false);
    const { allTokens } = useStoreApi();

    useEffect(() => {
        console.log('allTokens', allTokens);
    }, [allTokens]);

    const renderBody = () =>
        allTokens &&
        allTokens.map((obj, index) => {
            if (obj.tokenprice > 1) {
                return (
                    <tr key={index}>
                        <td>{obj.name}</td>
                        <td>{obj.symbol}</td>
                        <td>{numberWithCommas(parseFloat(obj.quantity).toFixed(3))}</td>
                        <td>-</td>
                        <td>-</td>
                        <td className="cbuy">-</td>
                        <td className="cbuy">-</td>
                        <td>{numberWithCommas(parseFloat(obj.tokenprice).toFixed(3))}</td>
                        <td>
                            <button
                                disabled
                                className=" d-inline-flex"
                                style={{
                                    borderRadius: '5px',
                                    marginLeft: '6px',
                                    fontSize: '12px',
                                    backgroundColor: 'transparent',
                                    border: '1px solid #292D40',
                                    color: 'white',
                                    height: '21px'
                                }}
                            >
                                Coming soon
                            </button>
                        </td>
                    </tr>
                );
            }
        });

    return (
        <div>
            <h2
                className="mb-4 positions-header"
                style={{ color: 'var(--text-base)', fontWeight: 400, textAlign: 'left', marginTop: '1em' }}
            >
                Open positions
            </h2>
            <Table variant="dark" style={{ backgroundColor: 'transparent' }}>
                <thead>
                    <tr>
                        <th>Token name</th>
                        <th>Symbol</th>
                        <th>Amount</th>
                        <th>Avg. price (USD)</th>
                        <th>Close</th>
                        <th>Gain (USD)</th>
                        <th>% Gain</th>
                        <th>Exposure (USD)</th>
                        <th>Take Profit/Stop loss</th>
                    </tr>
                </thead>
                <tbody>{allTokens ? renderBody() : null}</tbody>
            </Table>
        </div>
    );
}

// <tr>
//     <td>PokeDX</td>
//     <td>PDX</td>
//     <td>140,000</td>
//     <td>0.0134 <button onClick={() => setTradesShow(true)} className="tradesButton d-inline-flex" style={{borderRadius:'5px', marginLeft: '6px' , fontSize: '12px' , backgroundColor:'transparent', border: '1px solid #292D40' , color: 'white' , height: '21px' }}>Trades</button></td>
//     <Modal
//       size="lg"
//       show={tradesShow}
//       onHide={() => setTradesShow(false)}
//       aria-labelledby="example-modal-sizes-title-lg"
//     >
//     <Modal.Header closeButton>
//       <Modal.Title className="text-white font-weight-normal">
//         Your [Token name] trades
//       </Modal.Title>
//     </Modal.Header>
//     <Modal.Body>...</Modal.Body>
//     </Modal>
//     <td>0.0420</td>
//     <td className="cbuy">4,004.000</td>
//     <td className="cbuy">187.633 %</td>
//     <td>5.880,000</td>
//     <td><button disabled className=" d-inline-flex" style={{borderRadius:'5px', marginLeft: '6px' , fontSize: '12px' , backgroundColor:'transparent', border: '1px solid #292D40' , color: 'white' , height: '21px' }}>Coming soon</button></td>
//   </tr>
