import { OverlayTrigger, Col, Badge, Tooltip } from 'react-bootstrap';
import { BiLinkExternal } from 'react-icons/bi';
import { GiPapers } from 'react-icons/gi';
import { BsTelegram, BsReddit, BsTwitter } from 'react-icons/bs';
import { AiFillTwitterCircle } from 'react-icons/ai';
import { useStoreApi } from '../../store/storeApi';
import { BSC_URL } from '../../shared/ApiURL';
import BscLogo from '../../assets/img/bscscan.svg';

export default function TokenLinks() {
    const { tokenAddress, productInfoResponse } = useStoreApi();

    const callCustomLink = (type) => {
        // console.log('productInfoResponse', productInfoResponse);
        let link = '';
        if (type === 'contract') {
            link = `${BSC_URL}address/${tokenAddress}`;
        } else if (type === 'holders') {
            link = `${BSC_URL}token/${tokenAddress}#balances`;
        } else if (type === 'tx') {
            link = `${BSC_URL}token/${tokenAddress}`;
        } else if (type === 'website') {
            link = productInfoResponse && productInfoResponse[type] ? productInfoResponse[type] : '';
        } else if (type != '') {
            if (productInfoResponse?.links) {
                const res = productInfoResponse.links.filter((obj) => obj.name == type);
                console.log('res===>', res);
                link = res[0]?.url || '';
            }
        }
        window.open(link, '_blank', 'noopener');
    };

    const checkEmpty = (tag) => {
        if (!productInfoResponse) return false;
        if (productInfoResponse?.links) return productInfoResponse.links.filter((obj) => obj.name == tag).length;
        return false;
    };

    return (
        <Col>
            <div className="d-flex justify-content-end mt-2">
                <OverlayTrigger placement="top" overlay={<Tooltip>Contract</Tooltip>}>
                    {({ ref, ...triggerHandler }) => (
                        <Badge
                            ref={ref}
                            {...triggerHandler}
                            src={BscLogo}
                            onClick={() => callCustomLink('contract')}
                            bg="transparent"
                            style={{
                                fontSize: '14px',
                                height: '20px',
                                width: '20px',
                                fontWeight: '400',
                                borderRadius: '100px',
                                color: 'var(--text-base)',
                                cursor: 'pointer',
                                marginBottom: '10px'
                            }}
                            className="mr-2 interactivehover"
                        >
                            <img
                                src={BscLogo}
                                height="20px"
                                width="20px"
                                alt="explorer"
                                style={{ marginLeft: '-8.9px', marginTop: '-5px' }}
                            />
                        </Badge>
                    )}
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip>Holders</Tooltip>}>
                    {({ ref, ...triggerHandler }) => (
                        <Badge
                            ref={ref}
                            {...triggerHandler}
                            onClick={() => callCustomLink('holders')}
                            bg="transparent"
                            style={{
                                fontSize: '14px',
                                height: '20px',
                                width: '20px',
                                fontWeight: '400',
                                borderRadius: '100px',
                                color: 'var(--text-base)',
                                cursor: 'pointer'
                            }}
                            className="mr-2 interactivehover"
                        >
                            <img
                                src={BscLogo}
                                height="20px"
                                width="20px"
                                alt="explorer"
                                style={{ marginLeft: '-8.9px', marginTop: '-5px' }}
                            />
                        </Badge>
                    )}
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip>Transactions</Tooltip>}>
                    {({ ref, ...triggerHandler }) => (
                        <Badge
                            ref={ref}
                            {...triggerHandler}
                            alt="explorer"
                            bg="transparent"
                            onClick={() => callCustomLink('tx')}
                            style={{
                                fontSize: '14px',
                                height: '20px',
                                width: '20px',
                                fontWeight: '400',
                                borderRadius: '100px',
                                cursor: 'pointer'
                            }}
                            className="mr-2 interactivehover bscbg"
                        >
                            <img
                                src={BscLogo}
                                height="20px"
                                width="20px"
                                alt="explorer"
                                style={{ marginLeft: '-8.9px', marginTop: '-5px' }}
                            />
                        </Badge>
                    )}
                </OverlayTrigger>
                {checkEmpty('whitepaper') ? (
                    <OverlayTrigger placement="top" overlay={<Tooltip>Whitepaper</Tooltip>}>
                        {({ ref, ...triggerHandler }) => (
                            <a ref={ref} {...triggerHandler}>
                                <GiPapers
                                    onClick={() => callCustomLink('whitepaper')}
                                    bg="primary"
                                    style={{
                                        fontSize: '13px',
                                        height: '20px',
                                        width: '20px',
                                        fontWeight: '400',
                                        borderRadius: '100px',
                                        color: 'var(--text-primary)',
                                        cursor: 'pointer',
                                        marginBottom: '10px'
                                    }}
                                    className="mr-2 interactivehover"
                                />
                            </a>
                        )}
                    </OverlayTrigger>
                ) : null}
                {checkEmpty('website') != '' ? (
                    <OverlayTrigger placement="top" overlay={<Tooltip>Website</Tooltip>}>
                        {({ ref, ...triggerHandler }) => (
                            <Badge
                                ref={ref}
                                {...triggerHandler}
                                onClick={() => callCustomLink('website')}
                                bg="primary"
                                style={{
                                    fontSize: '14px',
                                    height: '25px',
                                    width: '25px',
                                    fontWeight: '400',
                                    borderRadius: '100px',
                                    color: 'var(--text-base)',
                                    cursor: 'pointer'
                                }}
                                className="mr-2 interactivehover"
                            >
                                {/* <GiPapers style={{ color: 'var(--text-base)', marginTop: '-3px', marginLeft: '-1px', marginRight: '0px' }} /> */}
                            </Badge>
                        )}
                    </OverlayTrigger>
                ) : null}
                {checkEmpty('telegram') ? (
                    <OverlayTrigger placement="top" overlay={<Tooltip>Telegram</Tooltip>}>
                        {({ ref, ...triggerHandler }) => (
                            <a ref={ref} {...triggerHandler}>
                                <BsTelegram
                                    onClick={() => callCustomLink('telegram')}
                                    bg="primary"
                                    style={{
                                        marginTop: '-5px',
                                        fontSize: '14px',
                                        height: '20px',
                                        width: '20px',
                                        fontWeight: '400',
                                        borderRadius: '100px',
                                        backgroundColor: 'var(--text-primary)',
                                        color: '#0088CC',
                                        cursor: 'pointer'
                                    }}
                                    className="mr-2 interactivehover"
                                />
                            </a>
                        )}
                    </OverlayTrigger>
                ) : null}
                {checkEmpty('twitter') ? (
                    <OverlayTrigger placement="top" overlay={<Tooltip>Twitter</Tooltip>}>
                        {({ ref, ...triggerHandler }) => (
                            <a ref={ref} {...triggerHandler}>
                                <BsTwitter
                                    onClick={() => callCustomLink('twitter')}
                                    style={{
                                        marginTop: '-5px',
                                        fontSize: '14px',
                                        height: '20px',
                                        backgroundColor: '#1DA1F2',
                                        width: '20px',
                                        fontWeight: '400',
                                        borderRadius: '100px',
                                        color: 'var(--text-primary)',
                                        cursor: 'pointer'
                                    }}
                                    className="mr-2 p-1 interactivehover"
                                />
                            </a>
                        )}
                    </OverlayTrigger>
                ) : null}
                {checkEmpty('instagram') ? (
                    <OverlayTrigger placement="top" overlay={<Tooltip>Instagram</Tooltip>}>
                        {({ ref, ...triggerHandler }) => (
                            <Badge
                                ref={ref}
                                {...triggerHandler}
                                onClick={() => callCustomLink('instagram')}
                                bg="primary"
                                style={{
                                    fontSize: '14px',
                                    height: '25px',
                                    width: '25px',
                                    fontWeight: '400',
                                    borderRadius: '100px',
                                    color: 'var(--text-base)',
                                    cursor: 'pointer'
                                }}
                                className="mr-2 interactivehover"
                            >
                                <BiLinkExternal
                                    style={{ color: 'var(--text-muted)', marginTop: '-4px', marginLeft: '-3px', marginRight: '0px' }}
                                />
                            </Badge>
                        )}
                    </OverlayTrigger>
                ) : null}
                {checkEmpty('reddit') ? (
                    <OverlayTrigger placement="top" overlay={<Tooltip>Reddit</Tooltip>}>
                        {({ ref, ...triggerHandler }) => (
                            <a ref={ref} {...triggerHandler}>
                                <BsReddit
                                    onClick={() => callCustomLink('reddit')}
                                    style={{
                                        marginTop: '-5px',
                                        fontSize: '14px',
                                        height: '20px',
                                        width: '20px',
                                        fontWeight: '400',
                                        borderRadius: '100px',
                                        backgroundColor: 'var(--text-primary)',
                                        color: '#FF4500',
                                        cursor: 'pointer'
                                    }}
                                    className="mr-2 interactivehover"
                                />
                            </a>
                        )}
                    </OverlayTrigger>
                ) : null}
            </div>
        </Col>
    );
}
