import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Chart from './Chart';
import Crypto from './Crypto';
import SearchBar from '../SearchBar';
import CryptoOverviewMobile from './CryptoOverviewMobile';
import { DATA_SERVICE_URL } from '../../shared/ApiURL';
import { CLIENT_ID } from '../../shared/network';
import { processLiveTransaction } from '../../shared/dataProcessing';
import { useStoreApi } from '../../store/storeApi';
import {
    getliveTransactionsFromDB,
    setLiveTransactionsInDb,
    getActivePairFromDB,
    addTokenToRecentlyViewedList,
    getSettingsDataFromDB
} from '../../shared/commonUtils';
import { drawSpread } from '../../shared/charting';

function ChartContainer() {
    const [settings, setSettings] = useState(null);

    useEffect(() => {
        const data = getSettingsDataFromDB();
        if (data) {
            setSettings(data);
        }
    }, []);

    return <Crypto settings={settings} />;
}

export default function Token(props) {
    const { address, setLiveTransactions, setTrackWallet } = useStoreApi();
    useEffect(() => {
        if (address) {
            const list = JSON.parse(localStorage.getItem('TrackWallets')) || [];
            setTrackWallet(list);
        }
    }, [address]);

    useEffect(() => {
        const source = new EventSource(`${DATA_SERVICE_URL}/datastream?id=${CLIENT_ID}`);
        let latestCandle = null;
        source.onmessage = (data) => {
            const dictData = JSON.parse(data.data);
            const activePair = getActivePairFromDB();
            if (
                !dictData ||
                !activePair ||
                dictData.pairAddress === undefined ||
                activePair.pair === undefined ||
                activePair.pair.address.toLowerCase() !== dictData.pairAddress.toLowerCase()
            ) {
                return;
            }
            if (dictData.event === 'liveData') {
                if (dictData.data?.candle && window.onRealtimeCallback !== undefined && window.tvWidget !== undefined) {
                    const candle = dictData?.data?.candle;
                    candle.time *= 1000;
                    if (candle) {
                        if (latestCandle === null || latestCandle.id !== dictData.pairString) {
                            // @ts-ignore
                            window.onRealtimeCallback(candle);
                        } else {
                            try {
                                const resolution = window.tvWidget.activeChart().resolution();
                                const multiplier = resolution.includes('W') ? 10080 : resolution.includes('D') ? 1440 : 1;
                                const interval = parseInt(resolution) * multiplier * 60 * 1000;
                                if (candle.time - latestCandle.candle.time < interval) {
                                    candle.high = Math.max(candle.high, latestCandle.candle.high);
                                    candle.low = Math.min(candle.low, latestCandle.candle.low);
                                    candle.open = latestCandle.candle.open;
                                    candle.time = latestCandle.candle.time;
                                }
                                window.onRealtimeCallback(candle);
                            } catch (e) {}
                        }
                        latestCandle = { id: dictData.pairString, candle };
                        drawSpread(candle.close);
                    }
                }
                let listTransactions = dictData?.data?.transactions;
                if (listTransactions) {
                    listTransactions.map((transaction) => processLiveTransaction(transaction));
                    listTransactions = listTransactions.concat(getliveTransactionsFromDB());
                    listTransactions = listTransactions.slice(0, 150);
                    setLiveTransactions(listTransactions);
                    setLiveTransactionsInDb(listTransactions);
                }
            }
        };
        source.onerror = function (event) {
            console.error('Event stream error ', event);
        };
        source.onopen = function (event) {
            // console.log('Event stream open', event);
        };
    }, []);

    const data = useParams();
    const { getCryptoResponse, tokenAddress, getDexTokenResponse, token, setTokenAddress, getProductInfo, setToken } = useStoreApi();
    const MINUTE_MS = 60000;
    useEffect(() => {
        setTokenAddress(data.id);
        setToken(data.token);
        addTokenToRecentlyViewedList(data);
        if (token != null && token == data.token) {
            getProductInfo(data.id);
            getCryptoResponse(data.token, data.id);
        }
        const interval = setInterval(() => {
            getCryptoResponse(token, data.id);
        }, MINUTE_MS);

        return () => clearInterval(interval);
    }, [token, data]);

    return (
        <div className="tokencontainer">
            <ChartContainer />
            <Chart data={props.data} />
            <CryptoOverviewMobile />
        </div>
    );
}
