import { Nav, Tab } from 'react-bootstrap';
import TwitterTimeline from '../TwitterTimeline';
import NewsWidget from '../NewsWidget';
import RightSidebar from './RightSidebar';

function SocialStats() {
    return (
        <div className="col-2 mt-0 justify-content-end p-0 bg-darker-trade hideOnMobile">
            <div className="infosidebar" style={{ width: '100%' }}>
                <RightSidebar />
            </div>
            <div style={{ backgroundColor: 'var(--color-border-grey)' }}>
                <Tab.Container defaultActiveKey="month">
                    <Nav variant="pills" style={{ paddingTop: '5px' }} className="pl-1">
                        <Nav.Item>
                            <Nav.Link eventKey="month" className="mediumpill mr-1">
                                News
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="day" className="mediumpill">
                                Twitter
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <div style={{ width: '100%' }} className="">
                        <Tab.Content className="pl-0 pr-0">
                            <Tab.Pane eventKey="month">{NewsWidget ? <NewsWidget /> : 'Loading..'}</Tab.Pane>
                            <Tab.Pane eventKey="day">
                                <TwitterTimeline />
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </Tab.Container>
            </div>
        </div>
    );
}

export default SocialStats;
