import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import useLocalStorage from 'use-local-storage';
import Discover from './pages/Discover';
import Portfolio from './components/Portfolio';
import Token from './components/Token';
import About from './pages/About';
import Tools from './pages/Tools';
import AddNewToken from './pages/AddNewToken';
import Ramp from './pages/Ramp';
import Header from './components/Header';
import Footer from './components/Footer';
import Trade from './pages/Trade';
// css files
import './index.scss';
import './Styles/Themes.scss';
import './components/Token/Trade/index.scss';
import './Styles/modal-popup.scss';
import { DEFUALT_ADD, DEFUALT_TOKEN } from './shared/ApiURL';

function App() {
    const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    // changed from 'dark' : 'light' so theme is dark even if users system is light
    const [theme, setTheme] = useLocalStorage('theme', defaultDark ? 'dark' : 'dark');

    const switchTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        try {
            setTheme(newTheme);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="App" data-theme={theme}>
            <Router>
                <Header />
                <Switch>
                    <Route path="/" exact render={() => <Redirect to={`/token/${DEFUALT_ADD}/${DEFUALT_TOKEN}`} />} />
                    <Route path="/token/:id/:token">
                        <Token data={theme} />
                    </Route>
                    <Route path="/tools" component={Tools} />
                    <Route path="/swap" component={Trade} />
                    <Route path="/portfolio" component={Portfolio} />
                    <Route path="/discover">
                        <Discover />
                    </Route>
                    <Route path="/roadmap" component={About} />
                    <Route path="/add-new-token" component={AddNewToken} />
                    <Route path="/ramp" component={Ramp} />
                </Switch>
                <Footer />
                {/* <Button variant="normal" onClick={switchTheme}>
                    switch to {theme === 'light' ? 'dark' : 'light'}
                </Button> */}
            </Router>
        </div>
    );
}

export default App;
