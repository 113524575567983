import React, { useEffect } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';
import { Card , Col , Row } from 'react-bootstrap';
import { useStoreApi } from '../../store/storeApi';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );


export default function GainLossAssets() {
    const { address, walletTransactions, getWalletTransactions } = useStoreApi();

    useEffect(() => {
      if(address) {
        console.log("here pk");
        getWalletTransactions(address);
        getAssets();
      }
    }, [address]);
    // useEffect(() => {
    //   console.log('walletTransactions', walletTransactions);
    //   if(walletTransactions) {
        
    //   }
    // }, []);
    const getAssets = () => {
      const tokens = [];
      if(walletTransactions && walletTransactions.length > 0) {
        walletTransactions.forEach(obj => {
          if (obj.tokens_in[0] && (obj.tokens_in[0].symbol != 'WBNB' || obj.tokens_in[1].symbol != 'WBNB')) {
            if(Object.keys(tokens).length > 0 && tokens[obj.tokens_in[0].symbol]) {
              tokens[obj.tokens_in[0].symbol] = tokens[obj.tokens_in[0].symbol] - obj.amount_usd;
            } else {
              tokens[obj.tokens_in[0].symbol] = obj.amount_usd;
            }
          }
          if (obj.tokens_out[0] &&  (obj.tokens_out[0].symbol != 'WBNB' || obj.tokens_out?.[1].symbol != 'WBNB')) {
            console.log('ABCA',tokens.length , (tokens.length > 0 && tokens[obj.tokens_out[0].symbol]));
            if(Object.keys(tokens).length  > 0 && tokens[obj.tokens_out[0].symbol]) {
              tokens[obj.tokens_out[0].symbol] = tokens[obj.tokens_out[0].symbol] + obj.amount_usd;
            } else {
              tokens[obj.tokens_out[0].symbol] = obj.amount_usd;
            }
          }
        });
        console.log("tokens", tokens);
      }
    };
    const barOptions = {
        indexAxis: 'y',
        elements: {
          bar: {
            borderWidth: 1,
          },
        },
        responsive: true,
        maintainAspectRatio:true,
        aspectRatio:1,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false
          },
        },
    };

    const barLabels = ['PDX','BMCC','TOAD','BNB','Other'];

    const barData = {
        labels: barLabels,
        datasets: [
          {
            label: 'PnL',
            data: [1800,-800,-1000,-1200,-400],
            borderColor: 'rgb(101, 208, 162, 1)',
            backgroundColor: 'rgba(101, 208, 162, 0.5)',
          },
        ],
      };

    

  return (
    <div>
      <Card
          style={{ backgroundColor: 'rgb(31, 34, 48)', boxShadow: 'none' , border: '1px solid rgb(41, 45, 64)' , borderRadius: '20px' }}
          className="p-4"
        >
        <Row>
          <Col sm={12}>
            <h5 className="text-center font-weight-normal" >Profit & Loss</h5>
            <p className="text-center text-muted font-weight-normal">Individual assets</p>
            <Bar options={barOptions} data={barData} />
          </Col>
        </Row>
      </Card>
    </div>
  )
}
