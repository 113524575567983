import ICON_PANCAKESWAP from "../assets/img/pancakeswap_icon.png";
import ICON_APESWAP from "../assets/img/apeswap_icon.png";
import ICON_NOSWAP from "../assets/img/noswap_icon.png";

export const DEX_ICONS = {
  pancakeswap: ICON_PANCAKESWAP,
  apeswap: ICON_APESWAP,
  nullswap: ICON_NOSWAP,
};

export const NULL_LP = {
  dex: "nullswap",
  symbol0: "None",
  symbol1: "None",
  icon: ICON_NOSWAP,
  pair: {
    address: ''
  }
};
