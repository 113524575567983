import React, { useEffect, useState } from 'react';
import { Card, ListGroup, Button } from 'react-bootstrap';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { getGraphData } from '../../shared/commonUtils';
import { useStoreApi } from '../../store/storeApi';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function Performance() {
  const [chartData, setChartData] = useState(null);
  const { address, walletTokenData } = useStoreApi();
  useEffect(() => {
    if (address) {
      getPerfomanceData();
    }
  }, [address, walletTokenData]);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    tension: 0.3,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: 'meow',
      },
    },

  };
  const getPerfomanceData = async () => {
    if(walletTokenData && walletTokenData.length > 0) {
      const chartLabels = walletTokenData.map(obj => obj.dayname);
      const c1Data = walletTokenData.map(obj => obj.balance);
      const c1 = {
        labels: chartLabels,
        datasets: [
          {
            label: 'Wallet Balance',
            data: c1Data,
            borderColor: 'rgb(141, 220, 186, 1)',
            backgroundColor: 'rgba(250, 250, 250, 1)',
          }
        ],
      };
      setChartData(c1);
    }
  }





  return (
    <div>
      <div className="rounded my-2 p-0" style={{ height: '300px' }} >
        { 
        chartData ? 
          <Line options={chartOptions} data={chartData} />
        : null}
      </div>
    </div>
  )
}
