export const Reducer = (state, action) => {
    switch (action.type) {
        case 'NEW-ADDRESS':
            return {
                ...state,
                address: action.address,
                message: action.message
            };
        case 'SET_BALANCE':
            return {
                ...state,
                balance: action.balance
            };

        case 'SET_CRYPTO_RESPONSE':
            return {
                ...state,
                cryptoResponse: action.cryptoResponse,
                imageId: action.imageId
            };
        case 'SET_DEX_TOKEN_RESPONSE':
            return {
                ...state,
                dexCryptoResponse: action.dexCryptoResponse
            };
        case 'SET_TOKEN':
            return {
                ...state,
                token: action.token,
                toSearchToken: action.token
            };
        case 'SET_TOKEN_ADDRESS':
            return {
                ...state,
                tokenAddress: action.tokenAddress,
                toSearchTokenAddress: action.tokenAddress
            };
        case 'SET_ACTIVE_PAIR':
            return {
                ...state,
                activePair: action.activePair
            };
        case 'SET_LIST_PAIRS':
            return {
                ...state,
                listPairs: action.listPairs
            };
        case 'SET_LIVE_TRANSACTIONS':
            return {
                ...state,
                liveTransactions: action.liveTransactions
            };
        case 'SET_TRANS_RESPONSE':
            return {
                ...state,
                transactions: action.transactions
            };
        case 'SET_TRANS_RESPONSEV2':
            return {
                ...state,
                transactionsv2: action.transactionsv2
            };
        case 'SET_PRODUCT_INFO_RESPONSE':
            return {
                ...state,
                productInfoResponse: action.productInfoResponse
            };
        case 'SET_TRADE':
            return {
                ...state,
                trade: action.trade
            };
        case 'SET_TRADE_LEFT':
            return {
                ...state,
                tradeleft: action.tradeleft
            };
        case 'SET_TOKENS':
            return {
                ...state,
                allTokens: action.allTokens
            };
        case 'SET_FROM_TOKEN':
            return {
                ...state,
                fromSearchToken: action.token
            };
        case 'SET_TO_TOKEN':
            return {
                ...state,
                toSearchToken: action.token
            };
        case 'SET_FROM_TOKEN_ADDRESS':
            return {
                ...state,
                fromSearchTokenAddress: action.address
            };
        case 'SET_TO_TOKEN_ADDRESS':
            return {
                ...state,
                toSearchTokenAddress: action.address
            };
        case 'SET_LP_INFO':
            return {
                ...state,
                lpResponse: action.lpResponse
            };
        case 'CONNECT_METAMASK':
            return {
                ...state,
                metamaskConnected: action.metamaskConnected
            };
        case 'SET_METAMASK_STATUS': {
            return {
                ...state,
                metamaskStatus: action.metamaskStatus
            };
        }
        case 'SET_MARKET_CAP': {
            return {
                ...state,
                marketcap: action.marketcap,
                csupply: action.csupply
            };
        }
        case 'SET_TV_CHART': {
            return {
                ...state,
                tvChart: action.tvChart
            };
        }
        case 'SET_WATCHLIST': {
            return {
                ...state,
                watchList: action.watchList
            };
        }
        case 'SET_BASETOKEN_PRICE': {
            return {
                ...state,
                basetokenprice: action.basetokenprice
            };
        }
        case 'SET_WALLET_TRANSACTIONS': {
            return {
                ...state,
                walletTransactions: action.transactions
            };
        }
        case 'SET_RELOAD_CHART': {
            return {
                ...state,
                reloadChart: action.reloadChart
            };
        }
        case 'SET_LP': {
            return {
                ...state,
                lp: action.lp
            };
        }
        case 'SET_TOKEN_PRICE': {
            return {
                ...state,
                tokenprice: action.tokenprice
            };
        }
        case 'SET_FILTER_BY_PAIR':
            return {
                ...state,
                filterByPair: action.filterByPair
            };
        case 'SET_WALLET_TOKEN_DATA':
            return {
                ...state,
                walletTokenData: action.walletTokenData
            };
        case 'SET_BNB_PRICE':
            return {
                ...state,
                bnbToUSDPrice: action.bnbToUSDPrice
            };
        case 'SET_BUY_FEE':
            return {
                ...state,
                buyFee: action.buyFee
            };
        case 'SET_SELL_FEE':
            return {
                ...state,
                sellFee: action.sellFee
            };
        case 'SET_LAYOUT':
            return {
                ...state,
                layout: action.layout
            };
        case 'SET_TRACK_WALLET':
            return {
                ...state,
                trackWallets: action.trackWallets
            };
        case 'SET_REFRESH_TX_LIST':
            return {
                ...state,
                refreshTransactionList: action.refreshTransactionList
            };
        case 'WALLET_OBJ':
            return {
                ...state,
                walletType: action.walletType,
                walletObj: action.walletObj
            };
        default:
            throw new Error(`Unknown type of action: ${action.type}`);
    }
};
