import React, { useEffect, useState } from "react";
import { Form, Button, Row, Container, Col } from 'react-bootstrap';
import { useStoreApi } from "../store/storeApi";
import {
    addTokenToMoralis,
    checkForDuplicate,
    DisplayErrorMsg, 
    getDecimals,
} from "../shared/commonUtils";
import { PROXY_URL } from "../shared/ApiURL";

export default function AddNewToken() {
    const {

    } = useStoreApi();
    const [tokenData, setTokenData] = useState(null);
    useEffect(() => {
        setTokenData(null);
    }, []);

    const importToken = async () => {
        const secret = document.getElementById('secret').value;
        const response = await fetch(`${PROXY_URL}/authorizeuser`, {
            method: 'POST',
            mode: 'cors', 
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': secret,
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer'
        });
        const content = await response.json();
        if(content.message === 'success') {
            const result = await checkForDuplicate(tokenData.address, 1);
            if (result) {
                DisplayErrorMsg("Token added successfully.");
            } else {
                const res = await addTokenToMoralis(tokenData, 0, 1);
                setTokenData(null);
                if(res) {
                    DisplayErrorMsg("Token added successfully.");
                } else {
                    DisplayErrorMsg("Token already added.");
                }
            }
            
        } else {
            DisplayErrorMsg("Invalid secret.");
        }
    };

    const searchForToken = async (e) => {
        const str = e.target.value;
        let pattern = /0x/i;
        if (str.match(pattern) && str.length == '42') {
            const apiResponse = await getDecimals(str, true);
            console.log('apiResponse===>', apiResponse);
            if (apiResponse) {
                setTokenData(apiResponse);
            }
        }
    }
    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col>
                    <h4>Add New Token</h4>
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Token address</Form.Label>
                            <Form.Control type="text" placeholder="Enter Token Address" onKeyUp={(e)=> searchForToken(e)} />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                        { tokenData ? 
                            <span>
                                <Form.Group className="mb-3">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Text className="text-muted">
                                        {tokenData.name}
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Symbol</Form.Label>
                                    <Form.Text className="text-muted">
                                    {tokenData.symbol}
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Secret</Form.Label>
                                    <Form.Control type="password" id="secret" placeholder="Enter Secret Key" />
                                </Form.Group>
                            </span>
                        : null }
                        <Button className="mt-3" variant="primary" onClick={() => importToken()}>
                            Add New Token
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>

    );
}