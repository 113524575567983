import { Card, FormControl, InputGroup, Container, Button, Modal, Row, Col, ListGroup, Form, Alert, Image, Spinner } from 'react-bootstrap';

function SettingsPopup(props) {
    return (
        <Modal {...props} size="sm" animation={false} centered>
            <Modal.Header style={{ backgroundColor: '' }}>
                <Modal.Title style={{ fontSize: '16px', color: 'var(--text-muted)', fontWeight: 'normal' }}>Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Label
                    className="std-info px-0"
                    style={{ color: '#6f6e84', textTransform: 'capitalize', fontWeight: '400', fontSize: '12px' }}
                    column
                    lg={12}
                >
                    Maximum slippage
                </Form.Label>
                <Row className="mb-2">
                    <Col className="d-flex px-2">
                        <Button
                            variant="slippage"
                            value="0.1"
                            style={{ borderRadius: '7px 0 0 7px' }}
                            onClick={(e) => props.OnUpdateSlippage(e.target.value)}
                        >
                            0.1%
                        </Button>
                        <Button
                            variant="slippage"
                            value="0.5"
                            style={{ borderRadius: '0' }}
                            onClick={(e) => props.OnUpdateSlippage(e.target.value)}
                        >
                            0.5%
                        </Button>
                        <Button
                            variant="slippage"
                            value="1"
                            onClick={(e) => props.OnUpdateSlippage(e.target.value)}
                            style={{ borderRadius: '0' }}
                        >
                            1%
                        </Button>
                        <InputGroup>
                            <Form.Control
                                type="text"
                                id="inputSlp"
                                className="slippageinput"
                                placeholder="Custom"
                                style={{ color: '#6f6e84', fontFamily: 'monotalic', padding: '0' }}
                            />
                            <InputGroup.Text
                                className="mr-1"
                                style={{ height: '28px', backgroundColor: '#171722', border: 'none', color: '#c3c2d4', fontSize: '11px' }}
                            >
                                %
                            </InputGroup.Text>
                        </InputGroup>
                    </Col>
                </Row>
                <Col className="d-flex mt-3 px-0">
                    <Form.Label
                        className="std-info px-0"
                        style={{ color: '#6f6e84', textTransform: 'capitalize', fontWeight: '400', fontSize: '12px' }}
                        column
                        lg={8}
                    >
                        Transaction time limit
                    </Form.Label>
                    <InputGroup>
                        <Form.Control
                            type="text"
                            id="txTimeLimit"
                            aria-describedby="tx-timelimit"
                            className="slippageinput"
                            placeholder="20"
                            style={{ color: '#c3c2d4', fontFamily: 'monotalic', padding: '0' }}
                        />
                        <InputGroup.Text
                            className="d-flex px-2"
                            style={{ height: '28px', backgroundColor: '#171722', border: 'none', color: '#c3c2d4', fontSize: '11px' }}
                        >
                            mins
                        </InputGroup.Text>
                    </InputGroup>
                </Col>
            </Modal.Body>
            <Modal.Footer style={{ border: 'none' }}>
                <Button variant="primary-modal" onClick={() => props.OnSave()} style={{ width: '100%' }}>
                    Save changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default SettingsPopup;
