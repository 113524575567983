import React, { useEffect, useState } from 'react';
import Performance from './Performance';
import Positions from './Positions';
import BestWorstPerformers from './BestWorstPerformers';
import PortfolioBreakdown from './PortfolioBreakdown';
import { Row, Col, Card, ListGroup, ListGroupItem } from 'react-bootstrap';
import InfoRow from './InfoRow';
import GainLossWallet from './GainLossWallet';
import GainLossAssets from './GainLossAssets';
import Collectibles from './Collectibles';
import { useStoreApi } from '../../store/storeApi';
import PORTFOLIO_IMAGE from '../../assets/img/login_portfolio_PDX.svg';
import { getTokenBreakDownData } from '../../shared/commonUtils';

function Portfolio() {
    const { address, getWalletTokenData } = useStoreApi();
    const [breakdownData, setBreakDownData] = useState(null);
    useEffect(async () => {
        if (address) {
            getWalletTokenData(address);
            const res = await getTokenBreakDownData(address);
            setBreakDownData(res);
        }
    }, [address]);
    return (
        <div className="container-fluid pt-1" style={{ borderTop: '1px solid var(--color-border-grey)', marginTop: '-3px' }}>
            {/* {address ? ( */}
            <div className="vh-100 row">
                {/* <Col lg={3} sm={12} style={{ borderRight: '1px solid var(--color-border-grey)', marginTop: '-4px' }}>
                        <Row className="pl-3 pt-3">
                            <h6>Views</h6>
                        </Row>
                        <Row className="p-3">
                            <Col className="m-0 p-0">
                                <ListGroup variant="primary">
                                    <ListGroupItem>Overview</ListGroupItem>
                                    <ListGroupItem>Positions</ListGroupItem>
                                </ListGroup>
                            </Col>
                        </Row>
                    </Col> */}
                <Col>
                    <Row className="p-3">
                        <PortfolioBreakdown />
                        <Positions />
                    </Row>
                </Col>
                {/* <Row className="d-flex m-0 align-items-center">
                        <Col sm={6}>
                            <h3 style={{ color: 'white', fontWeight: 300 }}>Performance graph (7D)</h3>
                        </Col>
                        <Col sm={6}>
                            <InfoRow />
                        </Col>
                        <Col sm={12} className="">
                            <Performance />
                        </Col>
                    </Row>
                    <Row className="m-0 d-flex">
                        <Col sm={3} className="p-2">
                            <PortfolioBreakdown breakdown={breakdownData} />
                        </Col>
                        <Col sm={3} className="p-2">
                            <GainLossAssets />
                        </Col>
                        <Col sm={3} className="p-2">
                            <GainLossWallet />
                        </Col>
                        <Col sm={3} className="p-2">
                            <BestWorstPerformers breakdown={breakdownData} />
                        </Col>
                    </Row>
                    <Row className="m-0">
                        <Col sm={12}>
                            <Positions />
                        </Col>
                        <Col sm={12}>
                            <Collectibles />
                        </Col>
                    </Row> */}
            </div>
            {/* ) : (
                <div>Coming soon</div>
            )} */}
        </div>
    );
}

export default Portfolio;
