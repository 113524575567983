import { Card, FormControl, InputGroup, Container, Button, Modal, Row, Col, ListGroup, Form, Alert, Image, Spinner } from 'react-bootstrap';
import { HiOutlineSwitchVertical, HiCog } from 'react-icons/hi';
import { BiWallet } from 'react-icons/bi';
import { ImWarning } from 'react-icons/im';
import { useCallback, useEffect, useState } from 'react';
import { utils as etils, BigNumber, constants } from 'ethers';
import { useAccount, useConnect, useSendTransaction } from 'wagmi';
import debounce from 'lodash.debounce';
import useWeb3 from '../../../useWeb3';
import {
    searchToken,
    getDecimals,
    getEthInstance,
    DisplayErrorMsg,
    getDecimalString,
    fetchTradesimulationData
} from '../../../shared/commonUtils';
import IERC20 from '../../../abis/IERC20.json';
import { useStoreApi } from '../../../store/storeApi';

// Importing children
import TradeSummary from './TradeSummary.js';
import SearchTokenPopup from './SearchTokenPopup';
import SettingsPopup from './SettingsPopup';

// 🟠 Move to separate file when done
function InverseSwap({ onClick }) {
    return (
        <center>
            <HiOutlineSwitchVertical
                className="inverse-button"
                onClick={onClick}
                size={26}
                style={{
                    // backgroundColor: '#292D40',
                    borderRadius: 100,
                    padding: 4,
                    color: 'var(--text-base)',
                    marginTop: '9px',
                    marginBottom: '-9px'
                }}
            />
        </center>
    );
}

// 🟠 Move to separate file when done
const TradeButton = ({ inValue, OnClickSwap, btnString, isLoading }) => {
    const { address, isConnecting, isDisconnected, status } = useAccount();

    if (status === 'disconnected')
        return (
            <Button variant="trade-unavailable" disabled>
                Please connect
            </Button>
        );
    if (status === 'connecting') return <Button>Spinner</Button>;
    if (status === 'connected') {
        // Input field is empty
        if (inValue === '' || 0) return <Button disabled>Enter an amount</Button>;
        // Input field is not empty
        if (inValue !== '' || 0) {
            return (
                <Button onClick={OnClickSwap} disabled={btnString === 'Insufficient funds'}>
                    {isLoading || btnString}
                </Button>
            );
        }
        // if (hasAllowance === false) return <Button>Set Allorance</Button>;
        // if (hasAllowance === true) return <Button>Set Allorance</Button>;
    }
    if (isLoading === true)
        <Button>
            <Spinner animation="border" size="sm" variant="light" />
        </Button>;
    return (
        <Button>
            <Spinner animation="border" size="sm" variant="light" />
        </Button>
    );
};

export const TradingWindow = (props) => {
    const web3 = useWeb3();
    const [tokenResponse, setTokenResponse] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [popupState, setPopupState] = useState(null);
    const [fromToken, setFromToken] = useState({ token: 'BNB', address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', decimal: 18 });
    const [toToken, setToToken] = useState({ token: 'PDX', address: '0x43a0c5eb1763a211aa3c05849a617f2ee0452767', decimal: 9 });
    const [btnString, setBtnString] = useState('Loading...');
    const [isLoading, setIsLoading] = useState(false);
    const [inValue, setInValue] = useState('');
    const [outValue, setOutValue] = useState('');
    const [swapParams, setSwapParams] = useState(null);
    const [errorMsg, setErrorMsg] = useState('');
    const [tradeWarning, setTradeWarning] = useState('');
    const [fromTokenBalance, setFromTokenBalance] = useState(0);
    const [toTokenBalance, setToTokenBalance] = useState(0);

    // modal for share + settings
    const [showShare, setShowShare] = useState(false);
    const [slippage, setSlippage] = useState(null);
    const [minOutputAmount, setMinOutputAmount] = useState(0);
    const { metamaskStatus, connectMetaMask, allTokens, token, tokenAddress, walletObj, walletType } = useStoreApi();

    // wagmi
    const { address, isConnected, connector } = useAccount();
    const { isSuccess } = useConnect();

    // Swap Router
    const ALLOWANCE_TARGET = '0x00555513Acf282B42882420E5e5bA87b44D8fA6E'; // v3

    // eslint-disable-next-line react-hooks/exhaustive-deps -- SETS SLIPPAGE
    useEffect(async () => {
        if (tokenAddress) {
            const res = await fetchTradesimulationData(tokenAddress);
            if (res?.tokenSymbol == 'WBNB') {
                setTradeWarning('');
                setSlippage(0 + 0.3);
            }
            if (res?.buyFee !== undefined) {
                setTradeWarning('');
                setSlippage(Math.round(res.buyFee) + 0.3);
            }
            if (res?.buyFee <= 21) {
                setTradeWarning('');
                setSlippage(Math.round(res.buyFee) + 0.3);
            }
            if (res?.buyFee >= 21) {
                setSlippage(Math.round(res.buyFee));
                setTradeWarning('Warning: Fees are too high! DYOR!');
            }
        }
    }, [tokenAddress]);

    function TradeWarning() {
        if (tradeWarning !== '') {
            return (
                <Alert style={{ padding: '9px 10px 7px 10px', borderRadius: '7px' }} variant="danger">
                    <ImWarning size={16} style={{ marginTop: '-9px', marginRight: '5px' }} />
                    {tradeWarning}
                </Alert>
            );
        }
        if (tradeWarning === '') {
            return <></>;
        }
    }

    const getRPCErrorMessage = (err) => {
        const open = err.stack.indexOf('{');
        const close = err.stack.lastIndexOf('}');
        const j_s = err.stack.substring(open, close + 1);
        const j = JSON.parse(j_s);
        return j.message;
    };

    const handleErrorCallback = (error) => {
        setIsLoading(false);
        setErrorMsg(error.message);
    };

    // 🟠 Update callSwap function inside
    const handleSuccessCallback = (result) => {
        DisplayErrorMsg(`Transaction receipt ${result}`);
        const callSwap = async () => {
            try {
                setIsLoading(true);
                const instance = await getEthInstance();
                const amountIn = fromToken.token === 'BNB' ? swapParams.inputAmount : 0;
                // calculate gas estimate for
                const estimateGasOption = {
                    from: address,
                    to: swapParams.routerAddress,
                    data: swapParams.encodedSwapData,
                    value: BigNumber.from(amountIn)
                };

                let gasEstimate;
                let gasPrice;
                // call to get gas estimate
                try {
                    gasEstimate = await instance.eth.estimateGas(estimateGasOption);
                } catch (err) {
                    console.error('Failed to estimate gas.', err);
                    const message = getRPCErrorMessage(err);
                    setErrorMsg(message);
                    setIsLoading(false);
                    return;
                }
                try {
                    gasPrice = await instance.eth.getGasPrice();
                } catch (err) {
                    console.error('Failed to get gas price', err);
                }

                const transactionData = {
                    from: address,
                    to: swapParams.routerAddress,
                    data: swapParams.encodedSwapData,
                    value: BigNumber.from(amountIn),
                    gasLimit: gasEstimate,
                    gasPrice
                };

                if (connector.name === 'MetaMask') {
                    const tx = await instance.eth.sendTransaction(transactionData, (error, result) => {
                        if (error) {
                            handleErrorCallback(error);
                        } else {
                            handleSuccessCallback(result);
                        }
                    });
                } else {
                    data.connector
                        .sendTransaction(transactionData)
                        .then((result) => {
                            handleSuccessCallback(result);
                        })
                        .catch((error) => {
                            handleErrorCallback(error);
                        });
                }
            } catch (error) {
                setIsLoading(false);
                console.error(error);
            }
        };
        setInValue(0);
        setOutValue(0);
        setSwapParams(null);
    };

    // 🟠 BELOW FUNCTION NEEDS TO BE FIXED
    const callSwap = async () => {
        try {
            setIsLoading(true);
            const instance = await getEthInstance();
            const amountIn = fromToken.token === 'BNB' ? swapParams.inputAmount : 0;
            // calculate gas estimate for
            const estimateGasOption = {
                from: address,
                to: swapParams.routerAddress,
                data: swapParams.encodedSwapData,
                value: BigNumber.from(amountIn)
            };

            let gasEstimate;
            let gasPrice;
            // call to get gas estimate
            try {
                gasEstimate = await instance.eth.estimateGas(estimateGasOption);
            } catch (err) {
                console.error('Failed to estimate gas.', err);
                const message = getRPCErrorMessage(err);
                setErrorMsg(message);
                setIsLoading(false);
                return;
            }
            try {
                gasPrice = await instance.eth.getGasPrice();
            } catch (err) {
                console.error('Failed to get gas price', err);
            }

            const transactionData = {
                from: address,
                to: swapParams.routerAddress,
                data: swapParams.encodedSwapData,
                value: BigNumber.from(amountIn),
                gasLimit: gasEstimate,
                gasPrice
            };

            if (connector.name === 'MetaMask') {
                const tx = await instance.eth.sendTransaction(transactionData, (error, result) => {
                    if (error) {
                        handleErrorCallback(error);
                    } else {
                        handleSuccessCallback(result);
                    }
                });
            } else {
                data.connector
                    .sendTransaction(transactionData)
                    .then((result) => {
                        handleSuccessCallback(result);
                    })
                    .catch((error) => {
                        handleErrorCallback(error);
                    });
            }
        } catch (error) {
            setIsLoading(false);
            console.error(error);
        }
    };

    // can be replaced with wagmi useContract
    const getERC20Contract = async (tokenAddrs) => {
        const instance = await getEthInstance();
        instance.eth.defaultAccount = address;
        return new instance.eth.Contract(IERC20, tokenAddrs);
    };

    const inverseSwap = async () => {
        // Inverting the amounts.
        const prevInValue = inValue;
        setInValue(outValue);
        setOutValue(prevInValue);

        // Inverting Tokens.
        const prevToToken = toToken;
        setToToken({ ...fromToken });
        setFromToken({ ...prevToToken });

        if (address) {
            const prevFromTokenBalance = fromTokenBalance;
            setFromTokenBalance(toTokenBalance);
            setToTokenBalance(prevFromTokenBalance);
        }
    };

    // BALANCES
    const getUserBalance = async (token) => {
        try {
            setFromTokenBalance(
                <Spinner
                    variant="secondary"
                    animation="border"
                    size="sm"
                    style={{ position: 'absolute', marginLeft: '4px', width: '13px', height: '13px', marginTop: '2px' }}
                >
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            );
            setToTokenBalance(
                <Spinner
                    variant="secondary"
                    animation="border"
                    size="sm"
                    style={{ position: 'absolute', marginLeft: '4px', width: '13px', height: '13px', marginTop: '2px' }}
                >
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            );
            let balance = null;
            await web3;
            if (token === '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE') {
                balance = await web3?.eth.getBalance(address);
            } else {
                const contract = await getERC20Contract(token);
                // const decimals = await contract.methods.decimals().call();
                balance = await contract.methods.balanceOf(address).call();
            }

            if (token === fromToken.address) {
                if (token === '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE') {
                    return parseFloat(web3?.utils.fromWei(balance, 'ether')).toFixed(4);
                }
                await balance;
                const contract = await getERC20Contract(token);
                const decimals = await contract.methods.decimals().call();
                const processedDecimal = await getDecimalString(decimals);
                return parseFloat(web3?.utils.fromWei(balance, processedDecimal)).toFixed(4);

                // return;
            }

            if (token === toToken.address) {
                if (token === '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE') {
                    return parseFloat(web3.utils?.fromWei(balance, 'ether')).toFixed(4);
                }
                const contract = await getERC20Contract(token);
                const decimals = await contract.methods.decimals().call();
                const processedDecimal = await getDecimalString(decimals);
                // console.log(processedDecimal);
                return parseFloat(web3.utils?.fromWei(balance, processedDecimal)).toFixed(4);

                // return parseFloat(web3.utils.fromWei(balance, 'Gwei')).toFixed(2);
            }
            return 'meep';
        } catch (e) {
            console.error(`Failed to get user balance for token ${token}`, e);
            return 'Err';
        }
    };

    const updateFromBalance = async () => {
        try {
            if (web3 && fromToken && address) {
                setFromTokenBalance(await getUserBalance(fromToken.address));
            }
        } catch (e) {
            console.log(e);
        }
    };

    const updateToBalance = async () => {
        try {
            if (web3 && toToken && address) {
                setToTokenBalance(await getUserBalance(toToken.address));
            }
        } catch (e) {
            console.log(e);
        }
    };

    // 🟠 BELOW FUNCTION NEEDS TO BE FIXED
    const retrieveSwapData = async (tokenIn, tokenOut, amountIn, swapSlippage) => {
        const opts = {
            amountIn: etils.parseUnits(amountIn, tokenIn.decimal),
            chain: 'bsc',
            recipient: address || '0x0000000000000000000000000000000000000000',
            tokenIn: tokenIn.address,
            tokenOut: tokenOut.address,
            chargeFeeBy: 'currency_in',
            clientData: { source: 'PDX.EXCHANGE' },
            deadline: Date.now() + 20 * 60 * 1000,
            feeAmount: '10',
            feeReceiver: '0xcBF4DAeDF93a623a6A82466C5ba1257A5FB0cA51',
            isInBps: true,
            saveGas: '0',
            slippageTolerance: swapSlippage * 100
        };

        try {
            const r = await fetch(
                `https://aggregator-api.kyberswap.com/${opts.chain}/route/encode?tokenIn=${opts.tokenIn}&tokenOut=${opts.tokenOut}&amountIn=${opts.amountIn}&saveGas=${opts.saveGas}&slippageTolerance=${opts.slippageTolerance}&deadline=${opts.deadline}&to=${opts.recipient}`
            );
            return r.json();
        } catch (error) {
            console.error('Failed to get swap data', error);
            return null;
        }
    };

    // 🟠 BELOW FUNCTION NEEDS TO BE FIXED
    const updateSwapData = async (amountIn) => {
        const processedAmountIn = amountIn.replace(',', '.');
        if (parseFloat(processedAmountIn) <= -1 || processedAmountIn === '') {
            setInValue('');
            setOutValue('');
            return; // no action required.
        }
        setInValue(processedAmountIn);

        // If user wrote 0 it probably means he's trying to set amountIn as a float (e.g. 0.1)
        // there's no need to call the kyberswap api yet.
        if (parseFloat(processedAmountIn) === 0) return;

        const swapData = await retrieveSwapData(fromToken, toToken, processedAmountIn, slippage);
        if (swapData === null) {
            setErrorMsg('Cannot get swap data. Please try again');
            return;
        }
        setSwapParams(swapData);
        setOutValue(etils.formatUnits(swapData.outputAmount, toToken.decimal));
    };

    // 🟠 BELOW FUNCTION NEEDS TO BE FIXED
    const hasEnoughBalance = async (token, amount) => {
        const balance = await getUserBalance(token.address);
        // console.log('swap');
        // console.log(`balance:${balance}`);
        // console.log(`amount:${amount}`);
        // console.log(`hasEnoughBalance:${balance}` >= amount);
        return balance >= amount;
    };

    // 🟠 BELOW FUNCTION NEEDS TO BE FIXED
    const hasAllowance = async (token, amount) => {
        if (address && token.token !== 'BNB') {
            setIsLoading(true);
            amount = etils.parseUnits(amount, token.decimal);
            const contract = await getERC20Contract(token.address);
            try {
                const allowance = await contract.methods.allowance(address, ALLOWANCE_TARGET).call();
                setIsLoading(false);
                return BigNumber.from(allowance).gte(BigNumber.from(amount));
            } catch (error) {
                console.error('Failed to get allowance', error);
                setIsLoading(false);
                return false;
            }
        }
    };
    // 🟠 BELOW FUNCTION NEEDS TO BE FIXED
    const approveAllowance = async (token) => {
        setIsLoading(true);

        const contract = await getERC20Contract(token.address);

        contract.methods.approve(ALLOWANCE_TARGET, constants.MaxUint256).send({ from: address }, (error, result) => {
            if (error) {
                setErrorMsg(error.message);
            } else {
                let receiptInt;
                const callback = () => {
                    clearInterval(receiptInt);
                };
                receiptInt = setInterval(async () => {
                    const instance = await getEthInstance();
                    instance.eth.getTransactionReceipt(result, (error, receipt) => {
                        setIsLoading(false);
                        if (error) {
                            setErrorMsg(error.message);
                        } else if (receipt) {
                            clearInterval(receiptInt);
                            setBtnString('Swap');
                            callback();
                        }
                    });
                }, 3000);
            }
        });
    };

    // updating slippage fills the inputSlp field
    const updateSlippage = (value) => {
        const slpInput = (document.getElementById('inputSlp').value = value);
    };
    // saves the input
    const OnSaveSettings = () => {
        let slpInput = document
            .getElementById('inputSlp')
            .value.replace(',', '.')
            .replace(/[^\d.-]/g, '');
        if (slpInput.includes('.')) {
            slpInput = slpInput.split('.');
            slpInput = `${slpInput.shift()}.${slpInput.join('')}`;
        }
        setSlippage(slpInput);
        setShowShare(false);
    };

    // sets balance when user connects wallet
    useEffect(() => {
        if (isConnected && web3) {
            updateFromBalance().then();
            updateToBalance().then();
        }
    }, [web3]);

    useEffect(() => {
        updateFromBalance().then();
    }, [fromToken]);

    useEffect(() => {
        updateToBalance().then();
    }, [toToken]);

    useEffect(() => {
        updateFromBalance().then();
        updateToBalance().then();
    }, [isConnected]);

    useEffect(() => {
        if (errorMsg !== '') {
            setTimeout(() => {
                setErrorMsg('');
            }, 5000);
        }
    }, [errorMsg]);

    useEffect(() => {
        const mUpdateSwapData = async () => {
            await updateSwapData(inValue);
        };
        mUpdateSwapData().then();
    }, [fromToken, toToken, address, slippage]); // address is needed so when user connects to wallet we retrieve the swap data with receiver address.

    // Search popup
    const checkSearchToken = (selected) => {
        if (popupState === 'TOTOKEN' && selected.symbol === fromToken.token) {
            return false;
        }
        if (popupState === 'FROMTOKEN' && selected.symbol === toToken.token) {
            return false;
        }
        return true;
    };
    const searchTokenPopup = (type) => {
        setShowModal(true);
        setPopupState(type);
    };

    const getSuggestions = async (word) => {
        const response = await searchToken(word);
        if (response && response.length) {
            setTokenResponse(response);
        } else {
            setTokenResponse(response);
            const pattern = /0x/i;
            if (word.match(pattern)) {
                const apiResponse = await getDecimals(word, true);
                if (apiResponse) {
                    const result = [
                        {
                            label: apiResponse.name,
                            value: apiResponse.address,
                            symbol: apiResponse.symbol,
                            desc: apiResponse.name,
                            isNew: true,
                            logo: 0
                        }
                    ];
                    setTokenResponse(result);
                }
            }
        }
    };
    const selectToken = async (selected) => {
        if (checkSearchToken(selected)) {
            const decimal = await getDecimals(selected.value, false);
            if (popupState === 'TOTOKEN') {
                setToToken({ token: selected.symbol, address: selected.value, decimal });
            } else if (popupState === 'FROMTOKEN') {
                setFromToken({ token: selected.symbol, address: selected.value, decimal });
            }
        }
        setShowModal(false);
    };

    // 🟡 TODO: setTimeout() || interval for half or quoter sec. before triggering "onQuantityChange" to avoid excessive API calls to Kyber Aggregator API
    const onQuantityChange = async (e, initiator) => {
        await updateSwapData(e.target.value);
        if (e.target.value > fromTokenBalance) setBtnString('Insufficient funds');
        if (e.target.value < fromTokenBalance) setBtnString('Swap');
    };

    const setMaxValue = () => {
        if (parseFloat(fromTokenBalance) > 0) {
            updateSwapData(fromTokenBalance);
        }
    };

    // Handles the state of the button as well as triggers specific actions.
    const onClickWalletBtn = async () => {
        if (inValue === '' || parseFloat(inValue) <= 0) return;

        // See if user has enough balance.
        if ((await hasEnoughBalance(fromToken, inValue)) === false) {
            setErrorMsg('Insufficient Balance');
            return;
        }

        if (btnString === 'Approve') {
            await approveAllowance(fromToken);
            return;
        }

        // See if user has approved allowance.
        if ((await hasAllowance(fromToken, inValue)) === false) {
            setBtnString('Approve');
            setErrorMsg('Set allowance');
            console.log(hasAllowance);
            return;
        }
        // User is good to swap.
        await callSwap();
        await updateFromBalance();
        await updateToBalance();
    };

    return (
        <Card
            style={{
                borderRadius: '7px',
                boxShadow: 'none',
                backgroundColor: 'transparent',
                border: 'none',
                height: '100%'
            }}
        >
            <Card.Body className="m-0 px-1 py-3">
                <Row>
                    <Col>
                        <Card.Title className="mb-3" style={{ fontSize: 16, fontWeight: 400, color: 'var(--text-base)' }}>
                            Aggregator
                        </Card.Title>
                    </Col>
                    <Col className="d-flex justify-content-end align-content-center">
                        <Button variant="default-dark" style={{ marginTop: '-5px' }} onClick={() => setShowShare(true)}>
                            <HiCog size={17} style={{ color: 'var(--text-base)', marginLeft: '-8px', marginTop: '-18px' }} />
                        </Button>
                    </Col>
                </Row>
                <SettingsPopup
                    show={showShare}
                    onHide={() => setShowShare(false)}
                    OnSave={OnSaveSettings}
                    OnUpdateSlippage={updateSlippage}
                />
                <TradeWarning />
                <Container
                    style={{
                        padding: 10,
                        marginTop: 5,
                        backgroundColor: 'var(--background-light)',
                        borderRadius: 7
                    }}
                    className="mobiletrading"
                >
                    <Row style={{ marginTop: '-5px', marginBottom: '3px' }}>
                        <Col className="d-flex justify-content-between">
                            <span className="d-flex justify-content-end mr-1 mt-1 text-muted">
                                {address ? (
                                    <div className="cursor-pointer" onClick={() => setMaxValue()}>
                                        <BiWallet size={12} style={{ marginRight: '5px', marginTop: '-9px', marginLeft: '4px' }} />
                                        <span style={{ fontFamily: 'monotalic', marginTop: '-4px' }}>{fromTokenBalance}</span>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </span>
                            <span
                                style={{
                                    fontFamily: 'monotalic',
                                    color: 'var(--text-base)',
                                    marginRight: '4px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    fontSize: '12px',
                                    fontWeight: '100'
                                }}
                            >
                                ~${swapParams ? swapParams.amountInUsd.toFixed(2) : '0'}
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <InputGroup className="shadow-none">
                            <FormControl
                                type="text"
                                className="amount-in-out"
                                aria-label="Text input with dropdown button"
                                style={{
                                    fontSize: 15,
                                    fontWeight: 400,
                                    lineHeight: 0,
                                    paddingLeft: 5,
                                    outline: 'none'
                                }}
                                placeholder="0"
                                value={inValue}
                                onChange={(e) => onQuantityChange(e, 'fromToken')}
                            />
                            <Button
                                variant="token"
                                id="input-group-dropdown-2"
                                align="end"
                                onClick={() => searchTokenPopup('FROMTOKEN')}
                                style={{ fontFamily: 'monotalic' }}
                            >
                                {fromToken.token}
                            </Button>
                        </InputGroup>
                    </Row>
                </Container>
                <InverseSwap onClick={inverseSwap} />
                <Container
                    style={{
                        padding: 10,
                        marginTop: 15,
                        backgroundColor: 'var(--background-light)',
                        borderRadius: 10
                    }}
                    className="mobiletrading"
                >
                    <Row style={{ marginTop: '-5px', marginBottom: '3px' }}>
                        <Col className="d-flex justify-content-between">
                            <span className="d-flex justify-content-end mr-1 mt-1 text-muted">
                                {address ? (
                                    <div>
                                        <BiWallet size={12} style={{ marginRight: '5px', marginTop: '-9px', marginLeft: '4px' }} />
                                        <span style={{ fontFamily: 'monotalic', marginTop: '-4px' }}>{toTokenBalance}</span>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </span>
                            <span
                                style={{
                                    fontFamily: 'monotalic',
                                    color: 'var(--text-base)',
                                    marginRight: '4px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    fontSize: '12px',
                                    fontWeight: '100'
                                }}
                            >
                                ~${swapParams ? swapParams.amountOutUsd.toFixed(2) : '0'}
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <InputGroup className="shadow-none">
                            <FormControl
                                type="text"
                                className="amount-in-out shadow-none"
                                aria-label="Text input with dropdown button"
                                style={{
                                    fontSize: 15,
                                    fontWeight: 400,
                                    lineHeight: 0,
                                    paddingLeft: 5,
                                    outline: 'none',
                                    paddingRight: '5px'
                                }}
                                placeholder="0"
                                value={outValue}
                                disabled
                                onChange={(e) => onQuantityChange(e, 'toToken')}
                            />

                            <Button
                                variant="token"
                                id="input-group-dropdown-2"
                                align="end"
                                onClick={() => searchTokenPopup('TOTOKEN')}
                                style={{ fontFamily: 'monotalic' }}
                            >
                                {toToken.token}
                            </Button>
                        </InputGroup>
                    </Row>
                </Container>
                <SearchTokenPopup
                    show={showModal}
                    onHide={() => setShowModal(!showModal)}
                    tokenResponse={tokenResponse}
                    requests={getSuggestions}
                    selectToken={selectToken}
                />
                <TradeSummary swapParams={swapParams} toToken={toToken} slippage={slippage} />
                <TradeButton OnClickSwap={onClickWalletBtn} inValue={inValue} fromToken={fromToken} btnString={btnString} />
                {errorMsg !== '' ? (
                    <div className="error mt-3">
                        <span className="errorMsg">{errorMsg}</span>
                    </div>
                ) : null}
            </Card.Body>
        </Card>
    );
};
