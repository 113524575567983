import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import './AutoCompleateInput.scss';
import { Form, Button } from 'react-bootstrap';
import PLACEHOLDER from '../../assets/img/placeholder.svg';
import { CDN_URL } from '../../shared/ApiURL';

class Autocomplete extends Component {
    static propTypes = {
        suggestions: PropTypes.instanceOf(Array)
    };

    static defaultProps = {
        suggestions: []
    };

    constructor(props) {
        super(props);
        this.state = {
            // The active selection's index
            activeSuggestion: 0,
            // The suggestions that match the user's input
            filteredSuggestions: [],
            // Whether or not the suggestion list is shown
            showSuggestions: false,
            userInput: ''
            // What the user has entered
            // userInput: props.inputVal ? props.inputVal : ""
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState((prevstate) => ({
            ...prevstate,
            filteredSuggestions: nextProps.suggestions ? nextProps.suggestions : []
        }));
        if (nextProps && nextProps.setUserInput) {
            this.setState((prevstate) => ({
                ...prevstate,
                userInput: nextProps.userInput ? nextProps.userInput : ''
            }));
        }
    }

    onChange = (e) => {
        const userInput = e.currentTarget.value;
        this.props.handleOnSearch(userInput);
        // Filter our suggestions that don't contain the user's input
        const { filteredSuggestions } = this.state;

        this.setState({
            activeSuggestion: 0,
            filteredSuggestions,
            showSuggestions: true,
            userInput: e.currentTarget.value
        });
    };

    onClick = (suggestion) => {
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: suggestion.label
        });
        this.props.setToken(suggestion);
    };

    render() {
        const {
            onChange,
            onKeyDown,
            state: { filteredSuggestions, showSuggestions, userInput }
        } = this;
        let suggestionsListComponent;
        if (userInput && showSuggestions) {
            if (filteredSuggestions.length) {
                let contentType = '';
                suggestionsListComponent = (
                    <ul className="suggestions">
                        {filteredSuggestions.map((suggestion, index) => {
                            if (
                                suggestion.desc === '' &&
                                suggestion.typeval === '' &&
                                suggestion.checkMark === '' &&
                                suggestion.logo === ''
                            ) {
                                contentType = suggestion.label;
                                suggestion.value = '';
                                return (
                                    <li className="mb-0 ether-search-heading" onClick={() => this.onClick(suggestion)}>
                                        <h5 className="h6 font-weight-bold bg-light px-2 py-1 rounded mb-2">
                                            {suggestion.label === 'Private' ? 'Private Name Tags' : suggestion.label}
                                        </h5>
                                    </li>
                                );
                            }
                            if (contentType === 'Private') {
                                const addressContent = suggestion.label.substring(0, 42);
                                return (
                                    <li className="mb-2" onClick={() => this.onClick(suggestion)}>
                                        <a className="ether-search media rounded p-2" href="javascript:;">
                                            <div className="mr-2">
                                                <span className="btn btn-xs btn-icon btn-soft-secondary rounded-circle">
                                                    <i className="fa fa-tag btn-icon__inner" />
                                                </span>
                                            </div>
                                            <div className="media-body text-truncate">
                                                <span className="u-label u-label--value u-label--secondary">
                                                    {addressContent.length > 25 ? `${addressContent.substring(0, 25)}...` : addressContent}
                                                </span>
                                            </div>
                                        </a>
                                    </li>
                                );
                            }
                            if (contentType === 'Tokens (BEP 20)' || contentType === 'Tokens (ERC 721)' || contentType === '') {
                                const website =
                                    suggestion.desc.split('~')[1] != undefined ? (
                                        <div className="text-secondary font-size-1 text-truncate"> {suggestion.desc.split('~')[1]} </div>
                                    ) : (
                                        ''
                                    );
                                const tokenType = suggestion.desc.split('~')[2];
                                const price =
                                    tokenType != '2' ? (
                                        suggestion.desc.split('~')[3] != undefined ? (
                                            <span className="badge bg-soft-secondary mr-2">{suggestion.desc.split('~')[3]}</span>
                                        ) : (
                                            ''
                                        )
                                    ) : (
                                        ''
                                    );

                                const imageUrl = suggestion.logo
                                    ? `${CDN_URL}/${suggestion.value.toUpperCase()}.png`
                                    : `${CDN_URL}/placeholder.svg`;

                                return (
                                    <li className="mb-2" onClick={() => this.onClick(suggestion)}>
                                        <a className="ether-search media rounded p-2" href="javascript:;">
                                            <div className="mr-2">
                                                <img
                                                    className="u-xs-avatar"
                                                    src={imageUrl}
                                                    // onError={(e) => {
                                                    //     e.target.src = CDN_URL + '/placeholder.svg';
                                                    //     e.target.onError = null;
                                                    // }}
                                                />
                                            </div>
                                            <div className="media-body text-truncate">
                                                <h6 className="d-flex text-nodecor text-size-1 mb-0" style={{ marginBottom: '-30px' }}>
                                                    <div className="text-truncate text-searchh mb-0 pb-0 mr-2">
                                                        <span className="token-name">{suggestion.label}</span>({suggestion.symbol})
                                                    </div>
                                                    <i className="fa fa-badge-check text-info ml-auto" />
                                                </h6>
                                                <span className="text-search text-truncate font-size-1">{suggestion.value}</span>
                                            </div>
                                        </a>
                                    </li>
                                );
                            }
                            if (contentType === 'Addresses') {
                                const addressContent = suggestion.label.substring(0, 42);
                                return (
                                    <li className="mb-2" onClick={() => this.onClick(suggestion)}>
                                        <a className="ether-search media rounded p-2" href="javascript:;">
                                            <div className="mr-2">
                                                <img className="u-xs-avatar" src={PLACEHOLDER} />
                                            </div>
                                            <div className="media-body text-truncate">
                                                <h6 className="text-size-1 mb-0 text-truncate">{addressContent}</h6>
                                            </div>
                                        </a>
                                    </li>
                                );
                            }
                            if (contentType === 'Labels') {
                                return (
                                    <li className="mb-2" onClick={() => this.onClick(suggestion)}>
                                        <a className="ether-search media rounded p-2" href="javascript:;">
                                            <div className="mr-2">
                                                <span className="btn btn-xs btn-icon btn-soft-secondary rounded-circle">
                                                    <i className="fa fa-tag btn-icon__inner" />
                                                </span>
                                            </div>
                                            <div className="media-body text-truncate">
                                                <h6 className="text-size-1 mb-0">{suggestion.label.substring(0, 42)}</h6>
                                            </div>
                                        </a>
                                    </li>
                                );
                            }
                        })}
                    </ul>
                );
            } else {
                suggestionsListComponent = (
                    <ul className="suggestions">
                        <li className="mb-2">
                            <div className="d-flex row justify-content-center align-items-center media-body mb-2 text-truncate">
                                <p className="text-center notfoundsearch">We are not tracking this token yet</p>
                                <Button className="import-btn" size="sm" onClick={() => this.props.onimport()}>
                                    Import manually
                                </Button>{' '}
                            </div>
                        </li>
                    </ul>
                );
            }
        }

        return (
            <>
                <div className="auto-c-posi-rel">
                    <Form.Group>
                        <Form.Control
                            type="text"
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            value={userInput}
                            placeholder={this.props.placeholderText}
                            className={this.props.classProp}
                            trim="blur"
                        />{' '}
                    </Form.Group>
                    {suggestionsListComponent}
                </div>
            </>
        );
    }
}

export default Autocomplete;
