import { Container, Row, Col } from 'react-bootstrap';

export default function MarketsOverview() {
    return (
        <Container fluid className="pr-3 txtable pb-3">
            <Row>
                <Col>Losh</Col>
            </Row>
        </Container>
    );
}
