import React, { useEffect, useState } from 'react';
import { Col, OverlayTrigger, Row, Tooltip, Button } from 'react-bootstrap';
import { useHistory, Link, NavLink } from 'react-router-dom';
import { useMoralis } from 'react-moralis';
import { BiHide } from 'react-icons/bi';
import { FaTrashRestoreAlt } from 'react-icons/fa';
import { useStoreApi } from '../store/storeApi';
import useWeb3 from '../useWeb3';
import { numberWithCommas, getBnbToUSD, getBlackListedTokens, DisplayErrorMsg, addHideToken } from '../shared/commonUtils';
import IMG_HIDE from '../assets/img/hide.svg';
import { MORALIS_FIAT_PROD_KEY, MORALIS_SERVER_URL, MORALIS_APP_ID, CDN_URL } from '../shared/ApiURL';

function WalletWidget(props) {
    const {
        activePair,
        balance,
        address,
        allTokens,
        basetokenprice,
        setAddress,
        setBalance,
        setAllTokens,
        metamaskConnected,
        setMetamaskStatus,
        getPokedxPriceInUSD,
        setWalletObject
    } = useStoreApi();
    const web3 = useWeb3();
    const [showModal, setShowModal] = useState(false);
    const [tokenResponse, setTokenResponse] = useState([]);
    const [userInput, setUserInput] = useState('');
    const history = useHistory();

    // Wallet Connect
    const { user, Moralis } = useMoralis();
    const showRestoreBtn = () => localStorage.getItem('hideTokens') != null;

    const connectToWallet = async () => {
        setShowModal(false);
        const walletConnector = new NodeWalletConnect(
            {
                bridge: 'https://bridge.walletconnect.org' // Required
            },
            {
                clientMeta: {
                    description: 'pokedx.app connect',
                    url: 'https://nodejs.org/en/',
                    icons: ['https://nodejs.org/static/images/logo.svg'],
                    name: 'Pokedx.app'
                }
            }
        );

        if (!walletConnector.connected) {
            // create new session
            walletConnector.createSession().then(() => {
                // get uri for QR Code modal
                const { uri } = walletConnector;
                // display QR Code modal
                WalletConnectQRCodeModal.open(
                    uri,
                    () => {
                        console.warn('QR Code Modal closed');
                    },
                    true // isNode = true
                );
            });
        } else {
            setWalletObject('WALLET', walletConnector);
            setConnection(walletConnector.accounts);
        }

        walletConnector.on('connect', (error, payload) => {
            if (error) {
                throw error;
            }

            // Close QR Code Modal
            WalletConnectQRCodeModal.close(
                true // isNode = true
            );

            // Get provided accounts and chainId
            setConnection(payload?.params[0].accounts);
        });

        // const user = await authenticate({
        //     provider: 'walletconnect',
        //     chainId: 56,
        //     mobileLinks: [],
        //     signingMessage: 'Connect to pokedex.app'
        // });
    };
    const setConnection = (payload) => {
        if (payload.length) {
            setMetamaskStatus('CONNECTED');
            setAddress(payload[0]);
            updateBalance(payload[0]);
        }
    };

    useEffect(() => {
        if (user && user.get('ethAddress')) {
            setMetamaskStatus('CONNECTED');
            setAddress(user.get('ethAddress'));
            updateBalance(user.get('ethAddress'));
        }
    }, [user]);

    // end wallet connect
    const getUserAccount = async () => {
        setShowModal(false);
        if (window.ethereum) {
            try {
                if (window.ethereum.chainId === '0x38') {
                    await window.ethereum.enable();
                    await web3.eth.getAccounts().then((accounts) => {
                        setMetamaskStatus('CONNECTED');
                        setWalletObject('MM', null);
                        setAddress(accounts[0]);
                        updateBalance(accounts[0]);
                    });
                } else {
                    DisplayErrorMsg('Please connect to BSC Smart chain network');
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            DisplayErrorMsg('Metamask extensions not detected!');
        }
    };
    useEffect(() => {
        window.Moralis.initialize(MORALIS_FIAT_PROD_KEY);
        window.Moralis.serverURL = MORALIS_SERVER_URL;
        (async function () {
            window.Moralis.initPlugins();
        })();
    }, []);
    useEffect(() => {
        if (metamaskConnected && address == null) {
            getUserAccount();
        }
    }, [metamaskConnected, address]);

    // delete when done
    useEffect(() => {
        if (window.ethereum) {
            window.ethereum.on('accountsChanged', (accounts) => {
                if (accounts && web3) {
                    setAddress(accounts[0]);
                    updateBalance(accounts[0]);
                }
            });
        }
    }, [web3]);

    useEffect(() => {
        getPokedxPriceInUSD();
    }, []);

    useEffect(() => {
        if (basetokenprice) {
            document.title = `PokeDX Real-Time Charts & Trading - $${basetokenprice.toFixed(3)}`;
        }
    }, [basetokenprice]);

    // delete when done
    const updateBalance = async (fromAddress) => {
        let finalBalance = 0;
        try {
            const balance = await web3.eth
                .getBalance(fromAddress)
                .then((value) => parseFloat(web3.utils.fromWei(value, 'ether')).toFixed(4));
            const balancesOfTokens = await getAllAccountTokens(fromAddress, balance);
            if (balancesOfTokens.length) {
                const bitRes = balancesOfTokens.map((obj) => {
                    const priceObj = getTokenUsdPrice(obj);
                    obj.tokenaddress = obj.token_address;
                    obj.quantity = priceObj.quantity;
                    finalBalance += priceObj.price;
                    obj.tokenprice = priceObj.price;
                    delete obj.token_address;
                    delete obj.balance;
                    return obj;
                });
                setAllTokens(bitRes);
                setBalance(finalBalance.toFixed(2));
            } else {
                setBalance(0);
            }
        } catch (error) {
            console.error('Error getwallet balance', error.message);
        }
    };

    const getTokenUsdPrice = (obj) => {
        const quantity = obj.balance / 10 ** obj.decimals;
        const price = quantity * obj.usdPrice;
        return {
            quantity,
            price
        };
    };

    const getAllAccountTokens = async (address, balance) => {
        const serverUrl = MORALIS_SERVER_URL;
        const appId = MORALIS_APP_ID;
        Moralis.start({ serverUrl, appId });
        let bnb = null;
        if (balance) {
            bnb = {
                symbol: 'BNB',
                name: 'BNB',
                balance: balance * 10 ** 18,
                decimals: '18',
                token_address: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c'
            };
        }
        const params = { address, bnb };
        const results = await Moralis.Cloud.run('getWalletTokens', params);
        let whiteListAddress = [];
        if (results != undefined) {
            const blacklistTokens = getBlackListedTokens();
            whiteListAddress = results?.filter((elem) => !blacklistTokens.includes(elem.token_address));
            return whiteListAddress;
        }
        whiteListAddress.push(bnb);
        return whiteListAddress;
    };

    const restoreHidden = () => {
        localStorage.removeItem('hideTokens');
        updateBalance(address);
    };

    // wallet
    const hideTheToken = (tokenAddr) => {
        const latestTokens = allTokens;
        const tokens = addHideToken(tokenAddr);
        const removedToken = latestTokens.splice(
            latestTokens.findIndex((i) => i.tokenaddress == tokenAddr),
            1
        );
        const newBalance = +balance - +removedToken[0].tokenprice;
        setBalance(newBalance.toFixed(2));
    };

    const renderBody = () => {
        if (allTokens && allTokens.length > 0) {
            const sortedTokens = allTokens.sort((a, b) => (a.tokenprice > b.tokenprice ? -1 : a.tokenprice < b.tokenprice ? 1 : 0));
            const filteredTokens = sortedTokens.filter((ele) => ele.tokenprice >= 1);
            return filteredTokens.map((token, index) => {
                const path = `/token/${token.tokenaddress}/${token.symbol}`;
                const imageUrl = token.tokenaddress ? `${CDN_URL}/${token.tokenaddress.toUpperCase()}.png` : `${CDN_URL}/placeholder.svg`;
                return (
                    <tr key={index}>
                        <td className="w-tokenname">
                            <Link to={path} style={{ textDecoration: 'none' }}>
                                <span className="w-text">
                                    {' '}
                                    <img
                                        src={imageUrl}
                                        // onError={(e) => {
                                        //     e.target.src = `${CDN_URL}/placeholder.svg`;
                                        //     e.target.onError = null;
                                        // }}
                                        className="lp-dropdown-listitem-icon"
                                        alt="logo"
                                    />
                                    {token.symbol}
                                </span>
                            </Link>
                        </td>
                        {/* <td className="w-tokenval text-left">
                            <span className="w-text">{parseFloat(token.quantity).toFixed(2)}</span>
                        </td> */}
                        <td className="w-tokenval text-left">
                            <span style={{ color: 'var(--text-green)' }} className="w-text">
                                ${parseFloat(token.tokenprice).toFixed(2)}
                            </span>
                        </td>
                        <td className="w-tokenval logo">
                            <BiHide
                                size={14}
                                onClick={() => hideTheToken(token.tokenaddress)}
                                style={{ marginTop: '-7px', marginLeft: '-12px', cursor: 'pointer' }}
                            />
                        </td>
                    </tr>
                );
            });
        }
        return null;
    };

    return (
        <div
            className="w-widget"
            style={{
                height: '100%',
                minHeight: '260px',
                maxHeight: '260px',
                overflowY: 'auto',
                margin: '4px',
                borderRadius: '7px'
            }}
        >
            {address ? (
                <Col className="m-0 p-0" style={{ margin: '4px' }}>
                    <Row
                        className="m-0 p-0"
                        style={{
                            backgroundColor: 'var(--background-dark)',
                            borderBottom: '1px solid var(--color-border-grey)'
                        }}
                    >
                        <Col className="px-2 py-2">
                            <div style={{ fontSize: '16px', fontWeight: '100', color: 'var(--text-green)', marginLeft: '3px' }}>
                                ${numberWithCommas(balance)}
                            </div>
                        </Col>
                        <Col className="px-2 py-2 d-flex justify-content-end">
                            <div style={{ fontSize: '13px', color: 'var(--text-muted)', marginTop: '2px', marginRight: '4px' }}>
                                {/* <span style={{ marginTop: '10px' }}>View portfolio</span> */}
                                {showRestoreBtn() ? (
                                    <OverlayTrigger placement="left" overlay={<Tooltip>Restore hidden</Tooltip>}>
                                        {({ ref, ...triggerHandler }) => (
                                            <div
                                                ref={ref}
                                                onClick={() => restoreHidden()}
                                                {...triggerHandler}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <FaTrashRestoreAlt style={{ marginTop: '-8px', marginLeft: '12px' }} size={12} />
                                            </div>
                                        )}
                                    </OverlayTrigger>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                    <table className="table table-dark table-hover">
                        <thead className="w-head">
                            <td style={{ color: 'var(--text-muted)', fontWeight: '400' }}>Symbol</td>
                            {/* <td style={{ color: 'var(--text-muted)', fontWeight: '400' }}>Balance</td> */}
                            <td style={{ color: 'var(--text-muted)', fontWeight: '400' }}>Value</td>
                            <td className="text-right" style={{ color: 'var(--text-muted)', fontWeight: '400' }}>
                                More
                            </td>
                        </thead>
                        <tbody>
                            {allTokens ? null : <div className="spinner" />}
                            {renderBody()}
                        </tbody>
                    </table>
                </Col>
            ) : (
                <div className="w-connect" style={{ backgroundColor: 'var(--background-base)', height: '230px' }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'inherit',
                            width: '250px'
                        }}
                    >
                        <span
                            className="d-flex text-center mb-1"
                            style={{ color: 'var(--text-muted)', fontSize: '18px', fontWeight: '100' }}
                        >
                            Connect your wallet to see your wallet balances
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
}

export default WalletWidget;
