import React, { useEffect, useMemo, useState } from 'react';
import { RiCopperCoinFill, RiFundsFill } from 'react-icons/ri';
import { FaInfoCircle } from 'react-icons/fa';
import { FiArrowDownRight, FiArrowUpRight } from 'react-icons/fi';
import { HiChartBar } from 'react-icons/hi';
import { Skeleton } from '@mui/material';
import { Dropdown, Container, Row, Col, Accordion, Button, OverlayTrigger, Tooltip, Spinner, Card } from 'react-bootstrap';
import { BsLayoutSidebarReverse } from 'react-icons/bs';
import useSWR from 'swr';
import IMG_LOGO2 from '../../assets/img/PDX.png';
import { useStoreApi } from '../../store/storeApi';
import { DATA_SERVICE_URL, CDN_URL, PROXY_URL } from '../../shared/ApiURL';
import {
    numberWithCommas,
    numFormatter,
    liquityNumber,
    getBnbToUSD,
    setActivePairInDB,
    getWatchList,
    getTokenPrice,
    toFixedDecimal,
    getQuoteToken,
    setSettingsDataToDB
} from '../../shared/commonUtils';
import VolumeModal from '../VolumeModal';
import TokenDetails from './TokenDetails';
import { CLIENT_ID } from '../../shared/network';
import { DEX_ICONS, NULL_LP } from '../../shared/constants';
import '../../Styles/modal-popup.scss';
import TokenProfile from './TokenProfile';
import SettingModal from './SettingsModal';
import TokenLinks from './TokenLinks';
import ProjectDescription from '../ProjectDescription';
import TokenStats from './TokenStats';
import ContractCheckup from './ContractCheckup';

const Title = ({ title }) => (
    <h6 className="mt-3 pl-0 mb-1" style={{ color: 'var(--text-primary)' }}>
        {title}
    </h6>
);

const fetcher = (url) => fetch(url).then((r) => r.json());

const currentDateTime = new Date();
let weekAgo = currentDateTime?.setHours(currentDateTime.getHours() - 168);
weekAgo = new Date(weekAgo);
const fromTime = weekAgo.toISOString();

const DayChange = () => {
    const { activePair, tokenprice, bnbToUSDPrice, cryptoResponse } = useStoreApi();
    const url = `${DATA_SERVICE_URL}/price-change?fromWeek=${fromTime}&baseCurrency=${activePair.token0}&quoteCurrency=${activePair.token1}`;
    const { data: res, error } = useSWR(url, fetcher, { revalidateOnFocus: false });
    if (error) return 'N/A';
    if (!res)
        return (
            <Skeleton
                variant="rectangular"
                width={65}
                height={14}
                className="mt-0 mb-1 p-0"
                style={{ marginRight: '-6px' }}
                sx={{ backgroundColor: 'var(--background-lighter)', borderRadius: '4px', marginBottom: '5px', marginLeft: '-6px' }}
            />
        );
        
    const changeInPct = (res?.daychange && res?.inusd) ? ((
        ((tokenprice - res?.daychange[1]?.quotePrice * res?.inusd[1]?.quotePrice) /
            ((tokenprice + res?.daychange[1]?.quotePrice * res?.inusd[1]?.quotePrice) / 2)) *
        100
    ).toFixed(2)) : 0;

    const getArrowBody = (value) => {
        if (res) {
            if (Math.sign(changeInPct) == 1) {
                return <FiArrowUpRight size={14} style={{ marginTop: '-9px', marginRight: '5px' }} />;
            }
            return <FiArrowDownRight size={14} style={{ marginTop: '-9px', marginRight: '5px' }} />;
        }
        return '';
    };

    const getHistorySpanClass = (value) => {
        if (res) {
            return Math.sign(changeInPct) == 1 ? 'cbuy' : 'csell';
        }
        return '';
    };

    return (
        <div style={{ marginRight: '-5px' }} className={`${getHistorySpanClass('percent_change_24h')} mb-0 mt-0`}>
            {getArrowBody('percent_change_24h')}
            {changeInPct}%
        </div>
    );
};

export default function Crypto(props) {
    const {
        address,
        bnbToUSDPrice,
        cryptoResponse,
        marketcap,
        tokenAddress,
        activePair,
        listPairs,
        token,
        allTokens,
        csupply,
        tokenprice,
        setActivePair,
        setListPairs,
        setMarketCap,
        setTokenPrice,
        setBnBPrice
    } = useStoreApi();
    // const [tokenResponse, setTokenResponse] = useState([]);
    const [settingsShow, setSettingsShow] = React.useState(false);
    const [userInput, setUserInput] = useState('');
    const [showSearch, setShowSearch] = useState(false);
    const [isAdded, setIsAdded] = useState(false);
    const [balance, setBalance] = useState(0);
    const onPairSelected = (value) => {
        setActivePairInDB(value);
        setActivePair(value);
    };

    useEffect(() => {
        async function subscribe() {
            const data = {
                token0: activePair.token0,
                token1: activePair.token1,
                dex: activePair.dex,
                clientId: CLIENT_ID,
                pairs: listPairs.map((pair) => ({
                    address: pair.pair.address,
                    token0: pair.token0,
                    token1: pair.token1,
                    dex: pair.dex
                }))
            };
            if (!Object.values(data).every((x) => x)) return;
        }

        subscribe();
    }, [activePair]);

    // useEffect(() => {
    //   setTimeout(enableDisableTvChart(), 1000);
    // }, []);

    useEffect(async () => {
        if (address) {
            const bal = await tokenBalance();
            setBalance(bal);
        }
    }, [tokenAddress, address]);
    useEffect(() => {
        async function getTopPairs() {
            try {
                if (tokenAddress) {
                    const res = await fetch(`${DATA_SERVICE_URL}/topPairs?token0=${tokenAddress}`);
                    const data = await res.json();
                    if (data && data.length > 0) {
                        for (let i = 0; i < data.length; i += 1) {
                            data[i].icon = DEX_ICONS[data[i].dex];
                            const quoteToken = getQuoteToken(data[i], tokenAddress);
                            data[i].token0 = tokenAddress.toLowerCase();
                            data[i].token1 = quoteToken.toLowerCase();
                            data[i].symbol0 = data[i][[data[i].token0]].symbol;
                            data[i].symbol1 = data[i][[data[i].token1]].symbol;
                        }
                        setListPairs(data);
                        setActivePairInDB(data[0]);
                        setActivePair(data[0]);
                    } else {
                        setListPairs([]);
                        setActivePairInDB(NULL_LP);
                        setActivePair(NULL_LP);
                    }
                }
            } catch (error) {
                setListPairs([]);
                setActivePairInDB(NULL_LP);
                setActivePair(NULL_LP);
                console.error('error', error);
            }
        }
        getTopPairs();
    }, [tokenAddress]);

    useEffect(() => {
        const watchList = getWatchList();
        const alreadyAdded = watchList.filter((obj) => obj.address === tokenAddress);
        if (alreadyAdded.length) {
            setIsAdded(true);
        } else {
            setIsAdded(false);
        }
    }, [address]); // 🔴TODO: replace this with the wagmi address object

    const tokenBalance = () => {
        // console.log('allTokens==>', allTokens, token);
        if (allTokens !== null) {
            const selectedToken = allTokens.filter((ele) => ele.symbol === token);
            return selectedToken.length > 0 ? parseFloat(selectedToken[0].tokenprice).toFixed(2) : 0;
        }
        return 0;
    };

    const getMarketCap = async () => {
        fetch(`${DATA_SERVICE_URL}/marketcap?token=${tokenAddress}`)
            .then((res) => res.json())
            .then((data) => {
                setMarketCap({
                    csupply: data.supply,
                    marketcap: data.marketcap
                });
            })
            .catch((err) => {
                console.error(err);
            });
    };

    // const getFees = async () => {
    //     fetch(`${DATA_SERVICE_URL}/fees?token0=${activePair.token0}&token1=${activePair.token1}&dex=${activePair.dex}`)
    //         .then((res) => res.json())
    //         .then((data) => {
    //             setBuyFee(data.buyFee);
    //             setSellFee(data.sellFee);
    //             setFeesInDB({ buyFee: data.buyFee, sellFee: data.sellFee });
    //         })
    //         .catch((err) => {
    //             console.error(err);
    //         });
    // };

    const fetchPairData = async () => {
        const BNBUSD = await getBnbToUSD();
        setBnBPrice(BNBUSD?.toFixed(3));
        // connect to BSC node
        // get pair contract
        const tokenPriceInUsd = await getTokenPrice(true, tokenAddress);
        setTokenPrice(tokenPriceInUsd);
        await getMarketCap();
    };

    const [volume, setVolume] = useState(null);
    const get24Volume = async () => {
        try {
            const currentDateTime = new Date();
            let yesterday = await currentDateTime?.setHours(currentDateTime.getHours() - 24);
            yesterday = new Date(yesterday);
            const fromTime = yesterday.toISOString();
            await activePair;
            const result = await fetch(
                `${DATA_SERVICE_URL}/daily-volume?baseCurrency=${activePair.token0}&quoteCurrency=${activePair.token1}&fromTime=${fromTime}`
            );
            const data = await result.json();
            if (data && data.length === 2) {
                const dayVolume = data[0].totalVolume + data[1].totalVolume;
                setVolume(dayVolume);
                return;
            }
            if (data && data.length === 1) {
                const dayVolume = data[0].totalVolume;
                setVolume(dayVolume);
                return;
            }
            if (data && volume === 0) {
                setVolume(0);
            }
            console.log(data);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        get24Volume();
    }, [activePair]);

    const [showVolumeHistory, setShowVolumeHistory] = React.useState(false);

    // delete when done
    const getHistorySpanClass = (value) => {
        if (cryptoResponse) {
            return Math.sign(cryptoResponse.quote.USD[value]) == 1 ? 'cbuy' : 'csell';
        }
        return '';
    };

    const [showInfo, setShowInfo] = useState(false);

    const loadLpList = () => (
        <ul className="dropdown-menu dropdown-menu-dark lp-dropdown-ul">
            {listPairs.map((pair) => (
                <Dropdown.Item key={pair.pair.address} onClick={() => onPairSelected(pair)}>
                    <img src={pair.icon} className="lp-dropdown-listitem-icon" alt="logo" />
                    <span className="lp-dropdown-listitem-text">
                        {pair.symbol0} / {pair.symbol1}
                    </span>
                </Dropdown.Item>
            ))}
        </ul>
    );

    // delete when done
    const getArrowBody = (value) => {
        if (cryptoResponse) {
            if (Math.sign(cryptoResponse.quote.USD[value]) == 1) {
                return <FiArrowUpRight size={14} style={{ marginTop: '-9px', marginRight: '5px' }} />;
            }
            return <FiArrowDownRight size={14} style={{ marginTop: '-9px', marginRight: '5px' }} />;
        }
        return '';
    };

    useEffect(() => {
        if (activePair.dex === 'nullswap') return;
        fetchPairData().then(() => {});
    }, [activePair, cryptoResponse]);

    // change settings method
    const [settings, setSettings] = useState(null);
    const [priceChange24, setPriceChange24] = useState(true);
    const [tradingVolumeTab, setTradingVolumeTab] = useState(true);
    const [liquidityTab, setLiquidityTab] = useState(true);
    const [marketCapTab, setMarketCapTab] = useState(true);
    const [totalSupplyTab, setTotalSupplyTab] = useState(true);
    const [bnbPriceTab, setBnbPriceTab] = useState(true);
    const [pdxPerBnbTab, setPdxPerBnbTab] = useState(true);
    const [buyFeeTab, setBuyFeeTab] = useState(true);
    const [sellFeeTab, setSellFeeTab] = useState(true);
    const [balanceTab, setBalanceTab] = useState(true);

    const changeSettings = (type, layoutType) => {
        switch (type) {
            case 'priceChange24':
                setPriceChange24(!priceChange24);
                setSettings({ ...props.settings, priceChange24: !priceChange24 });
                setSettingsDataToDB({ ...props.settings, priceChange24: !priceChange24 });
                break;
            case 'tradingVolumeTab':
                setTradingVolumeTab(!tradingVolumeTab);
                setSettingsDataToDB({ ...props.settings, tradingVolumeTab: !tradingVolumeTab });
                break;
            case 'liquidityTab':
                setLiquidityTab(!liquidityTab);
                setSettingsDataToDB({ ...props.settings, liquidityTab: !liquidityTab });
                break;
            case 'marketCapTab':
                setMarketCapTab(!marketCapTab);
                setSettingsDataToDB({ ...props.settings, marketCapTab: !marketCapTab });
                break;
            case 'totalSupplyTab':
                setTotalSupplyTab(!totalSupplyTab);
                setSettingsDataToDB({ ...props.settings, totalSupplyTab: !totalSupplyTab });
                break;
            case 'bnbPriceTab':
                setBnbPriceTab(!bnbPriceTab);
                setSettingsDataToDB({ ...props.settings, bnbPriceTab: !bnbPriceTab });
                break;
            case 'pdxPerBnbTab':
                setPdxPerBnbTab(!pdxPerBnbTab);
                setSettingsDataToDB({ ...props.settings, pdxPerBnbTab: !pdxPerBnbTab });
                break;
            case 'buyFeeTab':
                setBuyFeeTab(!buyFeeTab);
                setSettingsDataToDB({ ...props.settings, buyFeeTab: !buyFeeTab });
                break;
            case 'sellFeeTab':
                setSellFeeTab(!sellFeeTab);
                setSettingsDataToDB({ ...props.settings, sellFeeTab: !sellFeeTab });
                break;
            case 'balanceTab':
                setBalanceTab(!balanceTab);
                setSettingsDataToDB({ ...props.settings, balanceTab: !balanceTab });
                break;
            case 'layout':
                setSettingsDataToDB({ ...props.settings, layout: layoutType });
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (props.settings) {
            setSettings(props.settings);
        }
    }, [props]);

    const [isFee, setIsFee] = useState(null);
    useEffect(() => {
        const honeyPotCheck = async () => {
            fetch(`${PROXY_URL}/honeypot/rugcheck?type=pancakeswap&address2=default&tokenAddress=${tokenAddress}`)
                .then((res) => res.json())
                .then((data) => {
                    if (data.data && tokenAddress) {
                        setIsFee(data.data);
                        // console.log(data.data);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        };
        honeyPotCheck();
    }, [tokenAddress]);

    // this is a mess - to do: deal with this shit
    const [changeVolume, setChangeVolume] = useState(null);

    return (
        <div className="mb-2 pt-0 tokenrow" style={{ borderTop: '0px solid #2d2d3c', marginLeft: '0', marginRight: '0', zIndex: '9' }}>
            <Container className="pr-2 pl-2 hideOnDesktop">
                <Row
                    style={{
                        marginTop: '-3px',
                        borderBottom: '1px solid rgb(45, 45, 60)',
                        borderTop: '1px solid rgb(45, 45, 60)'
                    }}
                    className="mb-2"
                >
                    <Col md="auto" xs={6} className="d-flex align-items-center">
                        <div className="p-0" style={{ borderRight: '1px solid rgb(45, 45, 60)', marginRight: '10px' }}>
                            <img
                                src={tokenAddress ? `${CDN_URL}/${tokenAddress.toUpperCase()}.png` : IMG_LOGO2}
                                // onError={(e) => {
                                //     e.target.src = `${CDN_URL}/placeholder.svg`;
                                //     e.target.onError = null;
                                // }}
                                height="42"
                                width="42"
                                alt="logo"
                                className="mr-3"
                            />
                        </div>
                        <Col>
                            <Dropdown className="pairdropdown">
                                <Dropdown.Toggle
                                    id="pairdropdowntoggle"
                                    variant="secondary"
                                    className="d-flex align-items-left"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <div className="cbuy" id="tokenmain">
                                        <span
                                            className="d-flex justify-content-start"
                                            style={{
                                                fontFamily: 'Ligurino',
                                                marginLeft: '-18px',
                                                // marginTop: '-4px',

                                                fontSize: '17px',
                                                fontWeight: '500',
                                                color: 'var(--text-base)'
                                            }}
                                        >
                                            {activePair.symbol0}-{activePair.symbol1}
                                            <br />
                                        </span>
                                        <span className="cryptoname-mobile">{activePair.dex}</span> <br />
                                    </div>
                                </Dropdown.Toggle>
                                {loadLpList()}
                            </Dropdown>
                        </Col>
                    </Col>
                    <Col className="d-flex flex-column" style={{ alignItems: 'flex-end', marginRight: '10px', justifyContent: 'center' }}>
                        <Row>
                            <OverlayTrigger key="copyprice" placement="top" overlay={<Tooltip id="tooltop-right">Copied!</Tooltip>}>
                                <Button
                                    variant=""
                                    style={{
                                        fontSize: '16px',
                                        fontFamily: 'monotalic',
                                        fontWeight: '400',
                                        marginTop: '4px',
                                        color: 'var(--text-base)',
                                        marginBottom: '-5px',
                                        marginRight: '5px'
                                    }}
                                    className="d-flex p-0"
                                    onClick={() => navigator.clipboard.writeText(tokenprice.toFixed(8))}
                                >
                                    {tokenprice ? (
                                        `$${(+tokenprice).toFixed(toFixedDecimal(tokenprice))}`
                                    ) : (
                                        <Skeleton
                                            variant="rectangular"
                                            width={80}
                                            height={18}
                                            className="mt-0 p-0"
                                            sx={{ backgroundColor: 'var(--background-lighter)', borderRadius: '5px', marginBottom: '5px' }}
                                        />
                                    )}
                                </Button>
                            </OverlayTrigger>
                        </Row>

                        <Row className="">
                            <span className={`${getHistorySpanClass('percent_change_24h')} mb-1 mt-1`}>
                                {getArrowBody('percent_change_24h')}

                                <DayChange />
                            </span>
                        </Row>
                    </Col>
                </Row>
                <Row style={{ overflowX: 'hidden' }}>
                    <Accordion className="tokendetails-mobile pl-0 pr-0 m-0 mb-2" flush>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header style={{ border: 'none', backgroundColor: 'var(--background-base)' }}>
                                <RiFundsFill size={17} style={{ marginTop: '-7px', marginRight: '5px', color: 'var(--text-muted)' }} />{' '}
                                <span style={{ color: 'var(--text-base)' }}>FDMCap</span>
                                <span className="cbuy">&nbsp;${numberWithCommas(Math.round(marketcap).toFixed(2))}</span>
                                &nbsp;&nbsp;&nbsp;{' '}
                                <RiCopperCoinFill size={17} style={{ marginTop: '-7px', marginRight: '5px', color: 'var(--text-muted)' }} />
                                <span style={{ color: 'var(--text-base)' }}>Liquidity</span>
                                <span className="cbuy">&nbsp;${liquityNumber(Math.round(activePair.totalLiquidity))}</span>
                            </Accordion.Header>
                            <Accordion.Body
                                style={{
                                    backgroundColor: 'var(--background-dark)',
                                    borderTop: '1px solid var(--color-border-grey)'
                                }}
                                className="ml-0 mr-0 mt-2 mobile-crypto-section-wrapper"
                            >
                                <TokenProfile />

                                <div className="mt-2">
                                    <Container className="metrics-mobile-wrapper" fluid>
                                        <Card className="metrics-card">
                                            <Card.Body className="mb-0 pb-2 metrics-body">
                                                <Card.Title className="metrics-title">
                                                    <img
                                                        src="https://www.svgrepo.com/show/255156/money.svg"
                                                        width="18px"
                                                        style={{ marginRight: '4px', opacity: '70%', marginTop: '-3px' }}
                                                    />{' '}
                                                    {/* {activePair.symbol0}/{activePair.symbol1} */} Pair metrics
                                                </Card.Title>
                                                <TokenDetails />
                                            </Card.Body>
                                        </Card>
                                        <Card className="metrics-card">
                                            <Card.Body className="mb-0 pb-1 metrics-body">
                                                <Card.Title className="metrics-title">
                                                    <img
                                                        src="https://www.svgrepo.com/show/255104/stats-graph.svg"
                                                        width="18"
                                                        style={{ marginRight: '4px', opacity: '70%', marginTop: '-3px' }}
                                                    />{' '}
                                                    Token statistics
                                                </Card.Title>
                                                <TokenStats />
                                            </Card.Body>
                                        </Card>
                                        <Card className="metrics-card">
                                            <Card.Body className="mb-0 pb-1 metrics-body">
                                                <Card.Title className="metrics-title">
                                                    <img
                                                        src="https://www.svgrepo.com/show/255099/binoculars.svg"
                                                        width="18px"
                                                        style={{ marginRight: '4px', opacity: '70%', marginTop: '-3px' }}
                                                    />{' '}
                                                    Rugcheck
                                                </Card.Title>
                                                <ContractCheckup />
                                            </Card.Body>
                                        </Card>
                                    </Container>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Row>
            </Container>
            <Container className="mb-0 pl-0 dexwidth hideOnMobile">
                <div style={{ right: '0', position: 'absolute', marginTop: '10px', marginRight: '10px' }}>
                    <button
                        onClick={() => setSettingsShow(true)}
                        style={{
                            width: 'auto',
                            color: 'var(--text-base)',
                            height: '25px',
                            border: 'none',
                            backgroundColor: 'var(--background-light)',
                            borderRadius: '8px',
                            fontSize: '12px',
                            lineHeight: 1,
                            marginTop: '1px'
                        }}
                    >
                        <BsLayoutSidebarReverse
                            size={17}
                            style={{
                                marginTop: '-9px'
                            }}
                        />
                    </button>
                    <SettingModal
                        changeSettings={changeSettings}
                        settings={props.settings}
                        show={settingsShow}
                        onHide={() => setSettingsShow(false)}
                    />
                </div>
                <Row className="d-flex keydatarow" style={{ borderTop: '1px solid #2d2d3c' }}>
                    <Col
                        md="auto"
                        xs={6}
                        className="d-inline-flex align-items-center infodivider"
                        style={{ marginTop: '0px', marginBottom: '0px', paddingRight: '40px', marginRight: '-12px' }}
                    >
                        <div className="p-0 ml-3">
                            <img
                                src={tokenAddress ? `${CDN_URL}/${tokenAddress.toUpperCase()}.png` : IMG_LOGO2}
                                // onError={(e) => {
                                //     e.target.src = `${CDN_URL}/placeholder.svg`;
                                //     e.target.onError = null;
                                // }}
                                height="38"
                                width="38"
                                alt="logo"
                            />
                        </div>
                        <Col>
                            <Dropdown className="pairdropdown">
                                <Dropdown.Toggle
                                    id="pairdropdowntoggle"
                                    variant="secondary"
                                    className="d-flex align-items-center ml-2"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <div className="cbuy" id="tokenmain">
                                        <span className="cryptoname align-items-left">
                                            {/* <img src={activePair.icon} height="18" width="18" alt="logo" className="mr-1 mb-1 ml-2" /> */}
                                            {activePair.symbol0}-{activePair.symbol1}
                                        </span>{' '}
                                        {/* <br /> */}
                                    </div>
                                </Dropdown.Toggle>
                                {loadLpList()}
                            </Dropdown>
                            <OverlayTrigger
                                key="copyprice"
                                placement="right"
                                overlay={<Tooltip id="tooltop-right">Copy to clipboard</Tooltip>}
                            >
                                <Button
                                    variant="pricebutton"
                                    className="cbuy d-flex p-0 tprice"
                                    onClick={() => navigator.clipboard.writeText(tokenprice)}
                                >
                                    {tokenprice ? (
                                        `$${(+tokenprice).toFixed(toFixedDecimal(tokenprice))}`
                                    ) : (
                                        <Skeleton
                                            variant="rectangular"
                                            width={100}
                                            height={18}
                                            className="mt-0 m-0 p-0"
                                            sx={{ backgroundColor: 'var(--background-lighter)', borderRadius: '5px' }}
                                        />
                                    )}
                                </Button>
                            </OverlayTrigger>
                        </Col>
                    </Col>
                    <Col md="auto" xs={6} className="d-inline-flex" style={{ marginTop: '0px', marginBottom: '0px' }}>
                        <div className="keydatawrapper" style={{ marginTop: '8.5px', marginBottom: '7px' }}>
                            <div className="ml-0 ml-small scrolling-wrapper-keydata">
                                {priceChange24 ? (
                                    <div className="d-flex keydatacard inline-block change-24">
                                        <div className="">
                                            <span className="cardname" style={{ position: 'relative', bottom: 0 }}>
                                                24h change
                                            </span>
                                            <br />
                                            <span className="cardvalue">
                                                <span className={getHistorySpanClass('percent_change_24h')}>
                                                    <DayChange />
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                ) : null}
                                {/* {get24Volume() != 0 ? ( */}
                                {tradingVolumeTab ? (
                                    <div className="d-flex keydatacard inline-block">
                                        <div className="">
                                            <Button className="mr-1 p-0" variant="cryptoExpand" onClick={() => setShowVolumeHistory(true)}>
                                                <HiChartBar className="expandIcon" size={10} style={{ marginTop: '-18px' }} />
                                            </Button>
                                            <VolumeModal show={showVolumeHistory} onHide={() => setShowVolumeHistory(false)} />
                                            <span className="cardname" style={{ position: 'relative', bottom: 0 }}>
                                                24h volume
                                            </span>
                                            <br />
                                            <span className="cardvalue">
                                                {volume !== null ? (
                                                    `$${volume.toFixed(3)}`
                                                ) : (
                                                    <Skeleton
                                                        className="mt-1"
                                                        variant="rectangular"
                                                        width={70}
                                                        height={18}
                                                        sx={{
                                                            backgroundColor: 'var(--background-lighter)',
                                                            borderRadius: '5px'
                                                        }}
                                                    />
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                ) : null}
                                {liquidityTab ? (
                                    <div className="d-flex keydatacard inline-block">
                                        <div className="">
                                            <span className="cardname" style={{ position: 'relative', bottom: 0 }}>
                                                Pool value
                                            </span>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="button-tooltip-2">
                                                        Pool value includes both {activePair.symbol0} and {activePair.symbol1} from LP total
                                                    </Tooltip>
                                                }
                                            >
                                                {({ ref, ...triggerHandler }) => (
                                                    <div className="d-inline-flex align-items-center" {...triggerHandler}>
                                                        {/* <img src={FaInfoCircle} ref={ref} /> */}
                                                        <span ref={ref}>
                                                            <FaInfoCircle
                                                                size={11}
                                                                color="#5E667D"
                                                                style={{ marginTop: '2px' }}
                                                                className="mb-1 ml-1"
                                                            />
                                                        </span>
                                                    </div>
                                                )}
                                            </OverlayTrigger>
                                            <br />
                                            <span className="cardvalue">
                                                {liquityNumber(Math.round(activePair.totalLiquidity)) ? (
                                                    `$${liquityNumber(Math.round(activePair.totalLiquidity))}`
                                                ) : (
                                                    <Skeleton
                                                        className="mt-1"
                                                        variant="rectangular"
                                                        width={70}
                                                        height={18}
                                                        sx={{
                                                            backgroundColor: 'var(--background-lighter)',
                                                            borderRadius: '5px'
                                                        }}
                                                    />
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                ) : null}
                                {/* ) : null} */}
                                {/* {Math.round(marketcap) != 0 ? ( */}
                                {marketCapTab ? (
                                    <div className="d-flex keydatacard inline-block">
                                        <div className="">
                                            <span className="cardname" style={{ position: 'relative', bottom: 0 }}>
                                                Marketcap
                                            </span>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="button-tooltip-2">
                                                        Market cap = Current Price * Circulating Supply{' '}
                                                    </Tooltip>
                                                }
                                            >
                                                {({ ref, ...triggerHandler }) => (
                                                    <div className="d-inline-flex align-items-center" {...triggerHandler}>
                                                        {/* <img src={FaInfoCircle} ref={ref} /> */}
                                                        <span ref={ref}>
                                                            <FaInfoCircle size={9} color="#5E667D" className="mb-1 ml-1" />
                                                        </span>
                                                    </div>
                                                )}
                                            </OverlayTrigger>
                                            <br />
                                            <span className="cardvalue">
                                                {numberWithCommas(Math.round(marketcap)) ? (
                                                    `$${numberWithCommas(Math.round(marketcap))}`
                                                ) : (
                                                    <Skeleton
                                                        className="mt-1"
                                                        variant="rectangular"
                                                        width={80}
                                                        height={18}
                                                        sx={{
                                                            backgroundColor: 'var(--background-lighter)',
                                                            borderRadius: '5px'
                                                        }}
                                                    />
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                ) : null}
                                {totalSupplyTab ? (
                                    <div className="d-flex keydatacard inline-block">
                                        <div className="">
                                            <span className="cardname" style={{ position: 'relative', bottom: 0 }}>
                                                Total supply
                                            </span>
                                            <br />
                                            <span className="cardvalue">
                                                {numFormatter(csupply) ? (
                                                    `${numFormatter(csupply)}`
                                                ) : (
                                                    <Skeleton
                                                        className="mt-1"
                                                        variant="rectangular"
                                                        width={80}
                                                        height={18}
                                                        sx={{
                                                            backgroundColor: 'var(--background-lighter)',
                                                            borderRadius: '5px'
                                                        }}
                                                    />
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                ) : null}
                                {bnbPriceTab ? (
                                    <div className="d-flex keydatacard inline-block">
                                        <div className="">
                                            <span className="cardname" style={{ position: 'relative', bottom: 0 }}>
                                                BNB price
                                            </span>
                                            <br />
                                            <span className="cardvalue">
                                                {bnbToUSDPrice ? (
                                                    `$${bnbToUSDPrice}`
                                                ) : (
                                                    <Skeleton
                                                        className="mt-1"
                                                        variant="rectangular"
                                                        width={55}
                                                        height={18}
                                                        sx={{
                                                            backgroundColor: 'var(--background-lighter)',
                                                            borderRadius: '5px'
                                                        }}
                                                    />
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                ) : null}
                                {buyFeeTab ? (
                                    <div className="d-flex keydatacard inline-block">
                                        <div className="">
                                            <span className="cardname" style={{ position: 'relative', bottom: 0 }}>
                                                Buy Fee
                                            </span>
                                            <br />
                                            <span className="cardvalue">
                                                {isFee ? (
                                                    `${parseFloat(isFee.buyFee).toFixed(2)}%`
                                                ) : (
                                                    <Skeleton
                                                        className="mt-1"
                                                        variant="rectangular"
                                                        width={55}
                                                        height={18}
                                                        sx={{
                                                            backgroundColor: 'var(--background-lighter)',
                                                            borderRadius: '5px'
                                                        }}
                                                    />
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                ) : null}
                                {sellFeeTab ? (
                                    <div className="d-flex keydatacard inline-block">
                                        <div className="">
                                            <span className="cardname" style={{ position: 'relative', bottom: 0 }}>
                                                Sell Fee
                                            </span>
                                            <br />
                                            <span className="cardvalue">
                                                {isFee ? (
                                                    `${parseFloat(isFee.sellFee).toFixed(2)}%`
                                                ) : (
                                                    <Skeleton
                                                        className="mt-1"
                                                        variant="rectangular"
                                                        width={55}
                                                        height={18}
                                                        sx={{
                                                            backgroundColor: 'var(--background-lighter)',
                                                            borderRadius: '5px'
                                                        }}
                                                    />
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                ) : null}
                                {balanceTab && balance != 0 ? (
                                    <div className="d-flex keydatacard inline-block">
                                        <div className="">
                                            <span className="cardname">Balance</span>
                                            <FaInfoCircle size={10} className="mb-1 ml-1" color="var(--background-light)" />
                                            <br />
                                            <span className="cardvalue cbuy">{balance}</span>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
