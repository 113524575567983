import React, { useEffect, useState } from 'react';
import {
    Col,
    Button,
    Row,
    Tab,
    Nav,
    OverlayTrigger,
    Tooltip,
    Form,
    Dropdown,
    Card,
    Accordion,
    Badge,
    useAccordionButton
} from 'react-bootstrap';
import { VscRefresh } from 'react-icons/vsc';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { useStoreApi } from '../../store/storeApi';
import 'react-circular-progressbar/dist/styles.css';
import Transaction from './Transaction';
import WalletTransactions from './WalletTransactions';
import { check24hVote } from '../../shared/commonUtils';
import DetailsTab from './DetailsTab';
import SafetyTab from './SafetyTab';
import MonthPerformance from './MonthPerformance';
import MarketsOverview from './MarketsOverview';
import BiggestBuyers from './BiggestBuyers';
import BiggestSellers from './BiggestSellers';

function CryptoOverview() {
    const {
        address,
        cryptoResponse,
        trackWallets,
        tokenAddress,
        getLpInfo,
        filterByPair,
        setFilterByPair,
        refreshTransactionList,
        setRefreshTransactionList
    } = useStoreApi();
    const [alreadyVoted, setAlreadyVoted] = useState(false);
    const [vote, setVote] = useState(null);
    const [selectedTrackAddress, setSelectedTrackAddress] = useState(null);
    const filteringChanged = (event) => {
        console.log('insided the checked');
        setFilterByPair(event.target.checked);
    };

    const [panel, setPanel] = useState(false);
    useEffect(() => {
        if (address) {
            // console.log('trackWallets===>', trackWallets);
            if (trackWallets?.length) {
                setSelectedTrackAddress(trackWallets[0]);
            } else {
                setSelectedTrackAddress(address);
            }
        }
    }, [trackWallets]);

    useEffect(() => {
        if (tokenAddress) {
            const query = `{
                pairs(
                orderBy: reserveUSD
                orderDirection: desc
                where: {
                    token0_contains: "${tokenAddress}"
                }) 
                {
                id
                name
                reserveUSD
                reserveBNB
                reserve0
                reserve1
                }
            }`;
            getLpInfo(query);
        }
    }, [tokenAddress]);

    useEffect(() => {
        checkVoteStatus();
    }, [address, tokenAddress]);

    const checkVoteStatus = async () => {
        if (address && tokenAddress) {
            const res = await check24hVote(address, tokenAddress);
            if (res && res.length) {
                setVote(res[0]);
                setAlreadyVoted(true);
            } else {
                setAlreadyVoted(false);
            }
        } else {
            setAlreadyVoted(false);
        }
    };
    const setStatus = () => {
        console.log('called');
        checkVoteStatus();
    };

    // const getHistorySpanClass = (value) => {
    //   if (cryptoResponse) {
    //     return Math.sign(cryptoResponse.quote.USD[value]) == 1
    //       ? "green-span"
    //       : "red-span";
    //   }
    //   return "";
    // };
    // const getPercentageValue = (value) => {
    //   if (cryptoResponse && cryptoResponse.quote.USD[value]) {
    //     return `${cryptoResponse.quote.USD[value].toFixed(2)}%`;
    //   }
    //   return `${0}%`;
    // };
    const PanelToggler = ({ eventKey }) => {
        const panelToggle = useAccordionButton(eventKey, () => {
            setPanel(!panel);
        });

        return (
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltop-right">{panel ? 'Expand panel' : 'Hide panel'}</Tooltip>}>
                <Button id="acc-panel" variant="default-dark" style={{ marginTop: '-5px' }} onClick={panelToggle}>
                    {panel ? (
                        <BsChevronUp size={16} color="var(--text-base)" style={{ marginTop: '-20px', marginLeft: '-7px' }} />
                    ) : (
                        <BsChevronDown size={16} color="var(--text-base)" style={{ marginTop: '-18px', marginLeft: '-7px' }} />
                    )}
                </Button>
            </OverlayTrigger>
        );
    };

    const handleClick = () => {
        if (panel) {
            document.getElementById('acc-panel').click();
        }
    };

    return (
        <div className=" hideOnMobile" style={{ backgroundColor: 'var(--color-border-grey)', maxWidth: 'auto' }}>
            <Tab.Container defaultActiveKey="trades">
                <Accordion style={{ margin: '4px', borderRadius: '7px', backgroundColor: 'var(--color-border-grey)' }} flush>
                    <Accordion.Item eventkey="7">
                        <Card.Header className="p-0 m-0" style={{ border: 'none' }}>
                            <Col
                                md={12}
                                sm={12}
                                style={{
                                    boxShadow: 'none',
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0
                                }}
                            >
                                <Row className="inline-flex" style={{ paddingBottom: '0px' }}>
                                    <Col md={8} sm={12}>
                                        <Nav variant="pills" className="p-0 m-0">
                                            <Nav.Item
                                                className="active"
                                                style={{ padding: '0', marginLeft: '0', borderRight: '1px solid var(--color-border-grey)' }}
                                            >
                                                <Nav.Link className="largepill mr-1" eventKey="trades" onClick={() => handleClick()}>
                                                    Tradebook
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item
                                                className="active"
                                                style={{ padding: '0', marginLeft: '0', borderRight: '1px solid var(--color-border-grey)' }}
                                            >
                                                <Nav.Link className="largepill mr-1" eventKey="my-trades" onClick={() => handleClick()}>
                                                    My trades
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className="active" style={{ borderRight: '1px solid var(--color-border-grey)' }}>
                                                <Nav.Link
                                                    className="largepill mr-2"
                                                    eventKey="biggest-buyers"
                                                    onClick={() => handleClick()}
                                                >
                                                    Buyers
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className="active" style={{ borderRight: '1px solid var(--color-border-grey)' }}>
                                                <Nav.Link
                                                    className="largepill mr-2"
                                                    eventKey="biggest-sellers"
                                                    onClick={() => handleClick()}
                                                >
                                                    Sellers
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                    <Col
                                        md={4}
                                        sm={12}
                                        className="d-inline-flex align-items-center justify-content-end pr-1"
                                        style={{ height: '30px' }}
                                    >
                                        <Tab.Content className="">
                                            <Tab.Pane eventKey="trades">
                                                <Row>
                                                    <Col className="d-flex" style={{ marginTop: '-5px' }}>
                                                        <Form style={{ height: '25px' }}>
                                                            <div
                                                                key="checkbox"
                                                                className="checkbox-dark"
                                                                style={{ paddingLeft: '8px', paddingTop: '7px', height: '25px' }}
                                                            >
                                                                <Form.Check id="check-pair-filtering">
                                                                    <Form.Check.Input
                                                                        type="checkbox"
                                                                        style={{ marginTop: '0px' }}
                                                                        onClick={filteringChanged}
                                                                    />
                                                                    <Form.Check.Label
                                                                        className="pr-2 filterlabel"
                                                                        style={{ fontSize: '12px' }}
                                                                    >
                                                                        Filter by pair
                                                                    </Form.Check.Label>
                                                                </Form.Check>
                                                            </div>
                                                        </Form>

                                                        <OverlayTrigger
                                                            key="copyprice"
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltop-right">Refresh trades</Tooltip>}
                                                        >
                                                            <Button
                                                                variant="default-dark"
                                                                className="mx-1"
                                                                style={{ marginTop: '0px' }}
                                                                onClick={() => {
                                                                    if (refreshTransactionList === null) {
                                                                        setRefreshTransactionList(true);
                                                                        return;
                                                                    }
                                                                    setRefreshTransactionList(!refreshTransactionList);
                                                                }}
                                                            >
                                                                {' '}
                                                                <VscRefresh
                                                                    size={16}
                                                                    color="var(--text-base)"
                                                                    style={{ marginTop: '-18px', marginLeft: '-7px' }}
                                                                    className="refresh-button"
                                                                />
                                                            </Button>
                                                        </OverlayTrigger>
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="my-trades" className="">
                                                <Row>
                                                    <Col className="d-flex">
                                                        {/* <OverlayTrigger
                                                    key="copyprice"
                                                    placement="top"
                                                    overlay={<Tooltip id="tooltop-right">Refresh trades</Tooltip>}
                                                > */}
                                                        {/* <Dropdown>
                                                            <Dropdown.Toggle
                                                                variant="secondary"
                                                                className="dropdown-basic"
                                                                style={{ border: 'none' }}
                                                            >
                                                                {selectedTrackAddress ? selectedTrackAddress.substr(0, 15) : ''}
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                {trackWallets &&
                                                                    trackWallets.map((trackAddr, index) => (
                                                                        <Dropdown.Item
                                                                            key={index}
                                                                            onClick={() => setSelectedTrackAddress(trackAddr)}
                                                                            href="javascript:void(0)"
                                                                        >
                                                                            {trackAddr.substr(0, 15)}
                                                                        </Dropdown.Item>
                                                                    ))}
                                                            </Dropdown.Menu>
                                                        </Dropdown> */}
                                                        {/* </OverlayTrigger> */}
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="details">
                                                {/* <div
                                                    style={{
                                                        backgroundColor: 'blue',
                                                        paddingLeft: '10px',
                                                        paddingRight: '10px',
                                                        borderRadius: '8px'
                                                    }}
                                                /> */}
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="liquidity">
                                                <div
                                                    style={{
                                                        backgroundColor: '#07090D66',
                                                        paddingLeft: '10px',
                                                        paddingRight: '10px',
                                                        borderRadius: '8px'
                                                    }}
                                                />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="biggest-buyers">
                                                {/* <span className="text-muted" style={{ fontSize: '12px' }}>
                                                    From:
                                                </span>{' '}
                                                <span style={{ fontSize: '12px' }} className="text-base d-inline-flex">
                                                    03/05/2022
                                                </span>{' '}
                                                <span className="text-muted" style={{ fontSize: '12px' }}>
                                                    to&nbsp;
                                                </span>
                                                <span style={{ fontSize: '12px', fontWeight: '100' }} className="d-inline-flex mr-2">
                                                    03/05/2022
                                                </span> */}
                                            </Tab.Pane>
                                        </Tab.Content>
                                        <PanelToggler eventkey="7" />
                                    </Col>
                                </Row>
                            </Col>
                        </Card.Header>
                        <Accordion.Body className="p-0 m-0">
                            <Row style={{ backgroundColor: 'var(--color-border-grey)' }}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="trades">
                                        <Transaction />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="my-trades">
                                        <WalletTransactions trackAddress={selectedTrackAddress} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="details">
                                        <DetailsTab />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="biggest-buyers">
                                        <BiggestBuyers />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="biggest-sellers">
                                        <BiggestSellers />
                                    </Tab.Pane>
                                    {/* <Tab.Pane eventKey="safetycheck">
                                        <SafetyTab />
                                    </Tab.Pane> */}
                                    <Tab.Pane eventKey="liquidity">
                                        <MarketsOverview />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Tab.Container>
        </div>
    );
}

export default React.memo(CryptoOverview);
