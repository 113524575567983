import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';

function Tools() {
    return (
        <div className="toolspage">
            <Tabs
                defaultActiveKey="analysis"
                // variant="pills"
                transition={false}
                id="tab-tools"
                className="d-flex tools-nav justify-content-center"
                selectedtabclassname="bg-black"
            >
                <Tab className="toolstab p-2" eventKey="analysis" title="Token analysis" />
                {/* <Tab className="toolstab p-2" eventKey="bubble" title="Bubble Maps">
                Wow
            </Tab>
            <Tab className="toolstab p-2" eventKey="rugcheck" title="Rug Check" >
                Fish
            </Tab> */}
            </Tabs>
        </div>
    );
}

export default Tools;
