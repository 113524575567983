import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { Doughnut, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useStoreApi } from '../../store/storeApi';
import { getTokenBreakDownData } from '../../shared/commonUtils';
import './index.scss';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function PortfolioBreakdown(props) {
    const { address, allTokens, balance } = useStoreApi();
    const [pieData, setPieData] = useState(null);
    useEffect(() => {
        getTokensData();
    }, [address, allTokens, balance]);

    let pieOptions = {
        plugins: {
            legend: {
                display: true,
                position: 'top',
                labels: {
                    fontColor: 'blue'
                }
            },
            title: {
                display: false
            }
        }
    };

    const getTokensData = async () => {
        if (address && allTokens) {
            const res = props.breakdown;
            if (res && res.length > 0) {
                const l1 = [];
                const c1 = [];
                let other = false;
                allTokens.forEach((obj) => {
                    const percent = getPercentage(obj.tokenprice).toFixed(2);
                    if (percent < 1.2) {
                        other = true;
                    } else {
                        l1.push(obj.symbol);
                        c1.push(percent);
                    }
                });
                if (other) {
                    const sum = c1.reduce((partialSum, a) => partialSum + a, 0);
                    l1.push('Other');
                    c1.push(100 - sum);
                }

                let p1 = {
                    labels: l1,
                    datasets: [
                        {
                            label: '% of portfolio',
                            data: c1,
                            backgroundColor: [
                                'rgba(239, 152, 118, 0.2)', // laksefarvet
                                'rgba(85, 184, 234, 0.2)', // blue
                                'rgba(216, 66, 189, 0.2)', // purple
                                'rgba(131, 204, 165, 0.2)', // green
                                'rgba(153, 102, 255, 0.2)' // other purple
                            ],
                            borderColor: [
                                'rgba(239, 152, 118, 1)', // laksefarvet
                                'rgba(85, 184, 234, 1)', // blue
                                'rgba(216, 66, 189, 1)', // purple
                                'rgba(131, 204, 165, 1)', // green
                                'rgba(153, 102, 255, 1)' // other purple
                            ],
                            borderWidth: 1
                        }
                    ]
                };
                console.log('p1', p1);
                setPieData(p1);
            }
        }
    };

    const getPercentage = (value) => {
        console.log(value, balance);
        return (value / balance) * 100;
    };

    return (
        <div
            // style={{ backgroundColor: 'rgb(31, 34, 48)', boxShadow: 'none', border: '1px solid rgb(41, 45, 64)', borderRadius: '20px' }}
            className="p-4 portfolio-breakdown-container"
        >
            <Col sm={4}>
                <h5 className="text-center font-weight-normal">Portfolio breakdown</h5>
                <p className="text-center text-muted font-weight-normal">Asset allocation</p>
                {pieData ? <Doughnut symbol="%" data={pieData} options={pieOptions} /> : null}
            </Col>

            <Col sm={8}>
                <h5 className="text-center font-weight-normal">Portfolio breakdown</h5>
                <p className="text-center text-muted font-weight-normal">Asset allocation</p>
                {pieData ? <Doughnut symbol="%" data={pieData} options={pieOptions} /> : null}
            </Col>
        </div>
    );
}
