import { useEffect, useState } from 'react';
import { Col, ListGroup, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { recentlyAddedTokenList } from '../shared/commonUtils';

export const RecentlyViewed = () => {
    const [viewedList, setViwedList] = useState();

    useEffect(() => {
        const list = recentlyAddedTokenList();
        setViwedList(list);
    }, []);

    const renderBody = () => {
        if (viewedList && viewedList.length < 1) {
            return (
                <ListGroup.Item
                    key={1}
                    style={{
                        backgroundColor: 'var(--background-lighter)',
                        color: 'var(--text-base)',
                        marginBottom: '6px',
                        borderRadius: '12px'
                    }}
                    action
                >
                    <Row className="d-flex align-content-center">
                        <Col xs={12}>No record found</Col>
                    </Row>
                </ListGroup.Item>
            );
        }
        return (
            viewedList &&
            viewedList.map((item, index) => (
                <Link
                    key={index}
                    style={{
                        color: 'var(--text-base)',
                        textDecoration: 'none'
                    }}
                    to={`/token/${item.id}/${item.token}`}
                    aria-label="Close"
                >
                    <ListGroup.Item
                        style={{
                            backgroundColor: 'var(--background-lighter)',
                            color: 'var(--text-base)',
                            marginBottom: '10px',
                            borderRadius: '12px',
                            border: 'none'
                        }}
                        action
                    >
                        <Row className="d-flex align-content-center">
                            <Col xs={12}>{item.token} </Col>
                        </Row>
                    </ListGroup.Item>
                </Link>
            ))
        );
    };
    return (
        <div>
            <Col className="p-0">
                <ListGroup style={{ borderRadius: '12px', marginBottom: '20px' }}>{renderBody()}</ListGroup>
            </Col>
        </div>
    );
};
