import React, { useEffect, useState } from 'react';
import { Col, Tab, Nav } from 'react-bootstrap';
import { DATA_SERVICE_URL } from '../../../shared/ApiURL';
import { useStoreApi } from '../../../store/storeApi';

const fetcher = (url) => fetch(url).then((r) => r.json());

const Blub = () => {
    const { activePair, bnbToUSDPrice, tokenAddress } = useStoreApi();
    let baseCurrency = '';
    let quoteCurrency = '';
    if (activePair) {
        baseCurrency = activePair.token0;
        quoteCurrency = activePair.token1;
    }

    return <div />;
};

function MinMaxPrice() {
    const { activePair, bnbToUSDPrice, tokenAddress } = useStoreApi();
    return (
        <div>
            <Blub />
        </div>
    );
}

export default MinMaxPrice;

// function MinMaxPrice() {
//     const { activePair, bnbToUSDPrice, tokenAddress } = useStoreApi();
//     const [isLoading, setIsLoading] = useState(true);
//     const [cancel, setCancel] = useState();
//     const [minMax, setMinMax] = useState([]);

//     const fetchMinMax = async () => {
//         if (cancel) return;
//         if (activePair.token0 === 'None') return;
//         try {
//             let since = new Date();
//             let day = since.setDate(since.getDate() - 1);
//             let month = since.setDate(since.getDate() - 30);
//             let fromDay = new Date(day).toISOString();
//             let fromMonth = new Date(month).toISOString();
//             let baseCurrency = await activePair.token0;
//             let quoteCurrency = await activePair.token1;
//             const result = await fetch(
//                 `${DATA_SERVICE_URL}/min-max?fromDay=${fromDay}&fromMonth=${fromMonth}&baseCurrency=${baseCurrency}&quoteCurrency=${quoteCurrency}`
//             );
//             const data = await result.json();
//             setMinMax(data);
//             setIsLoading(false);
//         } catch (error) {
//             setIsLoading(false);
//             console.log(error);
//         }
//     };
//     useEffect(() => {
//         fetchMinMax();
//         return () => {
//             setCancel(true);
//         };
//     }, [tokenAddress]);

//     return (
//         <Tab.Container defaultActiveKey="day">
//             <Col className="p-0" style={{ fontSize: '13px', fontWeight: '100' }}>
//                 <div className="std-info">Min / Max</div>
//             </Col>
//             <Col className="p-0 d-flex justify-content-end" style={{ fontSize: '13px', fontWeight: '100' }}>
//                 <Nav variant="pills">
//                     <Nav.Item>
//                         <Nav.Link eventKey="day" className="minipill mr-1">
//                             24h
//                         </Nav.Link>
//                     </Nav.Item>
//                     <Nav.Item>
//                         <Nav.Link eventKey="month" className="minipill">
//                             30d
//                         </Nav.Link>
//                     </Nav.Item>
//                 </Nav>
//             </Col>
//             <Tab.Content className="pl-0 pr-0">
//                 <Tab.Pane eventKey="day">
//                     {/* <div className="deep-meep mt-1 mb-2" /> */}
//                     <MinMaxBar minInput={minMax} />
//                     <Col className="p-0 d-flex justify-content-between">
//                         <div style={{ fontFamily: 'monotalic', fontSize: '11px', color: 'var(--text-base)' }}>
//                             {minMax && minMax.day?.length === 2 ? `$${(minMax.day[0].min * bnbToUSDPrice).toFixed(5)}` : 'meep'}
//                         </div>
//                         <div style={{ fontFamily: 'monotalic', fontSize: '11px', color: 'var(--text-base)' }}>
//                             {minMax && minMax.day?.length === 2 ? `$${(minMax.day[1].max * bnbToUSDPrice).toFixed(5)}` : 'meep'}
//                         </div>
//                     </Col>
//                 </Tab.Pane>
//                 <Tab.Pane eventKey="month">
//                     <div className="deep-meep mt-1 mb-2" />
//                     <Col className="p-0 d-flex justify-content-between">
//                         {/* <div style={{ fontFamily: 'monotalic', fontSize: '11px', color: 'var(--text-primary)' }}>
//                             {minMaxMonth ? minMaxMonth.min : 'Loading...'}
//                         </div>
//                         <div style={{ fontFamily: 'monotalic', fontSize: '11px', color: 'var(--text-primary)' }}>
//                             {minMaxMonth ? minMaxMonth.max : 'Loading...'}
//                         </div> */}
//                         <div style={{ fontFamily: 'monotalic', fontSize: '11px', color: 'var(--text-base)' }}>
//                             {minMax && minMax.month?.length === 2 ? `$${(minMax.month[0].min * bnbToUSDPrice).toFixed(5)}` : null}
//                         </div>
//                         <div style={{ fontFamily: 'monotalic', fontSize: '11px', color: 'var(--text-base)' }}>
//                             {minMax && minMax.month?.length === 2 ? `$${(minMax.month[1].max * bnbToUSDPrice).toFixed(5)}` : null}
//                         </div>
//                     </Col>
//                 </Tab.Pane>
//             </Tab.Content>
//         </Tab.Container>
//     );
// }

// export default MinMaxPrice;

// function MinMaxBar({ minMax }) {
//     const { tokenPrice } = useStoreApi();
//     const progress = document.querySelector('.minmaxrange-filled');
//     const minInput = minMax?.day[0].min;
//     const input = tokenPrice;
//     // let finalValue =

//     return (
//         <div className="minmaxrange">
//             <div className="minmaxrange-filled" />
//         </div>
//     );
// }
