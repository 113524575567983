import { useStore } from './store';

export const GetState = () => {
    const { state } = useStore();
    return {
        balance: state.balance,
        address: state.address,
        message: state.message,
        cryptoResponse: state.cryptoResponse,
        dexCryptoResponse: state.dexCryptoResponse,
        imageId: state.imageId,
        token: state.token,
        tokenAddress: state.tokenAddress,
        activePair: state.activePair,
        listPairs: state.listPairs,
        liveTransactions: state.liveTransactions,
        transactions: state.transactions,
        productInfoResponse: state.productInfoResponse,
        trade: state.trade,
        tradeleft: state.tradeleft,
        allTokens: state.allTokens,
        fromSearchToken: state.fromSearchToken,
        toSearchToken: state.toSearchToken,
        fromSearchTokenAddress: state.fromSearchTokenAddress,
        toSearchTokenAddress: state.toSearchTokenAddress,
        lpResponse: state.lpResponse,
        transactionsv2: state.transactionsv2,
        metamaskConnected: state.metamaskConnected,
        metamaskStatus: state.metamaskStatus,
        marketcap: state.marketcap,
        csupply: state.csupply,
        tvChart: state.tvChart,
        watchList: state.watchList,
        basetokenprice: state.basetokenprice,
        walletTransactions: state.walletTransactions,
        reloadChart: state.reloadChart,
        lp: state.lp,
        tokenprice: state.tokenprice,
        filterByPair: state.filterByPair,
        walletTokenData: state.walletTokenData,
        bnbToUSDPrice: state.bnbToUSDPrice,
        buyFee: state.buyFee,
        sellFee: state.sellFee,
        layout: state.layout,
        trackWallets: state.trackWallets,
        refreshTransactionList: state.refreshTransactionList,
        walletType: state.walletType,
        walletObj: state.walletObj
    };
};
