function TradeSummary({ swapParams, toToken, slippage }) {
    return (
        <div className="mt-3 mb-2 px-2 py-1" style={{ backgroundColor: 'var(--background-darker)', borderRadius: '9px' }}>
            <div className="d-flex justify-content-between">
                <span className="text-muted lh-lg std-info">Min. received</span>
                <span className="lh-lg std-info" style={{ color: 'var(--text-base)' }}>
                    {swapParams ? ((swapParams.outputAmount / 10 ** toToken.decimal) * (1 - slippage / 100)).toFixed(2) : 0}
                </span>
            </div>
            <div className="d-flex justify-content-between">
                <span className="text-muted lh-lg std-info">Price impact</span>
                <span style={{ color: 'var(--text-base)' }} className="lh-lg std-info">
                    {swapParams
                        ? `~${(((swapParams.amountInUsd - swapParams.amountOutUsd) / swapParams.amountInUsd) * 100).toFixed(2)} %`
                        : `${0} %`}{' '}
                </span>
            </div>
            <div className="d-flex justify-content-between">
                <span className="text-muted lh-lg std-info">Gas price</span>{' '}
                <span style={{ color: 'var(--text-base)' }} className="lh-lg std-info">
                    {swapParams?.gasUsd ? `$${swapParams.gasUsd.toFixed(3)}` : '-'}
                </span>
            </div>
            <div className="d-flex justify-content-between">
                <span className="text-muted lh-lg std-info">Max slippage</span>{' '}
                <div>
                    <span className="lh-lg std-info" style={{ color: 'var(--text-base)' }}>
                        {slippage !== null ? `${slippage} %` : 'Loading'}
                    </span>
                </div>
            </div>
        </div>
    );
}

export default TradeSummary;
