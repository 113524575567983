import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import SearchToken from './SearchToken';
import { CDN_URL } from '../../shared/ApiURL';

const AutoComplete = (props) => {
    const [inputValue, setInputValue] = useState('');
    const [showInfo, setShowInfo] = useState(false);

    const debouncedSave = useCallback(
        debounce((newValue) => props.requests(newValue), 1000),
        []
    );

    const updateValue = (newValue) => {
        setInputValue(newValue);
        debouncedSave(newValue);
    };

    const onSelect = (suggestion) => {
        props.setToken(suggestion);
        setInputValue('');
    };

    const renderSuggestion = () => {
        try {
            console.log('props', props.suggestions[0], `${CDN_URL}/${props.suggestions[0].value.toUpperCase()}.png`);
            if (props.suggestions.length > 0) {
                return props.suggestions.map((suggestion, index) => (
                    <li key={index} className="mb-2" onClick={() => onSelect(suggestion)}>
                        <a className="ether-search media rounded p-2" href="javascript:;">
                            <div className="mr-2">
                                <img
                                    alt="token-logo"
                                    className="u-xs-avatar"
                                    src={
                                        suggestion.logo ? `${CDN_URL}/${suggestion.value.toUpperCase()}.png` : `${CDN_URL}/placeholder.svg`
                                    }
                                    // onError={(e) => {
                                    //     e.target.src = `${CDN_URL}/placeholder.svg`;
                                    //     e.target.onError = null;
                                    // }}
                                />
                            </div>
                            <div className="media-body text-truncate">
                                <h6 className="d-flex align-suggestions-center text-nodecor text-size-1 mb-0">
                                    <div className="text-truncate text-searchh mr-2">
                                        <span className="token-name">{suggestion.label}</span>({suggestion.symbol})
                                    </div>
                                    <i className="fa fa-badge-check text-info ml-auto" />
                                </h6>
                                <span className="text-search text-truncate font-size-1">{suggestion.value}</span>
                            </div>
                        </a>
                    </li>
                ));
            }
            if (inputValue !== '') {
                return (
                    <li className="mb-2">
                        <div className="d-flex row justify-content-center align-items-center media-body text-truncate">
                            <div className="text-center notfoundsearch">No results. Try pasting an address.</div>
                            {/* <Button className="import-btn" size="sm" onClick={() => setShowInfo(true)}>
                                Import manually
                            </Button>{' '} */}
                        </div>
                    </li>
                );
            }
        } catch (error) {}
    };

    return (
        <div className="auto-c-posi-rel">
            <Form.Group>
                <Form.Control
                    type="text"
                    onChange={(input) => updateValue(input.target.value)}
                    placeholder={props.placeholderText}
                    className={props.classProp}
                    trim="blur"
                    value={inputValue}
                />{' '}
            </Form.Group>
            {inputValue !== '' ? <ul className="suggestions">{renderSuggestion()}</ul> : ''}
            <SearchToken show={showInfo} onHide={() => setShowInfo(false)} />
        </div>
    );
};

export default AutoComplete;
