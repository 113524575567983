/**
 * @fileoverview Additional trading view tools.
 */

import { getFeeLinesFromDB, getFeesFromDB, setFeeLinesInDB } from './commonUtils';

/**
 * Draws a spread on the chart.
 * @param {number} close - The current close price of a token.
 */
function drawSpread(close) {
    try {
        const fees = getFeesFromDB();
        if (fees && window.tvWidget) {
            const feeLines = getFeeLinesFromDB();
            const highThreshold = close + (close * fees.buyFee) / 100;
            const lowThreshold = close - (close * fees.sellFee) / 100;
            if (feeLines.length !== 0) {
                window.tvWidget
                    .activeChart()
                    .getShapeById(feeLines[0])
                    .setPoints([{ price: highThreshold }]);
                window.tvWidget
                    .activeChart()
                    .getShapeById(feeLines[1])
                    .setPoints([{ price: lowThreshold }]);
            } else {
                const newFeeLines = [
                    window.tvWidget
                        .activeChart()
                        .createPositionLine()
                        .setText(null)
                        .setTooltip(null)
                        .setProtectTooltip(null)
                        .setCloseTooltip(null)
                        .setReverseTooltip(null)
                        .setQuantity(null)
                        .setPrice(highThreshold)
                        .setExtendLeft(false)
                        .setLineStyle(1)
                        .setLineLength(100),
                    window.tvWidget
                        .activeChart()
                        .createPositionLine()
                        .setText(null)
                        .setTooltip(null)
                        .setProtectTooltip(null)
                        .setCloseTooltip(null)
                        .setReverseTooltip(null)
                        .setQuantity(null)
                        .setPrice(lowThreshold)
                        .setExtendLeft(false)
                        .setLineStyle(1)
                        .setLineLength(100)
                ];
                const feeLineNames = newFeeLines.map((lineObj) => lineObj._line._id);
                setFeeLinesInDB(feeLineNames);
            }
        }
    } catch (e) {}
}

function toggleSpreadVisibility() {
    try {
        const feeLines = getFeeLinesFromDB();

        if (feeLines && window.tvWidget) {
            const isVisible = window.tvWidget.activeChart().getShapeById(feeLines[0]).getProperties().visible;
            window.tvWidget.activeChart().getShapeById(feeLines[0]).setProperties({ visible: !isVisible });
            window.tvWidget.activeChart().getShapeById(feeLines[1]).setProperties({ visible: !isVisible });
        }
    } catch (e) {}
}

export { drawSpread, toggleSpreadVisibility };
