import { Container, Row, Col } from 'react-bootstrap';
import TokenDetails from './TokenDetails';
import TokenProfile from './TokenProfile';

export default function DetailsTab() {
    return (
        <Container fluid className="pr-1 txtable pb-3" style={{ backgroundColor: '#17172180', borderRadius: '8px', overflowX: 'hidden' }}>
            <Row>
                <Col>
                    <TokenProfile />
                </Col>
                <Col>
                    <TokenDetails />
                </Col>
            </Row>
        </Container>
    );
}
