/**
 * @fileoverview Data pre-processing functions.
 */

/* eslint-disable no-param-reassign */
/* eslint-disable no-bitwise */

const FILTER_OUTLIERS = 0b0001;
const FILTER_FLAT_CANDLES = 0b0010;
const FILTER_DUPLICATE_CANDLES = 0b0100;

/**
 * Checks, if the value is in the range.
 * @param {number} value - The value to check.
 * @param {Array.<number>} boundaries - The array of range boundaries.
 * @return {boolean} - True, if the number is within the boundaries.
 */
function inRange(value, boundaries) {
    return value >= boundaries[0] && value <= boundaries[1];
}

/**
 * Checks, if the OHLC values are equal (a candle is flat).
 * @param {Array.<number>} ohlc - The array of OHLC values.
 * @return {boolean} - True, if the candle is flat.
 */
function isCandleFlat(ohlc) {
    return ohlc.every((val, i, arr) => val === arr[0]);
}

/**
 * Checks, if two candles are equal.
 * @param {Array.<number>} candleValues1 - The OHLC values of the first candle.
 * @param {Array.<number>} candlesValues2 - The OHLC values of the second candle.
 * @return {boolean} - True, if the candles are equal.
 */
function candlesEqual(candleValues1, candlesValues2) {
    if (candleValues1.length !== 4 || candlesValues2.length !== 4) return false;
    for (let i = 0; i < 4; i += 1) if (candleValues1[i] !== candlesValues2[i]) return false;
    return true;
}

/**
 * Drops out outlier candles from the OHLC arrays in-line.
 * @param {Array.<number>} listOpen - The array of open values.
 * @param {Array.<number>} listHigh - The array of high values.
 * @param {Array.<number>} listLow - The array of low values.
 * @param {Array.<number>} listClose - The array of close values.
 * @param {number} mode - The filtering mode.
 */

// 🔴 commenting out this filtering stuff to see how it affects chart loading
// function filterCandles(listOpen, listHigh, listLow, listClose, mode) {
//     /**
//      * Gets lower and upper thresholds for the provided array of numbers.
//      * @param {Array.<number>} data - The array of numbers.
//      * @return {Array.<number>} - The 2-element array of the lower and upper thresholds.
//      */
//     function getFilterValues(data) {
//         const values = [...data].sort((a, b) => a - b);
//         const q1 = values[Math.floor(values.length / 4)];
//         const q3 = values[Math.ceil(values.length * (3 / 4))];
//         const iqr = q3 - q1;
//         const maxValue = q3 + iqr * 1.5;
//         const minValue = q1 - iqr * 1.5;
//         return [minValue, maxValue];
//     }

//     const listLength = listOpen.length;
//     if (!Object.values([listOpen, listHigh, listLow, listClose]).every((x) => x.length === listLength)) return;

//     const openThresholds = getFilterValues(listOpen);
//     const highThresholds = getFilterValues(listOpen);
//     const lowThresholds = getFilterValues(listOpen);
//     const closeThresholds = getFilterValues(listOpen);

//     for (let i = listOpen.length - 1; i >= 0; i -= 1) {
//         if (
//             (mode & FILTER_OUTLIERS &&
//                 (!inRange(listOpen[i], openThresholds) ||
//                     !inRange(listHigh[i], highThresholds) ||
//                     !inRange(listLow[i], lowThresholds) ||
//                     !inRange(listClose[i], closeThresholds))) ||
//             (mode & FILTER_FLAT_CANDLES && isCandleFlat([listOpen[i], listHigh[i], listLow[i], listClose[i]])) ||
//             (mode & FILTER_DUPLICATE_CANDLES &&
//                 i > 0 &&
//                 candlesEqual(
//                     [listOpen[i], listHigh[i], listLow[i], listClose[i]],
//                     [listOpen[i - 1], listHigh[i - 1], listLow[i - 1], listClose[i - 1]]
//                 ))
//         ) {
//             listOpen.splice(i, 1);
//             listHigh.splice(i, 1);
//             listLow.splice(i, 1);
//             listClose.splice(i, 1);
//         }
//     }
// }

/**
 * Processes a single live transaction.
 * @param {Object} transaction - The live transaction to process.
 */
function processLiveTransaction(transaction) {
    const datetime = new Date(transaction.timestamp);
    transaction.cellClass = transaction.isBuy ? 'tcbuy' : 'tcsell';
    const locale = navigator.language != null ? navigator.language : 'en-GB';
    if (datetime.getDate() !== new Date().getDate()) {
        transaction.txTime = datetime.toLocaleString(locale, {
            day: 'numeric',
            month: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });
    } else {
        transaction.txTime = datetime.toLocaleString(locale, {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });
    }
}

export { processLiveTransaction, FILTER_OUTLIERS, FILTER_DUPLICATE_CANDLES, FILTER_FLAT_CANDLES };
