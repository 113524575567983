// eslint-disable-next-line no-use-before-define

import React, { useEffect, useState } from 'react';
import { useHistory, Link, NavLink } from 'react-router-dom';
import { BiSearch } from 'react-icons/bi';
import { GiWireframeGlobe } from 'react-icons/gi';
import { AiOutlineMenu } from 'react-icons/ai';
import { IconContext } from 'react-icons';
import { Nav, Row, Col, Button, Modal, Offcanvas, Badge, Navbar, Dropdown } from 'react-bootstrap';
import { useMoralis } from 'react-moralis';
import { useAccount, useConnect, useDisconnect } from 'wagmi';
import { HiHeart } from 'react-icons/hi';
import { RiHistoryFill } from 'react-icons/ri';
import { useStoreApi } from '../store/storeApi';
import useWeb3 from '../useWeb3';
import { NavigationData } from './NavigationData';
import '../Styles/modal-popup.scss';
// import WalletConnectQRCodeModal from '@walletconnect/qrcode-modal';
import { getBlackListedTokens, addTokenToMoralis, getDecimals, searchToken } from '../shared/commonUtils';
import { MORALIS_FIAT_PROD_KEY, MORALIS_SERVER_URL, MORALIS_APP_ID } from '../shared/ApiURL';
import IMG_LOGO from '../assets/img/logo_pokedx.svg';
import METAMASK_LOGO from '../assets/img/metamask-2.svg';
import TRUSTWALLET_LOGO from '../assets/img/trustwallet.svg';
import { RecentlyViewed } from './RecentlyViewed';
import { Favourites } from './Favourites';
import AutoComplete from './AutoCompleateTextBox/AutoComplete';

const ConnectedButton = () => {
    const { disconnect } = useDisconnect();
    return (
        <div className="connected-button-mobile">
            <button onClick={disconnect}>Disconnect</button>
        </div>
    );
};

export default function Header(props) {
    const {
        basetokenprice,
        setTokenAddress,
        setToken,
        setAddress,
        setBalance,
        setAllTokens,
        metamaskConnected,
        setMetamaskStatus,
        getPokedxPriceInUSD
    } = useStoreApi();
    const web3 = useWeb3();
    const [showModal, setShowModal] = useState(false);
    const [tokenResponse, setTokenResponse] = useState([]);
    const [userInput, setUserInput] = useState('');
    const history = useHistory();

    // Wallet Connect
    const { Moralis } = useMoralis();

    const openWalletPopup = () => {
        setShowModal(true);
    };
    // wagmi methods
    const { address, isConnected } = useAccount();
    const { connect, connectors, isLoading } = useConnect();
    const { disconnect } = useDisconnect();
    // delete this when done
    // useEffect(() => {
    //     if (address) {
    //         setMetamaskStatus('CONNECTED');
    //         setAddress(address);
    //         updateBalance(address);
    //         setShowModal(false);
    //     }
    // }, [address]);

    // end wallet connect
    const getUserAccount = async (connector) => {
        connect({ connector });
        setShowModal(false);
    };
    useEffect(() => {
        window.Moralis.initialize(MORALIS_FIAT_PROD_KEY);
        window.Moralis.serverURL = MORALIS_SERVER_URL;
        (async function () {
            window.Moralis.initPlugins();
        })();
    }, []);
    useEffect(() => {
        if (metamaskConnected && address == null) {
            getUserAccount();
        }
    }, [metamaskConnected, address]);

    // delete when done
    useEffect(() => {
        if (window.ethereum) {
            window.ethereum.on('accountsChanged', (accounts) => {
                if (accounts && web3) {
                    setAddress(accounts[0]);
                    updateBalance(accounts[0]);
                }
            });
        }
    }, [web3]);

    useEffect(() => {
        getPokedxPriceInUSD();
    }, []);

    useEffect(() => {
        if (basetokenprice) {
            document.title = `PokeDX Real-Time Charts & Trading - $${basetokenprice.toFixed(3)}`;
        }
    }, [basetokenprice]);

    const disconnectWallet = () => {
        setAddress(null);
        setMetamaskStatus(null);
        disconnect();
        // logout();
    };

    const updateBalance = async (fromAddress) => {
        let finalBalance = 0;
        try {
            const balance = await web3.eth
                .getBalance(fromAddress)
                .then((value) => parseFloat(web3.utils.fromWei(value, 'ether')).toFixed(4));
            const balancesOfTokens = await getAllAccountTokens(fromAddress, balance);
            if (balancesOfTokens.length) {
                const bitRes = balancesOfTokens.map((obj) => {
                    const priceObj = getTokenUsdPrice(obj);
                    obj.tokenaddress = obj.token_address;
                    obj.quantity = priceObj.quantity;
                    finalBalance += priceObj.price;
                    obj.tokenprice = priceObj.price;
                    delete obj.token_address;
                    delete obj.balance;
                    return obj;
                });
                setAllTokens(bitRes);
                setBalance(finalBalance.toFixed(2));
            } else {
                setBalance(0);
            }
        } catch (error) {
            console.error('Error getwallet balance', error.message);
        }
    };

    const getTokenUsdPrice = (obj) => {
        const quantity = obj.balance / 10 ** obj.decimals;
        const price = quantity * obj.usdPrice;
        return {
            quantity,
            price
        };
    };

    const getAllAccountTokens = async (address, balance) => {
        const serverUrl = MORALIS_SERVER_URL;
        const appId = MORALIS_APP_ID;
        Moralis.start({ serverUrl, appId });
        let bnb = null;
        if (balance) {
            bnb = {
                symbol: 'BNB',
                name: 'BNB',
                balance: balance * 10 ** 18,
                decimals: '18',
                token_address: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c'
            };
        }
        const params = { address, bnb };
        const results = await Moralis.Cloud.run('getWalletTokens', params);
        let whiteListAddress = [];
        if (results != undefined) {
            const blacklistTokens = getBlackListedTokens();
            whiteListAddress = results?.filter((elem) => !blacklistTokens.includes(elem.token_address));
            return whiteListAddress;
        }
        whiteListAddress.push(bnb);
        return whiteListAddress;
    };

    const onClose = () => {
        setShowModal(false);
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // search
    const getSuggestions = async (word) => {
        const response = await searchToken(word);
        if (response && response.length) {
            setTokenResponse(response);
        } else {
            setTokenResponse(response);
            const pattern = /0x/i;
            if (word.match(pattern)) {
                const apiResponse = await getDecimals(word, true);
                if (apiResponse) {
                    const result = [
                        {
                            label: apiResponse.name,
                            value: apiResponse.address,
                            symbol: apiResponse.symbol,
                            desc: apiResponse.name,
                            isNew: true,
                            logo: 0
                        }
                    ];
                    setTokenResponse(result);
                    addTokenToMoralis(apiResponse, 1, 0);
                }
            }
        }
    };

    const selectToken = async (item) => {
        setTokenAddress(item.value);
        const { typeval } = item;
        const selectedToken = item.symbol;
        const selectedAddress = item.value;
        setTokenAddress(selectedAddress);
        setToken(selectedToken);
        setUserInput('');
        history.push(`/token/${selectedAddress}/${selectedToken}`);
    };

    const avatarSource = `https://avatars.dicebear.com/api/identicon/${JSON.stringify(address)}.svg`;

    return (
        <>
            <div style={{ width: '100%' }} className="hideOnDesktop">
                <div className="input-group-prepend " />
                <IconContext.Provider value={{ color: 'white', className: 'searchicon-token-bar' }}>
                    <BiSearch size={20} style={{ paddingTop: '2px' }} />
                </IconContext.Provider>
                <div className="col-md-12">
                    <AutoComplete
                        suggestions={tokenResponse}
                        placeholderText="Search by name or paste address..."
                        requests={getSuggestions}
                        classProp="dex-search-bar-mobile"
                        setToken={selectToken}
                    />
                </div>
            </div>
            <div
                style={{
                    position: 'fixed',
                    padding: '10px 0 10px 0',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width: '100%',
                    backgroundColor: 'var(--background-base)',
                    borderTop: '1px solid var(--color-border-grey)',
                    marginLeft: '0px',
                    zIndex: '10000'
                }}
                className="d-flex row hideOnDesktop"
            >
                <Row>
                    <Col xs={2} className="d-flex justify-content-center align-items-center">
                        <AiOutlineMenu
                            // onClick={setShow == false ? handleShow : handleClose}
                            onClick={() => {
                                if (show == false) {
                                    handleShow();
                                } else {
                                    handleClose();
                                }
                            }}
                            size={40}
                            style={{ color: '#c3c2d4', cursor: 'pointer' }}
                            className="openMenu"
                        />
                        <Offcanvas show={show} onHide={handleClose} placement="bottom" style={{ height: '100vh', zIndex: '9999' }}>
                            <Offcanvas.Header style={{ backgroundColor: '#1c1c27' }}>
                                <Offcanvas.Title
                                    className="d-flex justify-content-between align-items-center "
                                    style={{ display: 'flow-root' }}
                                >
                                    <div>
                                        <Link to="/" style={{ textDecoration: 'none' }}>
                                            <div style={{ fontFamily: 'automate', color: '#f7f7f7', fontWeight: '400', fontSize: '18px' }}>
                                                <img
                                                    src={IMG_LOGO}
                                                    onClick={handleClose}
                                                    height="35px"
                                                    width="35px"
                                                    style={{ marginTop: '0px', marginRight: '8px' }}
                                                />
                                            </div>
                                        </Link>
                                    </div>
                                    <div style={{ marginTop: '-3px' }}>
                                        <Badge
                                            style={{
                                                color: '#c3c2d4',
                                                fontWeight: '400',
                                                fontFamily: 'Ligurino',
                                                backgroundColor: '#303044'
                                            }}
                                        >
                                            v0.7 Beta
                                        </Badge>
                                    </div>
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body
                                className="p-0 d-flex justify-content-start align-items-end"
                                style={{ marginBottom: '40px', backgroundColor: '#1c1c27' }}
                            >
                                <Nav className="d-flex flex-column align-items-start pb-4">
                                    {NavigationData.map((item, index) => (
                                        <li key={index} className={item.cname} style={{ padding: '0', color: '#c3c2d4' }}>
                                            {item.name == 'report' ? (
                                                <a className="nav-link" href="https://thomas587.typeform.com/to/dKQOyatB">
                                                    {item.title}
                                                </a>
                                            ) : (
                                                <NavLink
                                                    to={item.path}
                                                    exact
                                                    onClick={handleClose}
                                                    // className="nav-link"
                                                    style={{ fontSize: '30px', fontWeight: '400', color: '#c3c2d4', marginBottom: '12px' }}
                                                    activeClassName="active"
                                                >
                                                    {item.icon}
                                                    {item.title}
                                                </NavLink>
                                            )}
                                        </li>
                                    ))}
                                </Nav>
                            </Offcanvas.Body>
                        </Offcanvas>
                    </Col>
                    <Col xs={10} className="p-0">
                        {address ? null : (
                            <Button
                                variant="primary-h"
                                style={{
                                    borderRadius: 8,
                                    height: '32px'
                                }}
                                onClick={() => openWalletPopup()}
                            >
                                Connect
                            </Button>
                        )}
                        {address ? (
                            <div className="d-flex flex-row">
                                <ConnectedButton />
                                {/* <OpenWalletSidebar /> */}
                                {/* <Button
                                    style={{
                                        borderRadius: 8,
                                        height: '42px',
                                        width: '42px',
                                        marginLeft: '25px',
                                        border: 'none'
                                    }}
                                >
                                    <MdAccountBalanceWallet size={34} style={{ marginTop: '-8px', marginLeft: '-7px' }} />
                                </Button> */}
                            </div>
                        ) : null}
                        <OpenWalletSelection
                            connectors={connectors}
                            isLoading={isLoading}
                            show={showModal}
                            close={onClose}
                            connecttometamask={() => connectMetaMask(true)}
                            connecttowallet={() => connectMetaMask(true)}
                        />
                    </Col>
                </Row>
            </div>
            <Navbar
                variant="dark"
                expand="sm"
                className=" hideOnMobile"
                style={{ paddingLeft: '10px', paddingRight: '8px', marginTop: '-7px', marginBottom: '-5px', zIndex: '99' }}
            >
                <Navbar.Brand className="p-0 m-0">
                    <div className="brand m-0 p-0">
                        <Link to="/" style={{ textDecoration: 'none' }}>
                            <img src={IMG_LOGO} height="30px" width="30px" style={{ marginRight: '12px' }} />
                        </Link>
                    </div>
                </Navbar.Brand>
                <Navbar.Collapse>
                    <div className=" brand" style={{ marginLeft: '3px', marginRight: '0px', paddingRight: '4px' }}>
                        <Dropdown>
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic" disabled>
                                <GiWireframeGlobe size={17} style={{ marginTop: '-10px', marginLeft: '-5px', marginRight: '8px' }} />
                                BNB Chain
                            </Dropdown.Toggle>
                        </Dropdown>
                    </div>
                    <Nav className="ml-2">
                        {NavigationData.map((item, index) => (
                            <li key={index} className={item.cname}>
                                {item.name == 'report' ? (
                                    <a className="nav-link" href="https://thomas587.typeform.com/to/dKQOyatB">
                                        {item.title}
                                    </a>
                                ) : (
                                    <NavLink to={item.path} exact className="nav-link" activeClassName="active">
                                        {item.icon}
                                        {item.title}
                                    </NavLink>
                                )}
                            </li>
                        ))}
                        {/* <Dropdown>
                            <Dropdown.Toggle className="nav-link" activeClassName="active">
                                Trading tools
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item>Converter</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> */}
                    </Nav>
                    <Col className="d-flex justify-content-end pr-0">
                        <div className="">
                            {!props.home ? (
                                <div className="m-0 p-0 ">
                                    <Button
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasFavs"
                                        variant="fav"
                                        className="searchbar-button"
                                        style={{
                                            width: 'auto',
                                            // border: "1px solid #959cb250",
                                            height: '30px',
                                            backgroundColor: 'var(--background-lighter)',
                                            borderRadius: '8px',
                                            fontSize: '12px',
                                            lineHeight: 1,
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 7,
                                            paddingRight: 7,
                                            // textTransform: "uppercase",
                                            marginTop: '0px',
                                            marginRight: '10px',
                                            marginLeft: '0px'
                                        }}
                                    >
                                        <IconContext.Provider value={{ color: 'white' }}>
                                            <HiHeart
                                                className="meow"
                                                size={20}
                                                color="var(--text-base)"
                                                style={{
                                                    padding: 0,
                                                    margin: 0
                                                }}
                                            />
                                        </IconContext.Provider>
                                    </Button>
                                    <div
                                        className="offcanvas offcanvas-end"
                                        style={{ width: '310px' }}
                                        tabIndex="-1"
                                        id="offcanvasFavs"
                                        aria-labelledby="offcanvasFavsLabel"
                                    >
                                        <div className="offcanvas-header">
                                            <h5
                                                className="font-weight-normal mb-0 pb-0"
                                                style={{ color: 'var(--text-normal)' }}
                                                id="offcanvasFavsLabel"
                                            >
                                                Favourites
                                            </h5>
                                            <button
                                                type="button"
                                                className="btn-close btn-close-white text-reset"
                                                data-bs-dismiss="offcanvas"
                                                aria-label="Close"
                                            />
                                        </div>
                                        <div className="offcanvas-body pt-1 m-0">
                                            <Favourites />
                                        </div>
                                    </div>
                                    <Button
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasRecents"
                                        variant="fav"
                                        className="searchbar-button"
                                        style={{
                                            width: 'auto',
                                            // border: "1px solid #959cb250",
                                            height: '30px',
                                            backgroundColor: 'var(--background-lighter)',
                                            borderRadius: '8px',
                                            fontSize: '12px',
                                            lineHeight: 1,
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 7,
                                            paddingRight: 7,
                                            // textTransform: "uppercase",
                                            marginTop: '0px',
                                            marginRight: '-3px',
                                            marginLeft: '0px'
                                        }}
                                    >
                                        <IconContext.Provider value={{ color: 'white' }}>
                                            <RiHistoryFill
                                                className="meow"
                                                size={20}
                                                color="var(--text-base)"
                                                style={{
                                                    padding: 0,
                                                    margin: 0
                                                }}
                                            />
                                        </IconContext.Provider>
                                    </Button>
                                    <div
                                        style={{ width: '310px' }}
                                        className="offcanvas offcanvas-end"
                                        tabIndex="-1"
                                        id="offcanvasRecents"
                                        aria-labelledby="offcanvasRecentsLabel"
                                    >
                                        <div className="offcanvas-header">
                                            <h5
                                                className="font-weight-normal mb-0 pb-0"
                                                style={{ color: 'var(--text-normal)' }}
                                                id="offcanvasRecentsLabel"
                                            >
                                                Recently viewed
                                            </h5>
                                            <button
                                                type="button"
                                                className="btn-close btn-close-white text-reset"
                                                data-bs-dismiss="offcanvas"
                                                aria-label="Close"
                                            />
                                        </div>
                                        <div className="offcanvas-body pt-1 m-0">
                                            <RecentlyViewed />
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div style={{ width: '290px', marginLeft: '8px', marginRight: '8px' }} className="brand input-group input-group-sm">
                            <div className="input-group-prepend d-none d-md-block" />
                            <IconContext.Provider value={{ color: 'white', className: 'searchicon-token-bar' }}>
                                <BiSearch size={20} style={{ paddingTop: '2px' }} />
                            </IconContext.Provider>
                            <div className="col-md-12" style={{ marginTop: '-15px' }}>
                                <AutoComplete
                                    suggestions={tokenResponse}
                                    placeholderText="Search by name or paste address..."
                                    requests={getSuggestions}
                                    classProp="dex-search-bar"
                                    setToken={selectToken}
                                />
                            </div>
                        </div>
                        {isConnected ? (
                            <div className="d-flex navlastitem">
                                <div className="btn-group">
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            className="wallet-btn"
                                            variant="secondary"
                                            style={{
                                                borderRadius: 8,
                                                height: '32px',
                                                border: 'none'
                                            }}
                                        >
                                            {/* <IconContext.Provider value={{ color: 'var(--text-base)', className: 'wicon' }}>
                                                <MdAccountBalanceWallet size={16} />
                                            </IconContext.Provider> */}
                                            <div
                                                className="wicon m-0 p-0"
                                                style={{
                                                    borderRadius: '100px',
                                                    backgroundColor: 'var(--background-light)',
                                                    width: '22px',
                                                    height: '22px'
                                                }}
                                            >
                                                <img
                                                    height="11px"
                                                    width="11px"
                                                    alt="avatar"
                                                    src={avatarSource}
                                                    style={{ marginTop: '3px' }}
                                                />
                                            </div>
                                            <div className=" wbalance mpr m-0 p-0">
                                                {(address || '').slice(0, 4)}...{(address || '').slice(-4)}
                                            </div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu style={{ marginRight: '40px' }}>
                                            <Dropdown.Item onClick={() => disconnectWallet()}>Disconnect</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        ) : (
                            <div style={{ marginTop: '-1.5px' }}>
                                <button className="btn btn-primary-h f12 d-flex align-items-center" onClick={() => openWalletPopup()}>
                                    Connect your wallet
                                </button>
                            </div>
                        )}
                    </Col>
                </Navbar.Collapse>
                {/* <Navbar.Toggle /> */}
                <OpenWalletSelection
                    connectors={connectors}
                    isLoading={isLoading}
                    show={showModal}
                    close={onClose}
                    connecttometamask={getUserAccount}
                    connecttowallet={getUserAccount}
                />
            </Navbar>
        </>
    );
}

function OpenWalletSelection(props) {
    const { pendingConnector } = useConnect();
    return (
        <Modal size="md" {...props} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
                <div className="d-flex flex-column">
                    <Col className="d-flex justify-content-between">
                        <span className="h5" style={{ fontWeight: '300', color: '#f7f7f7', fontSize: '18px' }}>
                            Connect your wallet
                        </span>
                        <span className="h5" style={{ fontWeight: '300', color: '#f7f7f7', fontSize: '18px' }}>
                            {/* <OverlayTrigger placement="top" overlay={<Tooltip>Track</Tooltip>}>
                                {({ ref, ...triggerHandler }) => (
                                    <AiOutlineQuestionCircle
                                        ref={ref}
                                        size={20}
                                        style={{ color: 'var(--text-base)', marginTop: '-12px' }}
                                        {...triggerHandler}
                                    />
                                )}
                            </OverlayTrigger> */}
                        </span>
                    </Col>
                    <div className="m-1 p-2 pb-0 d-flex row" style={{ backgroundColor: '#171722', borderRadius: '5px' }}>
                        {props.connectors.map((connector) => (
                            <Button
                                key={connector.name}
                                variant="alternative"
                                className=" mb-2"
                                disabled={!connector.ready}
                                // key={connector.id}
                                onClick={() => props.connecttometamask(connector)}
                            >
                                {connector.name == 'MetaMask' ? (
                                    <img new src={METAMASK_LOGO} width="20" height="20" />
                                ) : (
                                    <img new src={TRUSTWALLET_LOGO} width="20" height="20" />
                                )}
                                &nbsp;
                                {connector.name}
                                {!connector.ready && ' (not available)'}
                                {props.isLoading && connector.id === pendingConnector?.id && ' (connecting)'}
                            </Button>
                        ))}
                    </div>
                    <div className="d-flex col justify-content-around p-0 m-0">
                        {/* <Button style={{ textAlign: 'left', fontWeight: '100' }} variant="primary" onClick={() => props.close()}>
                            <AiOutlineQuestionCircle
                                size={16}
                                style={{ color: 'var(--text-base)', marginTop: '-9px', marginRight: '4px' }}
                            />{' '}
                            About wallets
                        </Button> */}
                        <Button
                            style={{ textAlign: 'right', fontWeight: '100', border: 'none' }}
                            variant="secondary"
                            onClick={() => props.close()}
                        >
                            Skip for now ↩
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
