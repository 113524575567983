import React, { useEffect, useState } from 'react';
import { Badge, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { RiEye2Line } from 'react-icons/ri';
import { FiArrowDownRight, FiArrowUpRight } from 'react-icons/fi';
import { useAccount } from 'wagmi';
import { useStoreApi } from '../../store/storeApi';
import {
    numberWithCommas,
    setLiveTransactionsInDb,
    toFixedDecimal,
    txValueWithCommas,
    getLastTransactionsPair,
    setLastTransactionsPair,
    getLastActiveToken,
    setLastActiveToken,
    getLastTxFetchDatetime,
    setLastTxFetchDatetime,
    minToMs,
    DisplayErrorMsg
} from '../../shared/commonUtils';
import { processLiveTransaction } from '../../shared/dataProcessing';
import { DATA_SERVICE_URL } from '../../shared/ApiURL';
import BSCSCAN_LOGO from '../../assets/img/BscScan-logo-light-circle.png';
import '../../Styles/Themes.scss';
import '../../index.css';

export default function Transaction() {
    const txUpdatePeriod = minToMs(7);
    const { activePair, listPairs, tokenAddress, liveTransactions, setLiveTransactions, filterByPair, refreshTransactionList } =
        useStoreApi();
    // const { allTokens, setTrackWallet } = useStoreApi();
    // const { address } = useAccount();

    // const TrackWallet = (props) => {
    //     const [showTrack, setShowTrack] = useState(false);
    //     const handleCloseTrack = () => setShowTrack(false);
    //     const handleShowTrack = () => {
    //         if (address) {
    //             const pdxBalance = getPdxBalance();
    //             if (pdxBalance > 5000) {
    //                 addToTrackList(props.trackAddress);
    //             } else {
    //                 setShowTrack(true);
    //             }
    //         } else {
    //             DisplayErrorMsg('Please connect to wallet to use this feature.');
    //         }
    //     };

    //     const addToTrackList = (trackAddress) => {
    //         const list = JSON.parse(localStorage.getItem('TrackWallets')) || [];
    //         if (list && !list.includes(trackAddress)) {
    //             list.push(trackAddress);
    //             localStorage.setItem('TrackWallets', JSON.stringify(list));
    //             DisplayErrorMsg('Added successfully to track list');
    //             setTrackWallet(list);
    //         } else {
    //             DisplayErrorMsg('Already in track list');
    //         }
    //     };

    //     const getPdxBalance = () => {
    //         if (allTokens?.length > 0) {
    //             const pdx = allTokens.filter((obj) => obj.tokenaddress === '0x43a0c5eb1763a211aa3c05849a617f2ee0452767');
    //             if (pdx?.length) {
    //                 return pdx[0].quantity;
    //             }
    //             return 0;
    //         }
    //         setShowTrack(true);
    //     };

    //     return (
    //         <>
    //             <OverlayTrigger placement="top" overlay={<Tooltip>Track</Tooltip>}>
    //                 {({ ref, ...triggerHandler }) => (
    //                     <button
    //                         className="m-0 p-0"
    //                         ref={ref}
    //                         onClick={handleShowTrack}
    //                         {...triggerHandler}
    //                         style={{ cursor: 'pointer', border: 'none', background: 'none' }}
    //                     >
    //                         <RiEye2Line size={18} style={{ marginTop: '-7px', color: 'var(--text-base)' }} />
    //                     </button>
    //                 )}
    //             </OverlayTrigger>
    //             <Modal
    //                 show={showTrack}
    //                 onHide={handleCloseTrack}
    //                 centered
    //                 aria-labelledby="contained-modal-title-vcenter"
    //                 // style={{ backgroundColor: 'blue' }}
    //             >
    //                 <Modal.Header
    //                     style={{
    //                         borderRadius: '8px 8px 0px 0px',
    //                         color: 'var(--text-muted)',
    //                         border: 'none',
    //                         backgroundColor: '#232334'
    //                     }}
    //                 >
    //                     <Modal.Title className="h6" style={{ fontWeight: '400', color: '#f7f7f7' }}>
    //                         Track wallet
    //                     </Modal.Title>
    //                 </Modal.Header>
    //                 <Modal.Body
    //                     className="pt-1"
    //                     style={{
    //                         fontSize: '14px',
    //                         borderRadius: '0px 0px 8px 8px',
    //                         color: 'var(--text-base)',
    //                         fontWeight: '100',
    //                         backgroundColor: '#232334'
    //                     }}
    //                 >
    //                     This premium feature is currently available for registered testers only. Wallet tracking will be made available to
    //                     users with 5,000 PDX in the coming days.
    //                 </Modal.Body>
    //             </Modal>
    //         </>
    //     );
    // };

    const renderBody = () => {
        try {
            if (!liveTransactions) return;
            const transactions = filterByPair
                ? liveTransactions.filter((x) => x.quoteAddress.toLowerCase() === activePair.token1.toLowerCase())
                : liveTransactions;
            return transactions.map((transaction, index) => (
                <tr key={transaction.hash}>
                    <td className={`hideOnMobile ${transaction.cellClass}`}>
                        <div style={{ height: '18px', width: '18px', backgroundColor: 'var(--background-light)', borderRadius: '10px' }}>
                            {transaction.cellClass === 'tcsell' ? (
                                <FiArrowDownRight style={{ marginTop: '-7px', marginLeft: '2px' }} size={14} />
                            ) : (
                                <FiArrowUpRight style={{ marginTop: '-7px', marginLeft: '2px' }} size={14} />
                            )}
                        </div>
                    </td>
                    <td className={transaction.cellClass}>
                        {numberWithCommas(parseFloat(transaction.traded).toFixed(3))}{' '}
                        <span className="text-muted"> {activePair.symbol0}</span>
                    </td>
                    <td className={transaction.cellClass}>
                        ${numberWithCommas(txValueWithCommas(transaction.txValue))}
                        <span className="text-muted">
                            {' '}
                            {transaction.quoteAmount.toFixed(4)} {transaction.quoteSymbol}
                        </span>
                        {/* <span className="">USD</span> */}
                    </td>
                    <td style={{ color: 'var(--text-base)', fontWeight: '100', fontFamily: 'monotalic' }}>
                        ${transaction.price.toFixed(toFixedDecimal(transaction.price))}
                        <br />
                        {/* <span className="text-muted">Pc v2</span> */}
                    </td>
                    <td style={{ color: 'var(--text-muted)', fontWeight: '100', fontFamily: 'monotalic' }}>
                        {transaction.txTime}
                        {/* <br /><span className="grey-tx">GMT +1</span> */}
                    </td>
                    <td className="">
                        <OverlayTrigger placement="top" overlay={<Tooltip>{transaction.hash.substring(0, 6)}</Tooltip>}>
                            {({ ref, ...triggerHandler }) => (
                                <a ref={ref} {...triggerHandler} href={transaction.link} target="_blank" rel="noreferrer">
                                    <img src={BSCSCAN_LOGO} height="14px" />
                                    {/* {transaction.hash.substring(0, 6)} */}
                                </a>
                            )}
                        </OverlayTrigger>
                        {/* &nbsp;
                        <TrackWallet trackAddress={transaction.from} /> */}
                        {/* <span className="grey-tx">
                            <img src={transaction.dex === 'pancakeswap' ? PANCAKE_LOGO : APESWAP_LOGO} height="18px" />
                        </span> */}
                    </td>
                </tr>
            ));
        } catch (e) {
            // do nothing.
        }
    };

    const getTransactionHistory = async (activePair, useCache) => {
        if (!activePair || activePair.dex === 'nullswap') return;
        if (useCache) {
            const prevActivePair = getLastTransactionsPair();
            const prevActiveToken = getLastActiveToken();
            const lastTxFetchDatetime = getLastTxFetchDatetime();
            if (
                prevActivePair.dex !== 'nullswap' &&
                prevActivePair.pair?.address === activePair.pair.address &&
                prevActiveToken === tokenAddress &&
                lastTxFetchDatetime !== null &&
                Date.now() - lastTxFetchDatetime < txUpdatePeriod
            ) {
                return;
            }
        }
        setLastActiveToken(tokenAddress);
        setLastTransactionsPair(activePair);
        setLastTxFetchDatetime(Date.now());
        setLiveTransactions([]);
        setLiveTransactionsInDb([]);
        const pairs = listPairs.map((x) => ({
            address: x.pair.address,
            dex: x.dex
        }));
        const data = {
            baseToken: activePair.token0,
            pairs
        };
        const res = await fetch(`${DATA_SERVICE_URL}/latestTransactions`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });
        if (!res || res.status !== 200) return;
        const transactions = await res.json();
        transactions.map((transaction) => processLiveTransaction(transaction));
        setLiveTransactions(transactions);
        setLiveTransactionsInDb(transactions);
    };

    useEffect(async () => {
        await getTransactionHistory(activePair, true);
    }, [activePair]);

    useEffect(async () => {
        if (refreshTransactionList === null) return;
        await getTransactionHistory(activePair, false);
    }, [refreshTransactionList]);

    return (
        <div className="col-md-12 p-0">
            {/* <div>
        <Checkbox
          checked={filterByPair}
          onChange={filteringChanged}
          icon={<BsIcons.BsFillTerminalFill />}
          checkedIcon={<BsIcons.BsFillTerminalFill />}
          defaultChecked
          className="txnFilterCheckbox"
        />
        <span className="txnFilterLabel">
          Only show trades for selected pair
        </span>
      </div> */}

            {/* <div className="table-responsive txtable">
                <table className="table table-hover table-sm text-muted">
                    <thead className="tp-head botshadow">
                        <tr>
                            <th style={{ width: '10px' }} className="hideOnMobile" />
                            <th style={{ fontWeight: '100', fontSize: '14px' }}>
                                Traded{' '}
                                <Badge bg="darkgrey" style={{ color: 'var(--text-base)', fontWeight: '400', marginTop: '-1%' }}>
                                    {activePair.symbol0}
                                </Badge>
                            </th>
                            <th style={{ fontWeight: '100', fontSize: '14px' }}>
                                Value{' '}
                                <Badge bg="darkgrey" style={{ color: 'var(--text-base)', fontWeight: '400', marginTop: '-1%' }}>
                                    USD
                                </Badge>
                            </th>
                            <th style={{ fontWeight: '100', fontSize: '14px' }}>Price / Token </th>
                            <th style={{ fontWeight: '100', fontSize: '14px' }}>Time</th>
                            <th style={{ fontWeight: '100', fontSize: '14px' }}>More</th>
                        </tr>
                    </thead>
                    <tbody className="bg-darker">{renderBody()}</tbody>
                </table>
            </div> */}

            <div className="table-responsive txtable">
                <table className="table table-hover table-sm text-muted">
                    <thead className="tp-head sticky-top botshadow">
                        <tr>
                            <th style={{ width: '10px' }} className="hideOnMobile" />
                            <th style={{ fontWeight: '100', fontSize: '14px' }}>
                                Traded{' '}
                                <Badge bg="darkgrey" style={{ color: 'var(--text-base)', fontWeight: '400', marginTop: '-1%' }}>
                                    {activePair.symbol0}
                                </Badge>
                            </th>
                            <th style={{ fontWeight: '100', fontSize: '14px' }}>
                                Value{' '}
                                <Badge bg="darkgrey" style={{ color: 'var(--text-base)', fontWeight: '400', marginTop: '-1%' }}>
                                    USD
                                </Badge>
                            </th>
                            <th style={{ fontWeight: '100', fontSize: '14px' }}>Price / Token </th>
                            <th style={{ fontWeight: '100', fontSize: '14px' }}>Time</th>
                            <th style={{ fontWeight: '100', fontSize: '14px' }}>More</th>
                        </tr>
                    </thead>
                    <tbody className="bg-darker">{renderBody()}</tbody>
                </table>
            </div>
        </div>
    );
}
