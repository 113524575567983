import { Container, Row, Col } from 'react-bootstrap';
import { FaTelegramPlane, FaMediumM, FaTwitter } from 'react-icons/fa';

const Footer = () => (
    <Container
        fluid
        style={{
            backgroundColor: 'var(--background-base)',
            borderTop: '1px solid var(--color-border-grey)',
            padding: '10px',
            zIndex: '555',
            height: '36px'
        }}
        className="hideOnMobile"
    >
        <Row>
            <Col sm={12} md={6}>
                <p className="m-0 px-2 text-muted">
                    PokeDX 2024 © All rights reserved <span style={{ color: '#51555F' }}>Early beta. Charts are powered by TradingView.</span>
                </p>
            </Col>
            <Col sm={12} md={6} className="justify-content-end d-flex">
                <a href="https://www.pokedx.com/" target="blank" className="m-0 px-2 link">
                    About us
                </a>
                <a href="https://www.pokedx.com/#roadmap" target="blank" className="m-0 px-2 link">
                    Roadmap
                </a>
                <a href="https://pokedx.app/static/media/pokedx_whitepaper_v1.b2e0eaae.pdf" target="blank" className="m-0 px-2 link">
                    Whitepaper
                </a>
                <a href="https://www.pokedx.com/#token" target="blank" className="m-0 px-2 link">
                    Tokenomics
                </a>
                <a href="https://www.pokedx.com/#faq" target="blank" className="m-0 px-2 link">
                    FAQ
                </a>
                <a href="https://thomas587.typeform.com/to/dKQOyatB" target="blank" className="m-0 px-2 link">
                    Report bug
                </a>
                <span className="scl">
                    <a href="https://t.me/dextalks" target="_blank" rel="noreferrer">
                        <FaTelegramPlane size={18} color="var(--text-muted)" style={{ marginTop: '-8px', marginLeft: '10px' }} />
                    </a>
                    <a href="https://pokedx.medium.com/" target="_blank" rel="noreferrer">
                        <FaMediumM size={16} color="var(--text-muted)" style={{ marginTop: '-8px', marginLeft: '10px' }} />
                    </a>
                    <a href="https://twitter.com/pokedxapp/" target="_blank" rel="noreferrer">
                        <FaTwitter size={16} color="var(--text-muted)" style={{ marginTop: '-8px', marginLeft: '10px' }} />
                    </a>
                </span>
            </Col>
        </Row>
    </Container>
);

export default Footer;
