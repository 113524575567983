import { useStore } from './store';
import { PROXY_URL, DEX_TOKEN, BSC_GRAPH_URL, POKEDX_API_URL, TOKEN_INFO_URL } from '../shared/ApiURL';
import { BASE_TOKEN_ADDR } from '../shared/commonUtils';

export const Action = () => {
    const { dispatch } = useStore();
    return {
        setMetamaskStatus: (status) => {
            dispatch({
                type: 'SET_METAMASK_STATUS',
                metamaskStatus: status
            });
        },
        connectMetaMask: (flag) => {
            dispatch({
                type: 'CONNECT_METAMASK',
                metamaskConnected: flag
            });
        },
        setFromSearchToken: (token) => {
            dispatch({
                type: 'SET_FROM_TOKEN',
                token
            });
        },
        setToSarchToken: (token) => {
            dispatch({
                type: 'SET_TO_TOKEN',
                token
            });
        },
        setFromSearchTokenAddress: (address) => {
            dispatch({
                type: 'SET_FROM_TOKEN_ADDRESS',
                address
            });
        },
        setToSearchTokenAddress: (address) => {
            dispatch({
                type: 'SET_TO_TOKEN_ADDRESS',
                address
            });
        },
        setAddress: (newAddress) => {
            dispatch({
                type: 'NEW-ADDRESS',
                address: newAddress,
                message: 'New address added successfully!'
            });
        },
        setBalance: (newBalance) => {
            dispatch({
                type: 'SET_BALANCE',
                balance: newBalance
            });
        },
        setAllTokens: (tokens) => {
            dispatch({
                type: 'SET_TOKENS',
                allTokens: tokens
            });
        },
        getTokens: (newTokens) => {
            const token_data = [];
            fetch(DEX_TOKEN)
                .then((response) => response.json())
                .then((data) => {
                    token_data.push(data);
                });
        },
        getCryptoResponse: async (token, address) => {
            token = token.toUpperCase();
            await fetch(`${PROXY_URL}/fetchapi?endpoint=${token}`)
                .then((res) => res.json())
                .then((data) => {
                    if (data?.data[token]?.length > 0) {
                        let filterList;

                        if (data?.data[token]?.length > 1) {
                            filterList = data?.data[token]?.filter((obj) => {
                                if (obj.platform?.token_address === address) {
                                    return obj;
                                }
                                return null;
                            });
                        } else {
                            filterList = data?.data[token];
                        }
                        dispatch({
                            type: 'SET_CRYPTO_RESPONSE',
                            cryptoResponse: filterList[0],
                            imageId: token === 'PDX' ? null : filterList.id
                        });
                    } else {
                        dispatch({
                            type: 'SET_CRYPTO_RESPONSE',
                            cryptoResponse: null,
                            imageId: null
                        });
                    }
                })
                .catch((err) => console.error(err));
        },
        getDexTokenResponse: async (token) => {
            token = token.toUpperCase();
            await fetch(`${PROXY_URL}/fetchdexdata?token=${token}`)
                .then((res) => res.json())
                .then((data) => {
                    if (Object.keys(data).length > 0) {
                        dispatch({
                            type: 'SET_DEX_TOKEN_RESPONSE',
                            dexCryptoResponse: data
                        });
                    } else {
                        dispatch({
                            type: 'SET_DEX_TOKEN_RESPONSE',
                            dexCryptoResponse: null
                        });
                    }
                })
                .catch((err) => console.error(err));
        },
        getTokenTransactions: async (token) => {
            fetch(`${PROXY_URL}/fetchtransactions?token=${token}`)
                .then((res) => res.json())
                .then((res) => {
                    const transfers = res.data && res.data.length > 0 ? res.data : [];
                    if (transfers.length > 0) {
                        dispatch({
                            type: 'SET_TRANS_RESPONSE',
                            transactions: transfers
                        });
                    } else {
                        dispatch({
                            type: 'SET_TRANS_RESPONSE',
                            transactions: null
                        });
                    }
                })
                .catch(console.error);
        },
        getTokenTransactionsV2: async (query) => {
            const opts = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    query
                })
            };
            fetch(BSC_GRAPH_URL, opts)
                .then((res) => res.json())
                .then((res) => {
                    const transfers = res && res.data.swaps && res.data.swaps.length > 0 ? res.data.swaps : [];
                    if (transfers.length > 0) {
                        dispatch({
                            type: 'SET_TRANS_RESPONSEV2',
                            transactionsv2: transfers
                        });
                    } else {
                        dispatch({
                            type: 'SET_TRANS_RESPONSEV2',
                            transactionsv2: null
                        });
                    }
                })
                .catch(console.error);
        },
        getProductInfo: async (tokenAddress) => {
            const token = tokenAddress.toLowerCase();
            fetch(`${TOKEN_INFO_URL}${token}/info.json`)
                .then((res) => res.json())
                .then((res) => {
                    dispatch({
                        type: 'SET_PRODUCT_INFO_RESPONSE',
                        productInfoResponse: res
                    });
                })
                .catch(
                    dispatch({
                        type: 'SET_PRODUCT_INFO_RESPONSE',
                        productInfoResponse: null
                    })
                );
        },
        setToken: (token) => {
            dispatch({
                type: 'SET_TOKEN',
                token
            });
        },
        setTokenAddress: (tokenAddress) => {
            dispatch({
                type: 'SET_TOKEN_ADDRESS',
                tokenAddress
            });
        },
        setActivePair: (activePair) => {
            dispatch({
                type: 'SET_ACTIVE_PAIR',
                activePair
            });
        },
        setListPairs: (listPairs) => {
            dispatch({
                type: 'SET_LIST_PAIRS',
                listPairs
            });
        },
        setLiveTransactions: (liveTransactions) => {
            dispatch({
                type: 'SET_LIVE_TRANSACTIONS',
                liveTransactions
            });
        },
        setTrade: (trade) => {
            dispatch({
                type: 'SET_TRADE',
                trade
            });
        },
        setTradeLeft: (tradeleft) => {
            dispatch({
                type: 'SET_TRADE_LEFT',
                tradeleft
            });
        },
        setMarketCap: (data) => {
            dispatch({
                type: 'SET_MARKET_CAP',
                marketcap: data.marketcap,
                csupply: data.csupply
            });
        },
        setBnBPrice: (value) => {
            dispatch({
                type: 'SET_BNB_PRICE',
                bnbToUSDPrice: value
            });
        },
        setBuyFee: (value) => {
            dispatch({
                type: 'SET_BUY_FEE',
                buyFee: value
            });
        },
        setSellFee: (value) => {
            dispatch({
                type: 'SET_SELL_FEE',
                sellFee: value
            });
        },
        getLpInfo: (query) => {
            const opts = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'User-Agent': 'sfsfs'
                },
                body: JSON.stringify({
                    query
                })
            };
            fetch(`${PROXY_URL}/fetchlpinfo`, opts)
                .then((res) => res.json())
                .then((res) => {
                    const pairsData = res?.data.pairs && res?.data.pairs.length > 0 ? res?.data.pairs[0] : null;
                    if (pairsData) {
                        dispatch({
                            type: 'SET_LP_INFO',
                            lpResponse: pairsData
                        });
                    } else {
                        dispatch({
                            type: 'SET_LP_INFO',
                            lpResponse: null
                        });
                    }
                })
                .catch(console.error);
        },
        setTvChart: (flag) => {
            dispatch({
                type: 'SET_TV_CHART',
                tvChart: flag
            });
        },
        getTokenPriceDetails: async (tokens) => {
            for (const obj of tokens) {
                const response = await fetch(`${PROXY_URL}/fetchapi?endpoint=${obj.symbol}`)
                    .then((res) => res.json())
                    .then((res) => {
                        if (res) {
                            obj.percentchange = res.data[obj.symbol].quote.USD.percent_change_24h.toFixed(2);
                            obj.id = res.data[obj.symbol].id;
                            obj.price = res.data[obj.symbol].quote.USD.price.toFixed(2);
                            obj.name = res.data[obj.symbol].name;
                        }
                    })
                    .catch((err) => console.error(err));
            }
            return tokens;
        },
        setWatchList: (watchList) => {
            dispatch({
                type: 'SET_WATCHLIST',
                watchList
            });
        },
        getPokedxPriceInUSD: async () => {
            await fetch(`${PROXY_URL}/token-price?token=${BASE_TOKEN_ADDR}`)
                .then((res) => res.json())
                .then((res) => {
                    if (res) {
                        dispatch({
                            type: 'SET_BASETOKEN_PRICE',
                            basetokenprice: res.usdPrice ? res.usdPrice : 0
                        });
                    }
                })
                .catch((err) => console.error(err));
        },
        getWalletTransactions: async (address) => {
            fetch(`${PROXY_URL}/fetchtransactions?token=${address}&isWallet=true`)
                .then((res) => res.json())
                .then((res) => {
                    const transfers = res.data && res.data.length > 0 ? res.data : [];
                    if (transfers.length > 0) {
                        dispatch({
                            type: 'SET_WALLET_TRANSACTIONS',
                            transactions: transfers
                        });
                    } else {
                        dispatch({
                            type: 'SET_WALLET_TRANSACTIONS',
                            transactions: null
                        });
                    }
                })
                .catch(console.error);
        },
        setReloadChart: (flag) => {
            dispatch({
                type: 'SET_RELOAD_CHART',
                reloadChart: flag
            });
        },
        setLp: (lp) => {
            dispatch({
                type: 'SET_LP',
                lp
            });
        },
        setTokenPrice: (tokenprice) => {
            dispatch({
                type: 'SET_TOKEN_PRICE',
                tokenprice
            });
        },
        setFilterByPair: (filterByPair) => {
            dispatch({
                type: 'SET_FILTER_BY_PAIR',
                filterByPair
            });
        },
        getWalletTokenData: async (address) => {
            fetch(`${POKEDX_API_URL}?address=${address}&action=graph`)
                .then((res) => res.json())
                .then((res) => {
                    const data = res && res.length > 0 ? res : [];
                    if (data.length > 0) {
                        dispatch({
                            type: 'SET_WALLET_TOKEN_DATA',
                            walletTokenData: data
                        });
                    } else {
                        dispatch({
                            type: 'SET_WALLET_TOKEN_DATA',
                            walletTokenData: null
                        });
                    }
                })
                .catch(console.error);
        },
        setLayout: (type) => {
            dispatch({
                type: 'SET_LAYOUT',
                layout: type
            });
        },
        setTrackWallet: (updateList) => {
            dispatch({
                type: 'SET_TRACK_WALLET',
                trackWallets: updateList
            });
        },
        setRefreshTransactionList: (refreshTransactionList) => {
            dispatch({
                type: 'SET_REFRESH_TX_LIST',
                refreshTransactionList
            });
        },
        setWalletObject: (walletType, walletObj) => {
            dispatch({
                type: 'WALLET_OBJ',
                walletType,
                walletObj
            });
        }
    };
};
