import {
    Button,
    Modal,
    Container,
    Row,
    Col,
    Form
  } from "react-bootstrap";
  import { useEffect, useState } from "react";
  import { useStoreApi } from "../../store/storeApi";
  import {
      addTokenToMoralis,
    DisplayErrorMsg, getDecimals,
  } from "../../shared/commonUtils";
  
  export default function SearchToken(props) {
    const {
      
    } = useStoreApi();
    const [tokenData, setTokenData] = useState(null);
    useEffect(() => {
        setTokenData(null);
    }, [props.show]);

    const importToken = () => {
        addTokenToMoralis(tokenData, 1, 0);
        props.hide();
    };

    const searchForToken = async (e) => {
        const str = e.target.value;
        let pattern = /0x/i;
        if(str.match(pattern) && str.length == '42') {
            const apiResponse = await getDecimals(str, true);
            console.log('apiResponse===>', apiResponse);
            if(apiResponse) {
                setTokenData(apiResponse);
            }
        }
    }
  
    return (
      <Modal
        {...props}
        size="lg"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        className="tokeninfomodal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="whiteheading ml-3">Import Token</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="d-flex flex-row align-items-center">
              <Col sm={11} className="d-flex flex-row">
                <Form.Control type="address" onKeyUp={(e)=> searchForToken(e)} placeholder="Enter Token Address" />
              </Col>
            </Row>
            { tokenData ?
            <div>
                <Row>
                <Col sm={12} className="mt-4">
                    <span>{tokenData.symbol} - {tokenData.name}</span>
                </Col>
                </Row>
                <Row>
                <Col sm={12} className="mt-4">
                    <Button variant="primary" onClick={() => importToken()}>
                        Import Token
                    </Button>
                </Col>
                </Row>
            </div>
            : null}
          </Container>
        </Modal.Body>
      </Modal>
    );
  }
  