import React from 'react';

function Ramp() {
    return (
        <div className="container pt-5">
            <div className="row">
                <div className="justify-content-center" style={{ height: '100vh' }}>
                    <iframe
                        id="onramper-widget"
                        style={{ borderRadius: '12px' }}
                        src="https://widget.onramper.com/?color=266678&apiKey=pk_prod_5U2eAjhpqL501Am3tbNLc0PD8pt_0VMrLzVLLNcSdmI0&defaultCrypto=BNB_BEP20"
                    ></iframe>
                </div>
            </div>
        </div>
    );
}

export default Ramp;
