import React from 'react';

function About() {
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-around">
                <div className="col text-center border rounded p-3 mx-2">
                    <p className="h5">Tokens Held</p>
                    <span className="h4">3</span>
                </div>
                <div className="col text-center border rounded p-3 mx-2">
                    <p className="h5">Performance (24h)</p>
                    <span className="h4">15.32 %</span>
                </div>
                <div className="col text-center border rounded p-3 mx-2">
                    <p className="h5">PDX held</p>
                    <span className="h4">184,000 PDX</span>
                </div>
            </div>

            <div className="border rounded my-3 mx-2 p-2">
                <center>
                    <p className="">Chart coming soon</p>
                </center>
            </div>

            <div className=" rounded mb-3 mx-2">
            <table className="table table-dark">
                <thead className="thead-dark">
                    <tr>
                    <th scope="col">Token</th>
                    <th scope="col">Tokens held</th>
                    <th scope="col">USD value</th>
                    <th scope="col">USD value</th>
                    <th scope="col">G/L</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <th scope="row">Cake</th>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                    </tr>
                    <tr>
                    <th scope="row">PokeDX</th>
                    <td>Jacob</td>
                    <td>Thornton</td>
                    <td>Otto</td>
                    <td>@fat</td>
                    </tr>
                    <tr>
                    <th scope="row">PDX</th>
                    <td>Larry</td>
                    <td>the Bird</td>
                    <td>Otto</td>
                    <td>@twitter</td>
                    </tr>
                </tbody>
            </table>
            </div>

        </div>
    )
}

export default About
