import React, { useReducer, useContext, createContext } from 'react';
import { Reducer } from './reducer';
import { NULL_LP } from '../shared/constants';

const StoreContext = createContext();
window.process = { ...window.process };
const initialState = {
    message: '',
    balance: 0,
    address: null,
    cryptoResponse: null,
    dexCryptoResponse: null,
    imageId: null,
    token: 'PDX',
    tokenAddress: null,
    activePair: NULL_LP,
    listPairs: [],
    liveTransactions: [],
    transactions: null,
    productInfoResponse: null,
    trade: true,
    allTokens: null,
    fromSearchToken: 'BNB',
    toSearchToken: 'PDX',
    toSearchTokenAddress: '0x43a0c5eb1763a211aa3c05849a617f2ee0452767',
    fromSearchTokenAddress: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    lpResponse: null,
    transactionsv2: null,
    metamaskConnected: false,
    metamaskStatus: null,
    marketcap: 0,
    csupply: 0,
    tvChart: true,
    tradeleft: false,
    watchList: null,
    basetokenprice: 0,
    walletTransactions: null,
    reloadChart: false,
    lp: {},
    tokenprice: 0,
    filterByPair: false,
    walletTokenData: null,
    bnbToUSDPrice: 0,
    layout: 'left',
    trackWallets: null,
    refreshTransactionList: null,
    walletType: null,
    walletObj: null
};

export const StoreProvider = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState);

    return <StoreContext.Provider value={{ state, dispatch }}>{children}</StoreContext.Provider>;
};

export const useStore = () => useContext(StoreContext);
