import React from 'react';
import { Container } from 'react-bootstrap';
import { useBalance } from 'wagmi';
import './index.scss';
import Trending from './Trending';

const Title = ({ title }) => <h1 className="text-center p-5 discover-heading">{title}</h1>;

function Discover() {
    return (
        <Container className="discover-wrapper" fluid>
            <Title title="This is the start of something beautiful" />
            <Container>
                <Trending />
            </Container>
        </Container>
    );
}

export default Discover;
