import { DEX_GURU_URL, DATA_SERVICE_URL } from '../../../shared/ApiURL';
import { getActivePairFromDB } from '../../../shared/commonUtils';

const request = require('request-promise').defaults({ json: true });

export default {
    async getBars(from, to, interval) {
        try {
            const activePair = getActivePairFromDB();
            if (activePair == null || activePair.dex === 'nullswap') return [];
            const hourInterval = interval >= 60 ? interval / 60 : 1;
            const busdRes = await fetch(
                `${DATA_SERVICE_URL}/busdcandles?startDate=${from * 1000}&endDate=${to * 1000}&interval=${hourInterval}`
            ).then((data) => data.json());

            const candleRes = await fetch(
                `${DATA_SERVICE_URL}/candles?baseToken=${activePair.token0}&quoteToken=${activePair.token1}&pairAddress=${
                    activePair.pair.address
                }&dex=${activePair.dex}&startDate=${from * 1000}&endDate=${to * 1000}&interval=${interval}`
            ).then((data) => data.json());
            //  console.log('result of json', res.json());
            return {
                busdRes,
                candleRes
            };
        } catch (e) {
            return {
                busdRes: [],
                candleRes: []
            };
        }
    },

    async resolveSymboll(symbolInfo) {
        const data = await request({
            url: `${DEX_GURU_URL}/tokens/${symbolInfo[0]}?network=${symbolInfo[1]}`
        });
        return data;
    }
};
