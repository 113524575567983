// window.process = {}
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { WagmiConfig } from 'wagmi';

import { ToastContainer } from 'react-toastify';
import { MoralisProvider } from 'react-moralis';
import { client, chains, provider } from './utils/Wagmi/index';

import App from './App';
import { StoreProvider } from './store/store';
import 'react-toastify/dist/ReactToastify.css';
import { MORALIS_APP_ID, MORALIS_SERVER_URL } from './shared/ApiURL';

const moralisAppId = MORALIS_APP_ID;
const moralisServerURL = MORALIS_SERVER_URL;

ReactDOM.render(
    <>
        <WagmiConfig client={client}>
            <MoralisProvider appId={moralisAppId} serverUrl={moralisServerURL}>
                <StoreProvider>
                    <ToastContainer autoClose={3000} hideProgressBar toastClassName="toastMain" />
                    <App />
                </StoreProvider>
            </MoralisProvider>
        </WagmiConfig>
    </>,
    document.getElementById('root')
);
