import React, { useState } from 'react';
import * as HiIcons from 'react-icons/hi';
import { priceDecimal, toFixedDecimal } from '../../shared/commonUtils';
import { useStoreApi } from '../../store/storeApi';

export default function PriceCalculator() {
    const { token, tokenprice } = useStoreApi();
    const [quantity, setQuantity] = useState(0);
    const [usdPrice, setUsdPrice] = useState(0);

    const onQuantityChange = (e) => {
        const currentQty = e.target.value;
        if (currentQty > 0) {
            const usdPriceOfQty = currentQty * tokenprice;
            console.log('usdPriceOfQty', usdPriceOfQty);
            setUsdPrice(usdPriceOfQty);
        }
    };

    return (
        <div
            className="ml-0 p-3 priceCalcMobile"
            style={{
                backgroundColor: 'var(--background-light)',
                boxShadow: 'none',
                border: '0px solid rgb(41, 45, 64)',
                borderRadius: '12px'
                // marginBottom: '12px'
            }}
        >
            {/* <div className="mb-3 text-muted h6">
                <strong>
                    Conversion tool
                </strong>
            </div> */}
            {/* <h6 className="mb-3" style={{ color: 'var(--text-base)', fontWeight: '100' }}>
                Convert {token} to USD
            </h6> */}
            <div className="row d-flex align-items-center">
                <div className="col-3">
                    <span style={{ color: 'var(--text-primary)' }}>{token}</span>
                </div>
                <div className="col-9">
                    <input
                        type="text"
                        id="token"
                        placeholder="0"
                        className="cinput"
                        onChange={(e) => {
                            setQuantity(e.target.value);
                        }}
                        onBlur={(e) => onQuantityChange(e)}
                    />
                </div>
            </div>
            {/* <center><HiIcons.HiOutlineSwitchVertical size="18"/></center> */}
            <div className="row mt-2 d-flex align-items-center">
                <div className="col-3">
                    <span style={{ color: 'var(--text-primary)' }}>USD ($)</span>
                </div>
                <div className="col-9" style={{ cursor: 'not-allowed' }}>
                    <input
                        style={{ cursor: 'not-allowed' }}
                        type="text"
                        className="cinput"
                        disabled
                        id="usdvalue"
                        value={usdPrice.toFixed(priceDecimal(usdPrice))}
                    />
                </div>
            </div>
        </div>
    );
}
