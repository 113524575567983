import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useStoreApi } from '../../store/storeApi';
import IMG_LOGO2 from '../../assets/img/PDX.png';
import PLACEHOLDER from '../../assets/img/placeholder.svg';
import { getWatchList, updateWatchList } from '../../shared/commonUtils';
import { CDN_URL } from '../../shared/ApiURL';

export default function LeftSection() {
    const { metamaskConnected, metamaskStatus, getTokenPriceDetails, watchList } = useStoreApi();
    const [updatedList, setUpdatedList] = useState();
    useEffect(() => {
        console.log('watchList==>', watchList);
        getWatchListTokensDetails();
    }, [watchList]);

    const getWatchListTokensDetails = async () => {
        const watchListTokens = getWatchList();
        setUpdatedList(watchListTokens);
        if (watchListTokens || [].length > 0) {
            const result = await getTokenPriceDetails(watchListTokens);
            setUpdatedList(result);
        }
    };

    const getHistorySpanClass = (value) => {
        if (value) {
            return Math.sign(value) == 1 ? 'historyspan cbuy' : 'historyspan csell';
        }
        return '';
    };

    const renderBody = () => {
        if (metamaskStatus != 'CONNECTED') {
            return (
                <tr>
                    <td className="warn-msg" colSpan="3">
                        Please connect to use this feature
                    </td>
                </tr>
            );
        }
        return (
            updatedList &&
            updatedList.map((item, index) => {
                const path = `/token/${item.address}/${item.symbol}`;
                console.log('metamaskConnected', metamaskStatus, metamaskConnected);
                return (
                    <tr key={index} className="">
                        <td className="pl-1">
                            <img
                                src={`${CDN_URL}/${item.address.toUpperCase()}.png`}
                                // onError={(e) => {e.target.src = CDN_URL+ '/placeholder.svg'; e.target.onError = null;}}
                                height="25"
                                width="25"
                                alt="logo"
                                className="watchlistlogo"
                            />
                        </td>
                        <td className="text-white">
                            <Link className="text-white" to={path} style={{ textDecoration: 'none' }}>
                                {item.symbol}
                            </Link>
                        </td>
                        <td className={getHistorySpanClass(item.percentchange)}>
                            {item.percentchange != '-' ? `${item.percentchange}%` : '-'}
                        </td>
                    </tr>
                );
            })
        );
    };
    return (
        <div className="col-xl-2 col-sm-2 mt-0 bg-darker-trade p-3 leftsidebar">
            <h6 className="wlheading pb-1">Watchlist</h6>
            <Table hover size="sm">
                <tbody>{renderBody()}</tbody>
            </Table>
        </div>
    );
}
