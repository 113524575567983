import TvChart from './Tvchart';
import { useStoreApi } from '../../store/storeApi';
import CryptoOverview from './CryptoOverview';

function MiddleSection(props) {
    const { tokenAddress, trade, tradeleft, activePair, reloadChart } = useStoreApi();

    let divClass = 'col-8 pl-0 graph';
    if (trade && tradeleft) {
        // both
        divClass = 'col-7 pl-0 pr-0 graph';
    } else if (trade) {
        divClass = 'col-8 pl-0 pr-0 graph';
    } else if (tradeleft) {
        divClass = 'col-10 pl-0 pr-0 graph';
    } else {
        divClass = 'col-12 pl-0 pr-0 graph';
    }
    return (
        <div className={divClass} style={{ backgroundColor: 'var(--color-border-grey)' }}>
            <TvChart data={props.data} />
            <CryptoOverview />
        </div>
    );
}

export default MiddleSection;
