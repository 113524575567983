import React, { useEffect, useState } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { DATA_SERVICE_URL } from '../shared/ApiURL';

function NewsWidget() {
    const [cryptoNews, setCryptoNews] = useState();
    const [cancel, setCancel] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const getCryptoNews = async () => {
        if (cancel) return;
        try {
            const result = await fetch(`${DATA_SERVICE_URL}/news-feed`);
            const data = await result.json();
            if (data?.items?.length > 0) {
                setCryptoNews(data);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    };

    useEffect(() => {
        getCryptoNews();
        return () => {
            setCancel(true);
        };
    }, []);

    const newsRenderer = cryptoNews?.items.slice(0, 24).map((item) => {
        const difference = Date.now() - item.created;
        const timeSince = Math.floor(difference / 1000 / 60 / 60); // hours since post was created

        return (
            <Card
                key={item.created}
                onClick={() => {
                    // window.location.href = item.link;
                    window.open(item.link, '_blank', 'noopener');
                }}
                className="newsItem pb-2"
                style={{
                    width: '100%',
                    backgroundColor: 'var(--background-base)',
                    boxShadow: 'none',
                    border: '0px solid var(--color-border-grey)',
                    cursor: 'pointer'
                }}
            >
                <Card.Body className="mb-0 pb-0">
                    <Card.Subtitle className="news-subtitle pb-1" style={{ fontSize: '13px' }}>
                        {timeSince} hours ago · {item.category}
                    </Card.Subtitle>
                    <Card.Title className="news-title mt-1">{item.title}</Card.Title>
                </Card.Body>
            </Card>
        );
    });

    const news = isLoading ? <Spinner /> : <div>{newsRenderer}</div>;

    return (
        <div>
            <div style={{ maxHeight: '260px', overflow: 'scroll', height: '100%', margin: '4px', borderRadius: '7px' }}>{news}</div>
        </div>
    );
}

export default NewsWidget;
