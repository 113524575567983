import { Button, Modal, Container, Row, Col, Table, DropdownButton, Dropdown, Badge, ListGroup, Accordion, Card } from 'react-bootstrap';
import * as BiIcons from 'react-icons/bi';
import { useEffect, useState } from 'react';
import { useStoreApi } from '../../store/storeApi';
import IMG_LOGO2 from '../../assets/img/PDX.png';
import { addToWishlist, getWatchList, removeToWishlist, numberWithCommas, DisplayErrorMsg, getToFixed } from '../../shared/commonUtils';
import ProjectDescription from '../ProjectDescription';
import MonthPerformance from './MonthPerformance';
import { PROXY_URL, BSC_URL, STEMP_URL, DATA_SERVICE_URL, CDN_URL } from '../../shared/ApiURL';

export default function TokenProfile() {
    const {
        imageId,
        metamaskStatus,
        tokenAddress,
        cryptoResponse,
        activePair,
        marketcap,
        csupply,
        lp,
        lpResponse,
        productInfoResponse,
        token
    } = useStoreApi();

    const callCustomLink = (type) => {
        // console.log('productInfoResponse', productInfoResponse);
        let link = '';
        if (type === 'contract') {
            link = `${BSC_URL}address/${tokenAddress}`;
        } else if (type === 'holders') {
            link = `${BSC_URL}token/${tokenAddress}#balances`;
        } else if (type === 'tx') {
            link = `${BSC_URL}token/${tokenAddress}`;
        } else if (type === 'website') {
            link = productInfoResponse && productInfoResponse[type] ? productInfoResponse[type] : '';
        } else if (type != '') {
            if (productInfoResponse?.links) {
                const res = productInfoResponse.links.filter((obj) => obj.name == type);
                console.log('res===>', res);
                link = res[0]?.url || '';
            }
        }
        window.open(link, '_blank', 'noopener');
    };

    const checkEmpty = (tag) => {
        if (!productInfoResponse) return false;
        if (productInfoResponse?.links) return productInfoResponse.links.filter((obj) => obj.name == tag).length;
        return false;
    };

    return (
        <Col className="m-0 p-0">
            <Row className="d-flex flex-row">
                <h6 className="mt-1 text-muted">About</h6>
                <ProjectDescription />
            </Row>
            <Col className="p-0 mt-3 mb-2">
                <h6 className="pb-1 text-muted">Project links</h6>
                <div>
                    {/* These 3 badges should always be visible */}
                    <Badge
                        onClick={() => callCustomLink('contract')}
                        bg="primary"
                        style={{
                            fontSize: '13px',
                            fontWeight: '400',
                            borderRadius: '8px',
                            color: 'var(--text-base)',
                            cursor: 'pointer',
                            marginBottom: '10px'
                        }}
                        className="mr-2 interactivehover"
                    >
                        Contract{' '}
                        {/* <BiIcons.BiLinkExternal
                            style={{ color: 'var(--text-muted)', marginTop: '-9px', marginLeft: '2px', marginRight: '-2px' }}
                        /> */}
                    </Badge>
                    <Badge
                        onClick={() => callCustomLink('holders')}
                        bg="primary"
                        style={{
                            fontSize: '13px',
                            fontWeight: '400',
                            borderRadius: '8px',
                            color: 'var(--text-base)',
                            cursor: 'pointer'
                        }}
                        className="mr-2 interactivehover linkbadge"
                    >
                        Holders{' '}
                        {/* <BiIcons.BiLinkExternal
                            style={{ color: 'var(--text-muted)', marginTop: '-9px', marginLeft: '2px', marginRight: '-2px' }}
                        /> */}
                    </Badge>
                    <Badge
                        onClick={() => callCustomLink('tx')}
                        bg="primary"
                        style={{
                            fontSize: '13px',
                            fontWeight: '400',
                            borderRadius: '8px',
                            color: 'var(--text-base)',
                            cursor: 'pointer'
                        }}
                        className="mr-2 interactivehover"
                    >
                        Transactions{' '}
                        {/* <BiIcons.BiLinkExternal
                            style={{ color: 'var(--text-muted)', marginTop: '-9px', marginLeft: '2px', marginRight: '-2px' }}
                        /> */}
                    </Badge>
                    {checkEmpty('whitepaper') ? (
                        <Badge
                            onClick={() => callCustomLink('whitepaper')}
                            bg="primary"
                            style={{
                                fontSize: '13px',
                                fontWeight: '400',
                                borderRadius: '8px',
                                color: 'var(--text-base)',
                                cursor: 'pointer',
                                marginBottom: '10px'
                            }}
                            className="mr-2 interactivehover"
                        >
                            Whitepaper{' '}
                            {/* <BiIcons.BiLinkExternal
                                style={{ color: 'var(--text-muted)', marginTop: '-9px', marginLeft: '2px', marginRight: '-2px' }}
                            /> */}
                        </Badge>
                    ) : null}
                    {checkEmpty('website') != '' ? (
                        <Badge
                            onClick={() => callCustomLink('website')}
                            bg="primary"
                            style={{
                                fontSize: '13px',
                                fontWeight: '400',
                                borderRadius: '8px',
                                color: 'var(--text-base)',
                                cursor: 'pointer'
                            }}
                            className="mr-2 interactivehover"
                        >
                            Website{' '}
                            {/* <BiIcons.BiLinkExternal
                                style={{ color: 'var(--text-muted)', marginTop: '-9px', marginLeft: '2px', marginRight: '-2px' }}
                            /> */}
                        </Badge>
                    ) : null}
                    {checkEmpty('telegram') ? (
                        <Badge
                            onClick={() => callCustomLink('telegram')}
                            bg="primary"
                            style={{
                                fontSize: '13px',
                                fontWeight: '400',
                                borderRadius: '8px',
                                color: 'var(--text-base)',
                                cursor: 'pointer'
                            }}
                            className="mr-2 interactivehover"
                        >
                            Telegram{' '}
                            {/* <BiIcons.BiLinkExternal
                                style={{ color: 'var(--text-muted)', marginTop: '-9px', marginLeft: '2px', marginRight: '-2px' }}
                            /> */}
                        </Badge>
                    ) : null}
                    {checkEmpty('twitter') ? (
                        <Badge
                            onClick={() => callCustomLink('twitter')}
                            bg="primary"
                            style={{
                                fontSize: '13px',
                                fontWeight: '400',
                                borderRadius: '8px',
                                color: 'var(--text-base)',
                                cursor: 'pointer',
                                marginBottom: '10px'
                            }}
                            className="mr-2 interactivehover"
                        >
                            Twitter{' '}
                            {/* <BiIcons.BiLinkExternal
                                style={{ color: 'var(--text-muted)', marginTop: '-9px', marginLeft: '2px', marginRight: '-2px' }}
                            /> */}
                        </Badge>
                    ) : null}
                    {checkEmpty('instagram') ? (
                        <Badge
                            onClick={() => callCustomLink('instagram')}
                            bg="primary"
                            style={{
                                fontSize: '13px',
                                fontWeight: '400',
                                borderRadius: '8px',
                                color: 'var(--text-base)',
                                cursor: 'pointer'
                            }}
                            className="mr-2 interactivehover"
                        >
                            Instagram{' '}
                            {/* <BiIcons.BiLinkExternal
                                style={{ color: 'var(--text-muted)', marginTop: '-9px', marginLeft: '2px', marginRight: '-2px' }}
                            /> */}
                        </Badge>
                    ) : null}
                    {checkEmpty('reddit') ? (
                        <Badge
                            onClick={() => callCustomLink('reddit')}
                            bg="primary"
                            style={{
                                fontSize: '13px',
                                fontWeight: '400',
                                borderRadius: '8px',
                                color: 'var(--text-base)',
                                cursor: 'pointer'
                            }}
                            className="mr-2 interactivehover"
                        >
                            Reddit{' '}
                            {/* <BiIcons.BiLinkExternal
                                style={{ color: 'var(--text-muted)', marginTop: '-9px', marginLeft: '2px', marginRight: '-2px' }}
                            /> */}
                        </Badge>
                    ) : null}
                </div>
            </Col>
        </Col>
    );
}
