import React from 'react'
import './index.scss'
import {Table} from 'react-bootstrap'

function Trending() {
    return (
        <Table  hover variant="dark-standard">
            <thead className='dark-standard-thead'>
                <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Price</th>
                    <th>24h</th>
                    <th>7d</th>
                    <th>Market cap</th>
                    <th>Volume (24h)</th>
                    <th>Last 7 days</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1</td>
                    <td>PDX</td>
                    <td className="cbuy">$0.0075</td>
                    <td className="cbuy">24%</td>
                    <td className="cbuy">30%</td>
                    <td>$420,000</td>
                    <td>$4,200</td>
                    <td></td>
                </tr>

            </tbody>
        </Table>
  )
}

export default Trending