import { Modal, Row, ListGroup, Image } from 'react-bootstrap';
import { BiSearch } from 'react-icons/bi';
import { IconContext } from 'react-icons';
import AutoComplete from '../../AutoCompleateTextBox/AutoComplete';
import { getFavToken } from '../../../shared/commonUtils';
import BNB_ICON from '../../../assets/icons/BinanceCoin.svg';
import BUSD_ICON from '../../../assets/icons/BinanceUSD.svg';
import USDT_ICON from '../../../assets/icons/USDT.png';
import PDX_ICON from '../../../assets/icons/PDX_icon.png';

function SearchTokenPopup(props) {
    const selectFavToken = (type) => {
        const token = getFavToken(type);
        props.selectToken(token);
    };
    return (
        <Modal {...props} animation={false} size="sm" centered>
            <Modal.Header style={{ backgroundColor: '' }}>
                <Modal.Title style={{ fontSize: '16px', color: 'var(--text-muted)', fontWeight: 'normal' }}>Select token</Modal.Title>
            </Modal.Header>
            <Modal.Body className="tokensearch-modal pt-0">
                <IconContext.Provider value={{ className: 'searchicon-token-bar' }}>
                    <BiSearch size={18} style={{ marginLeft: '-7px', marginBottom: '-40px' }} />
                </IconContext.Provider>
                <AutoComplete
                    suggestions={props.tokenResponse}
                    placeholderText="Search by token name or symbol..."
                    requests={props.requests}
                    classProp="dex-search-bar"
                    setToken={props.selectToken}
                />

                <Row className="m-0 p-0 mt-3">
                    <ListGroup style={{ backgroundColor: '#303044', borderRadius: '7px', marginTop: '2px', paddingRight: '0' }} flush>
                        <ListGroup.Item onClick={() => selectFavToken('PDX')} style={{ cursor: 'pointer' }}>
                            <Image src={PDX_ICON} width="20px" />
                            PokeDX (PDX)
                        </ListGroup.Item>
                        <ListGroup.Item onClick={() => selectFavToken('BNB')} style={{ cursor: 'pointer' }}>
                            <Image src={BNB_ICON} width="20px" /> Binance Coin (BNB)
                        </ListGroup.Item>
                        <ListGroup.Item onClick={() => selectFavToken('USDT')} style={{ cursor: 'pointer' }}>
                            <Image src={USDT_ICON} width="20px" /> Tether (USDT)
                        </ListGroup.Item>
                        <ListGroup.Item onClick={() => selectFavToken('BUSD')} style={{ cursor: 'pointer' }}>
                            <Image src={BUSD_ICON} width="20px" /> Binance-Peg BUSD (BUSD)
                        </ListGroup.Item>
                    </ListGroup>
                </Row>
            </Modal.Body>
        </Modal>
    );
}

export default SearchTokenPopup;
