import provider from './Provider';
import { drawSpread } from '../../../shared/charting';
import { element } from 'prop-types';

const configurationData = {
    // supported_resolutions: ['1', '5', '10', '30', '60', '240', '720', '1D']
    supported_resolutions: ['1D']
};

export default {
    // This method is used by the Charting Library to get a configuration of your datafeed
    // (e.g. supported resolutions, exchanges and so on)
    onReady: (callback) => {
        // console.log('[onReady]: Method called!!', { configurationData });
        setTimeout(() => callback(configurationData));
    },
    // This method is used by the library to retrieve information about a specific symbol
    // (exchange, price scale, full symbol etc.).
    resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
        const info = symbolName.split('-');
        // console.log('PK ---------------------', info);

        const symbol_stub = {
            currency_code: 'USD',
            data_status: 'pulsed',
            description: '',
            exchange: 'PokeDX.app',
            // force_session_rebuild: true,
            format: 'price',
            full_name: info[0],
            has_daily: true,
            has_intraday: true,
            hide_empty_bars: true,
            id: info[0],
            listed_exchange: '',
            minmov: 1,
            name: '',
            pricescale: 1000000000,
            session: '24x7',
            supported_resolutions: configurationData.supported_resolutions,
            ticker: symbolName,
            timezone: 'Etc/UTC',

            type: 'crypto',
            volume_precision: 12
        };

        provider
            .resolveSymboll(info)
            .then((result) => {
                symbol_stub.description = result.description;
                symbol_stub.name = result.name;
            })
            .catch((err) => {
                // console.log({err})
                onResolveErrorCallback(err);
            });

        setTimeout(() => {
            onSymbolResolvedCallback(symbol_stub);
            // console.log('Resolving that symbol....', symbol_stub)
        }, 0);
    },
    // This method is used by the charting library to get historical data for the symbol.
    getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
        const multiplier = resolution.includes('W') ? 10080 : resolution.includes('D') ? 1440 : 1;
        const interval = parseInt(resolution) * multiplier;
        let { busdRes, candleRes } = await provider.getBars(periodParams.from, periodParams.to, interval);
        // console.log(data);
        const data = candleRes?.map((obj) => {
            if (busdRes[obj.time]) {
                // console.log('here inside');
                obj.open = obj.open * busdRes[obj.time].open;
                obj.high = obj.high * busdRes[obj.time].open;
                obj.low = obj.low * busdRes[obj.time].close;
                obj.close = obj.close * busdRes[obj.time].close;
            }
            return obj;
        });
        if (data !== null && data.length > 0) {
            onHistoryCallback(data, { noData: false });
            window.tvWidget.latestClose = data[data.length - 1].close;
        } else {
            onHistoryCallback([], { noData: true });
        }
    },

    subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscribeID, onResetCacheNeededCallback) => {
        window.onRealtimeCallback = onRealtimeCallback;
    },
    unsubscribeBars: (subscribeID) => {}
};
