import React, { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { useAccount, useBalance } from 'wagmi';
import { FiArrowDownRight, FiArrowUpRight } from 'react-icons/fi';
import { Col, Container, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { BSC_URL, DATA_SERVICE_URL } from '../../shared/ApiURL';
import { useStoreApi } from '../../store/storeApi';
import BSCSCAN_LOGO from '../../assets/img/BscScan-logo-light-circle.png';
import { numberWithCommas, getEthInstance, iso8601ToDateString } from '../../shared/commonUtils';

const url = `${DATA_SERVICE_URL}/wallet-transactions?`;

function TableBody() {
    const { address, isConnected } = useAccount();
    const { activePair } = useStoreApi();
    const fetcher = (...args) =>
        fetch(url, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                taker: `${address}`,
                baseCurrency: `${activePair?.token0}`,
                quoteCurrency: `${activePair?.token1}`
            })
        }).then((res) => res.json());
    const { data, error } = useSWR(activePair?.token0 && isConnected ? url : null, fetcher, {
        refreshInterval: 0,
        revalidateOnFocus: false
    });
    if (error) return `${error}`;
    if (!data) return 'loading...';
    if (data == [] || 'POST')
        return (
            <div className="text-center no-transactions">
                No {activePair.symbol0}/{activePair.symbol1} trades yet. What are you waiting for?
            </div>
        );
    if (data)
        return data.map((tx) => (
            <tr key={tx.tx.hash}>
                <td className={tx.bought.symbol === activePair.symbol0 ? 'csell' : 'cbuy'} key={tx.tx.hash}>
                    {tx.bought.symbol === activePair.symbol0 ? (
                        <FiArrowDownRight size={14} style={{ marginTop: '-9px', marginLeft: '2px' }} />
                    ) : (
                        <FiArrowUpRight size={14} style={{ marginTop: '-8px', marginLeft: '2px' }} />
                    )}
                </td>
                <td className="td-class">{iso8601ToDateString(tx.block.timestamp.iso8601)}</td>
                <td className="td-class">
                    {numberWithCommas(tx.buyAmount.toFixed(3))} {tx.bought.symbol}{' '}
                </td>
                <td className="td-class">
                    {numberWithCommas(tx.sellAmount.toFixed(3))} {tx.sold.symbol}
                </td>
                <td className="td-class">
                    {tx.bought.symbol === activePair.symbol0 ? (
                        <span>${tx.sellAmountInUsd.toFixed(2)}</span>
                    ) : (
                        <span>${tx.buyAmountInUsd.toFixed(2)}</span>
                    )}{' '}
                </td>
            </tr>
        ));
}

function WalletTransactions() {
    const { activePair } = useStoreApi();
    const { isConnected, address } = useAccount();
    if (!isConnected)
        return (
            <div className="table-responsive txtable d-flex flex-column justify-content-center align-items-center">
                <h3 className="subtlebigh3">Connect to use this feature</h3>
            </div>
        );

    return (
        <Container className="table-responsive txtable p-0">
            <Container className="position-info-container pt-3 pb-2">
                <Col className="position-info-wrapper" />
            </Container>
            <Table className="table table-hover table-sm text-muted">
                <thead className="tp-head sticky-top botshadow">
                    <tr>
                        <th style={{ width: '10px' }} />
                        <th style={{ fontWeight: '100', fontSize: '14px' }}>Timestamp</th>
                        <th style={{ fontWeight: '100', fontSize: '14px' }}>Sold </th>
                        <th style={{ fontWeight: '100', fontSize: '14px' }}>For</th>
                        <th style={{ fontWeight: '100', fontSize: '14px' }}>Value</th>
                    </tr>
                </thead>
                <tbody className="bg-darker">
                    <TableBody />
                </tbody>
            </Table>
        </Container>
    );
}
export default WalletTransactions;
