import { toast } from 'react-toastify';
import Moralis from 'moralis';
import Web3 from 'web3';
import { SWAP_TOKEN_BY_QUOTE, MORALIS_SERVER_URL, MORALIS_APP_ID, PROXY_URL, POKEDX_API_URL, DATA_SERVICE_URL } from './ApiURL';
import { NULL_LP } from './constants';

export function numberWithCommas(x) {
    if (x > 0) {
        const parts = x.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
    }
    return 0;
}

export function getFavToken(value) {
    const tokenList = {
        PDX: {
            value: '0x43a0c5eb1763a211aa3c05849a617f2ee0452767',
            symbol: 'PDX'
        },
        BUSD: {
            value: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
            symbol: 'BUSD'
        },
        WBNB: {
            value: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
            symbol: 'WBNB'
        },
        BNB: {
            value: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
            symbol: 'BNB'
        },
        USDT: {
            value: '0x55d398326f99059ff775485246999027b3197955',
            symbol: 'USDT'
        }
    };
    return tokenList[value];
}

export function txValueWithCommas(x) {
    if (x > 10000) {
        x = Math.round(x);
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return parseFloat(x).toFixed(2);
}
export function toFixedDecimal(x) {
    if (x < 1) {
        const zeros = -Math.floor(Math.log10(x) + 1);
        return zeros + 4;
    }
    if (x > 1) {
        return 2;
    }
    return 0;
}

export function priceDecimal(x) {
    if (x && x < 1) {
        return toFixedDecimal(x);
    }
    return 2;
}

export function timeTo12HrFormat(time) {
    // Take a time in 24 hour format and format it in 12 hour format
    const timePartArray = time.split(':');
    let ampm = 'AM';

    if (timePartArray[0] >= 12) {
        ampm = 'PM';
    }

    if (timePartArray[0] > 12) {
        timePartArray[0] -= 12;
    }

    const formattedTime = `${timePartArray[0]}:${timePartArray[1]}:${timePartArray[2]}`;

    return formattedTime;
}

export function iso8601ToDateString(time) {
    const date = new Date(time);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    const newDate = date.toLocaleDateString('UTC', options);
    return newDate;
}

export const removeLocalToken = () => {
    localStorage.removeItem('token');
};

export const getDecimals = async (tokenAddress, isTokenData = false) => {
    const response = await fetch(`${DATA_SERVICE_URL}/get-decimal?tokenAddress=${tokenAddress}`)
        .then((res) => res.json())
        .then((data) => {
            console.log('data from dataservice===>', data);
            if (isTokenData) {
                return data[0];
            }
            return data[0].decimals;
        })
        .catch((err) => console.error(err));
    return response;
};

export const getTokenPrice = async (isToken, address = null) => {
    const activePair = getActivePairFromDB();
    const tokenAdderess = isToken ? address : activePair.token1;
    const response = await fetch(`${PROXY_URL}/token-price?token=${tokenAdderess}`)
        .then((res) => res.json())
        .then((data) => data.usdPrice)
        .catch((err) => console.error(err));
    return response;
};

export const getBnbToUSD = async () => {
    try {
        const resp = await fetch(`${DATA_SERVICE_URL}/usdRate?token=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c`);
        const data = await resp.json();
        return data.rate;
    } catch (e) {
        return 0;
    }
};

export const getAllowance = async () => {
    const response = await fetch(`${SWAP_TOKEN_BY_QUOTE}approve/spender `)
        .then((res) => res.json())
        .then((data) => data)
        .catch((err) => console.error(err));
    return response;
};

export const searchToken = (str) => {
    if (str.replace(/\s/g, '') != '') {
        const response = [];
        const pattern = /0x/i;
        const result = str.match(pattern);
        const searchType = result == null ? 'name' : 'address';

        return fetch(`${DATA_SERVICE_URL}/autocomplete-search?searchType=${searchType}&value=${str}`)
            .then((response) => response.json())
            .then((data) => {
                data = data.map((item) => ({
                    label: item.name,
                    value: item.address,
                    symbol: item.symbol,
                    desc: item.name,
                    logo: item.logo
                }));
                return data;
            })
            .catch((error) => {
                console.log('error', error);
                return null;
            });
    }
};

export const addTokenToMoralis = async (apiResponse, isNew, isListed) => {
    const isDuplicate = await checkForDuplicate(apiResponse.address.toLowerCase(), false);
    if (!isDuplicate) {
        Moralis.start({ serverUrl: MORALIS_SERVER_URL, appId: MORALIS_APP_ID });
        const Token = Moralis.Object.extend('BnbTokens');
        const token = new Token();
        token.set('address', apiResponse.address.toLowerCase());
        token.set('name', apiResponse.name.toLowerCase());
        token.set('price', '0');
        token.set('price_BNB', '0');
        token.set('isListedToken', isListed);
        token.set('isNew', isNew);
        token.set('symbol', apiResponse.symbol.toUpperCase());
        return token.save().then(
            (token) => true,
            (error) => {
                console.error(error);
                return false;
            }
        );
    }
    return false;
};

export const checkForDuplicate = async (address, isListed) => {
    Moralis.start({ serverUrl: MORALIS_SERVER_URL, appId: MORALIS_APP_ID });
    const Token = Moralis.Object.extend('BnbTokens');
    const query = new Moralis.Query(Token);
    query.equalTo('address', address);
    const results = await query.find();
    if (isListed && results.length) {
        results[0].set('isListedToken', 1);
        results[0].save();
        return true;
    }
    return !!(results && results.length);
};

export const getRecentlyAddedTokenList = async () => {
    Moralis.start({ serverUrl: MORALIS_SERVER_URL, appId: MORALIS_APP_ID });
    const Token = Moralis.Object.extend('BnbTokens');
    const query = new Moralis.Query(Token);
    query.equalTo('isListedToken', 1);
    const results = await query.find();
    return results;
};

export const DisplayErrorMsg = (msg) => {
    toast(msg, {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
    });
};

export const numFormatter = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return `${+(n / 1e3).toFixed(2)}K`;
    if (n >= 1e6 && n < 1e9) return `${+(n / 1e6).toFixed(2)}M`;
    if (n >= 1e9) return `${+(n / 1e9).toFixed(2)}B`;
};

export function liquityNumber(x) {
    if (x > 0) {
        if (x > 1000000) {
            return numFormatter(x);
        }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return 0;
}

export function addHideToken(address) {
    let tokens = [];
    if (localStorage.getItem('hideTokens') != null) {
        tokens = JSON.parse(localStorage.getItem('hideTokens'));
    }
    tokens.push(address);
    localStorage.setItem('hideTokens', JSON.stringify(tokens));
    return tokens;
}

export async function submitYourVote(obj) {
    Moralis.start({ serverUrl: MORALIS_SERVER_URL, appId: MORALIS_APP_ID });
    const alreadyVoted = await check24hVote(obj.address, obj.tokenAddress);
    if (!alreadyVoted) {
        const Vote = Moralis.Object.extend('PdxVotes');
        const vote = new Vote(obj);
        return vote.save().then(
            (vote) => 'Voted successfully.',
            (error) => {
                console.error(error);
                return 'something went wrong';
            }
        );
    }
    return 'Alreayd voted, try again after 24h.';
}

export async function check24hVote(address, tokenAddress) {
    try {
        Moralis.start({ serverUrl: MORALIS_SERVER_URL, appId: MORALIS_APP_ID });
        const Vote = Moralis.Object.extend('PdxVotes');
        const query = new Moralis.Query(Vote);
        const ts = Math.round(new Date().getTime() / 1000);
        const voteTime = ts - 24 * 3600;
        query.equalTo('tokenAddress', tokenAddress.toLowerCase());
        query.equalTo('address', address.toLowerCase());
        query.greaterThanOrEqualTo('voteTime', voteTime);
        const results = await query.find();
        return results && results.length ? results : false;
    } catch (error) {
        console.log('error', error.message);
        return false;
    }
}

export async function getProgressDataFromDb(tokenAddress) {
    Moralis.start({ serverUrl: MORALIS_SERVER_URL, appId: MORALIS_APP_ID });
    const Vote = Moralis.Object.extend('PdxVotes');
    const query = new Moralis.Query(Vote);
    query.equalTo('tokenAddress', tokenAddress.toLowerCase());
    query.equalTo('voteType', 1);
    const goodVote = await query.count();
    const badQuery = new Moralis.Query(Vote);
    badQuery.equalTo('tokenAddress', tokenAddress.toLowerCase());
    badQuery.equalTo('voteType', 0);
    const badVote = await badQuery.count();
    const total = goodVote + badVote;
    const goodPercent = Math.round((goodVote / total) * 100);
    const badPercent = Math.round((badVote / total) * 100);
    return {
        goodPercent,
        badPercent
    };
}

export const blacklistTokens = [
    '0x3327c2ff6940b7532048de7aaf511359c60dc34e',
    '0x33a74ffb9efa01ab2a7f69604d1c35a23a1942be',
    '0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
    '0xc7ef1bff46cd025509cf5e55fa5cd5c14793cbff',
    '0x9c297d845b15f7fbe44d403c76e2f5f72fd11cb3',
    '0x6f4ffe47c3e9f8a85ec9039976e04a4fa31dd29b',
    '0xed842434a0226810ba8ef5d309a20ee099f5efec',
    '0xff43463b7af597297970b41a5f42ff6b8d23e9c3',
    '0xfd171730ed409629ca102ba937e1f08c21a90483',
    '0xdb3c3f1e7d4f33dac606fd857dd6e63f67455678',
    '0x0eb3a705fc54725037cc9e008bdede697f62f335',
    '0x89e0262ec34311564b4e43d416218d38d4db879c',
    '0x1997830b5beb723f5089bb8fc38766d419a0444d',
    '0x3fb905f2a4df07956fc443d69ae266143f168037',
    '0x566e1cb32e9841997fd4db804bc212e2cd9223bc',
    '0x0cf1a8f47da44ad29865aa584813fa99d5129598',
    '0xbc6675de91e3da8eac51293ecb87c359019621cf',
    '0x8076c74c5e3f5852037f31ff0093eeb8c8add8d3',
    '0x82eda694cb61bb4e977b8978b5b102831ac228a7',
    '0xf2242178b0433a1191aac54258fcb807ce158c44',
    '0x85eac5ac2f758618dfa09bdbe0cf174e7d574d5b',
    '0x68d1569d1a6968f194b4d93f8d0b416c123a599f',
    '0x57c56665b2bcdf3cb86e40a9d3dc21f5b0aed7ad',
    '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    '0x494f6ca72752c5c941538e7dd719136df8870e2c',
    '0x2b3f34e9d4b127797ce6244ea341a83733ddd6e4',
    '0x15351604e617d9f645b53ee211d9c95ba88297df',
    '0xa0ef7881f793c99a8d46c2ff46f92752dc499f8e',
    '0xc3b759bc189bc69ffad7c5ab5a522666bc051264',
    '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    '0xbe2a4af2ca533e92be8cf99046ada5d0ae9963d6',
    '0xf8d64dc86b0d2df8a93c87c94c85b127b68bc676',
    '0x61a0dcee3da5438aed3798ac276efc5731bec4fd',
    '0x90017be7948eb595a331461e07744e4a66b6f28b',
    '0x003f83da9868acc151be89eefa4d19838ffe5d64',
    '0x0447c9c38d4d39e375219bec908bfa6856e00c47',
    '0x8c5beefa5a2c08e9c1577f3efd29ec194c3fd724',
    '0x782bb4901220c77ccef3b8c60a436ac6a67ffcc2',
    '0xac51066d7bec65dc4589368da368b212745d63e8',
    '0x00112e5a6d623119fdb24ca1816813ac99d8379b',
    '0x396dc54078df81aaba85ecef938dd95a6949d1f5',
    '0x2aaa202e79c75c01a27ee6df22830c39236e5294',
    '0x44bafd63725845157567240739465488b5feeb56',
    '0xfd398e6de8390c5c667ffb1693febd6550e95212',
    '0x70cf8d40a3d0f7bc88077ba7d103050d0001a653',
    '0xca0992b8c1542d43f1f282b5d975478c8f096416',
    '0x7bc9d0da08a08846d779998dff760d45ea5380d2',
    '0x04a105f44f535823ec00b1ac596a0a8f05276ee8',
    '0x6d4e803582d8f0dbab32605289ee3f480309dda9',
    '0x283ff805e094bac28ba1da669216772727d4a2e9',
    '0x04d1bbf27e5aa700a12279ba44359e4e11bb02ef',
    '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
    '0xe5410ec65781a7a2d3adedead6441341b6b92851',
    '0xc7d43f2b51f44f09fbb8a691a0451e8ffcf36c0a',
    '0x9cefd9588f076c5f805341864adc8a6f077a5b99',
    '0x07abc48708a1e4666bd5a6d4c1e090c15d2eff90',
    '0xdccf095a0f214faef92fedcf7501e07f549ebcc8',
    '0xc0994af94fee0361a1e1e1ccf72bce19d5fd86fb',
    '0xb62293be5ec1695e4d0c43059e790b99fd85de0b',
    '0x8d9ee63ad70e6ca19d0f55227c9c6e0b5ef16c62',
    '0x119e2ad8f0c85c6f61afdf0df69693028cdc10be',
    '0xf9b93f867731538c7217fce07b9850b44335119b',
    '0xc9c69d8cae7656b06a832e2cd99520ced38a987a',
    '0x14a8ff741cf84842b671f882c489e34bb8a16212',
    '0x94ad50b22963e04b1b0d027724c56596244bc96f',
    '0x9f665d0cd46a25b43169d372715a68eaebcee313',
    '0x14d6eb7cfbc4303a365a9c85d2c167ab4f65f86a',
    '0x8d0e0883b76ce17828520538c24ba8a5df2ad399',
    '0x4cae95d03c85ae462daec44d5426f547a26faf47',
    '0x2a11feb809f316a7ca273ed497924c0493d7a6a9',
    '0xafec524595882e30cec7f86ea139e5dd486dc359',
    '0xeec191e00aed7c9683ea05d9b19c5c45a9261ca8',
    '0xc44bf5300be9168795dee25daad0bce5a8fb779a',
    '0x596437a62358dc6c1bc64212cd765826b6b4d6b6',
    '0x718f127435213b48c1564c71558608df5770fe68',
    '0xba2ae424d960c26247dd6c32edc70b295c744c43',
    '0xce262761df57c72999146b7a6a752da03835db4a',
    '0x1761d7fc6a761e1e431b17562b6a24df1d94b472',
    '0x304afe561cfe004792fa1eac8e907b65d0076ae8',
    '0x8500c4a12ce0ea7b16c3e8c959ecf7837fbaf93f',
    '0x06c0b3a203883435dc27eafe5cbd2c137e4915e1',
    '0x88c5052b0b10afe9dc1c608ce23039f6ca147b22',
    '0x373233a38ae21cf0c4f9de11570e7d5aa6824a1e',
    '0x1967abfdc4ae7961c5a8a5395469222260c27c02',
    '0xf3822314b333cbd7a36753b77589afbe095df1ba',
    '0xe1ae08d5254d0eb2a2dc8dd447c36b27e5b3a450',
    '0x0ed339834696fa5f0083ec64bcf56c105ec7e039',
    '0x0df62d2cd80591798721ddc93001afe868c367ff',
    '0xe40dc25b6b158cdd8d733ec2700098e2a9ed48af',
    '0xee4edf1b031cec14516c9cece9e955ce0cc6aab2',
    '0xa0c1db314eeb6b52db187c86a3db7c47f9fd3729',
    '0xa96f3414334f5a0a529ff5d9d8ea95f42147b8c9',
    '0x228c0c6a1811ba395d4c3c1d570b00f0746115f2',
    '0x0523215dcafbf4e4aa92117d13c6985a3bef27d7',
    '0x2270a2e12ad55d2493c85d4d92e648741d4c045b',
    '0x3ec7ba1b86387b8cb323eac3f2aae217c876f9a7',
    '0xa8025ff5d787de71d63fcf511d10df67fd13beb6',
    '0x79b3a3af86f341b48c654fb5f0d135b79f2b43af',
    '0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
    '0x23396cf899ca06c4472205fc903bdb4de249d6fc',
    '0x4b0f1812e5df2a09796481ff14017e6005508003',
    '0x1a1f99817523dcbecda68017364aba6a49449035',
    '0x04fa4373bd7ae3beb7d6a0d9dc0c15f3a70fb24e',
    '0x1863ba726dac0bf10459de81c4e058141e878feb',
    '0xe68efedf3e1c08af12de6bdec585f89794a75c52',
    '0xd3fbc0c0d28a92b5d5ec52f48e8b63a92691ce68',
    '0x6a79e08db6c08b8f88703794bf1a47f5a01eb9dc',
    '0xf9eeccdbb6d85240272a130ca2343166d07c196a',
    '0x678e8128138eec59801a80e1d57d0a982b8db5bb',
    '0xbf5140a22578168fd562dccf235e5d43a02ce9b1',
    '0xbf12b7e1486aefe4acc31e48ba60ace470bca97d',
    '0x154a9f9cbd3449ad22fdae23044319d6ef2a1fab',
    '0x2c030fbd5ee3b36d084186ca031ad881af140ee6',
    '0x1b55ce6dd9abc66cf53f0491fbb1003722faaa66',
    '0x15bce83063830e5b3683f55b6a07bf272e8bfc04',
    '0xb0be6d360fd41ce5e39f71fabaedac68997792cb',
    '0x6f5772f4bc3d3c2508f7932f0832d7fd5da7d53b',
    '0x55cc41083b00063ca7793962f75eff8ab2a1e839',
    '0x57d456c327f85a17b4768cbf8e4a53189a055e6d',
    '0xc93ab833335ead4a96b3a9c63cd9662aa7110d2c',
    '0xeeffc04db6c0c8ecd80754982f8eb60186cff34c',
    '0x6e5616f1a717faa1404ec6d6ed1767c17ed40142',
    '0x4078491980985b5bfd1e2e4e9a3113b6186257ac',
    '0x280ce73a3a90b8aeb4ef7ed10c928c35ec5c0420',
    '0xaf173aaf59f7fa3c6e4ba5e0fc4d8ab7f2006e80',
    '0xfb16cc83df6623f7cdcb5cfaa1ab72fe9041dbf4',
    '0x7c8a137df298009e580a3dd00a3819bb0ddf0f47',
    '0x1882c296ebfa916a0ad194cfa0094c5e0086ba03',
    '0x14982aa94caab4a0dd7866d047d62fb320009a74',
    '0x4dc7c9148a1f495872a5b31cf459ac7989cdce79',
    '0x47f45a2c8064bfd57b19673d64d485132246e010',
    '0x40619dc9f00ea34e51d96b6ec5d8a6ad75457434',
    '0xae9269f27437f0fcbc232d39ec814844a51d6b8f',
    '0x08ba0619b1e7a582e0bce5bbe9843322c954c340',
    '0x48a64f116ce221fd7c1119775ba7892701f9911d',
    '0xacfc95585d80ab62f67a14c566c1b7a49fe91167',
    '0xb8a9704d48c3e3817cc17bc6d350b00d7caaecf6',
    '0x46880afc2e6fa41bbbe9787c082f7c23f795465e',
    '0x55d398326f99059ff775485246999027b3197955',
    '0x7d5379212fafd11957ed711205354caba310c1ed',
    '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    '0x50da77f18051d60d5b8c830ada5f72c50fceba9c',
    '0xc9849e6fdb743d08faee3e34dd2d1bc69ea11a51',
    '0xc79c984efb04010504d4be2abd916abfad6b0dc7',
    '0xd48e5ef1cd316a997a18db0407d36959708dfb71',
    '0x8d2d0e7d45bdea3c44b87e2210e95b5826bbc101',
    '0x89c3d7f175e6142d9bb28df515410f4b1a7555d9',
    '0x6d2130efd1b6accacdbedb1a67a7e2e73ffa9756',
    '0x7cce94c0b2c8ae7661f02544e62178377fe8cf92',
    '0xaa40eebae662cd14ed5df7cb6f7d55ba93c869d2',
    '0xb6959be0dd2d25e79d69c7b8d6ece07f39b296e3',
    '0xcae755389bee1b066f4713f3909983b48161ae2e',
    '0x569b2cf0b745ef7fad04e8ae226251814b3395f9',
    '0xf5ce8b35e2c06b05eda3764b51042dfcb812a69c',
    '0x9612f89f32c8a8419be7a65358e485b76db2d7f4',
    '0xa2f45a12419de4a4dd1313c61d2a71330f8b61c5',
    '0xe9a904192342cf1f18069609f630e3e470deb366',
    '0xcac33ce2734d30949f5a96f7d64851830fda7ad9',
    '0x8bcbf3d220cd44b56d31e250555028b05547567b',
    '0x78650b139471520656b9e7aa7a5e9276814a38e9',
    '0x7ee7f14427cc41d6db17829eb57dc74a26796b9d',
    '0x202ea1329665a7ed6082cc8ee30baed6fe23d81a',
    '0x19b60612f9a93359bca835a788a334d4157e675b',
    '0x799e1cf88a236e42b4a87c544a22a94ae95a6910',
    '0xf869ffbff0f473062b13b662329e104635b1efd9',
    '0xc26d68a120d80cd22b3f4f85aee84dea8203e736',
    '0xab57aef3601cad382aa499a6ae2018a69aad9cf0',
    '0x8336cf2340bdf913da8385eeb57840b83c950c5f',
    '0x583e87f86c2affa5d98e8c0c137eb5995cb474bf',
    '0x7acf49997e9598843cb9051389fa755969e551bb',
    '0x43c934a845205f0b514417d757d7235b8f53f1b9',
    '0xb0557906c617f0048a700758606f64b33d0c41a6',
    '0x5190b01965b6e3d786706fd4a999978626c19880',
    '0xb90e1126200beee89f16bac817fd02a7ebcb389b',
    '0x85df4b5cafcf823e79ceab706d969bdb7bb2b6a4',
    '0x268f7bf8941f8d43a032cbf38ae37ea93e605974',
    '0x701452b81ba61dd2f53a1db88c4ad7dfb51f5bed',
    '0x9b7616e8b12dbc6063536dbef2dd940f715e84bf',
    '0xadd4d3a0e58ce84d0b42120b23bb20dce92d2ede',
    '0x47e28936d9c7944d106e46295d87a8e4c1be818c',
    '0x22ea985e940934eea0b6adcc14bab5d46168a4fb',
    '0xc4acd5ec78372dcaa4f9aa0799c052853ea11919',
    '0x57bb0b5b9f964ef5c851e227d7a14cae4147ee42',
    '0xeecf1b979191372249b8d0b9b32457e918a54c82',
    '0xc4871be594043c9a33770b1eb32ef194058df1c1',
    '0x7269163f2b060fb90101f58cf724737a2759f0bb',
    '0x24aefaddbaec40b5e6912b0200164b51c5b17181',
    '0x2df0b14ee90671021b016dab59f2300fb08681fa',
    '0xb4670b23638f383cfd2d8e684b46c845fb782313',
    '0x715d400f88c167884bbcc41c5fea407ed4d2f8a0',
    '0xe3d478fe8e8f55f7e9b2b55cf25868edc9f924d8',
    '0xddf1d0f361ae9444020fcfa905acb4faff420bdd',
    '0xf6c12d4b9178d9145c2e6545c32f8cf907a43fa8',
    '0xecc62bd353edd64ed31595dbc4c92801af1e2af0',
    '0xd22202d23fe7de9e3dbe11a2a88f42f4cb9507cf',
    '0x01b20cccb063c09d5a834072f7e9adaaaaab233e',
    '0x5558447b06867ffebd87dd63426d61c868c45904',
    '0xf74b902806ce8d1f33b5c07b56d6adb87c0fc43a',
    '0x4f723940bdd50c2d1cb2c2c5e56e37817c43638e',
    '0xe13adcc13a61f134017530f2eb345c417438d5ca',
    '0x222755de9969b02727f8c2d75cf24b2a91ddb267',
    '0x875b23f90528117c6e3a78cb72e35781746475cb',
    '0xd08f276fc324a7e9b3b51bfc7da1bbca6144f180',
    '0x6bf3533f8a81e3c7ffffce44b226a8dc4f1552cb',
    '0x50ed8a61a2214ec68c02f58e7baca80e88b8aa1c',
    '0xd5eb2270fd872f2d8d97deb2f977fbebb96d1dca',
    '0xf03c1cba20b69f5bb12506cd0d6d2362b5abed8d',
    '0xb16600c510b0f323dee2cb212924d90e58864421',
    '0x43c5e15aaf0375229662ffba990044b960fdd7dd',
    '0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe',
    '0xeb1ecce22836c2b551819e156813a087a74cac76',
    '0xf872526a3eac69e9f53b91850f331c2685ab62b5',
    '0xef89d9e0f376c52e0303f14ecdd528e4ea1136aa',
    '0xc286be607f1402b04686f56164ac1c9b4370182e',
    '0x8ee3e98dcced9f5d3df5287272f0b2d301d97c57',
    '0xd9abdc78e9d9925e0642bd5e92098af3a66efae9',
    '0xb6011673643c12dae336c8931eb8a88e5d6cec96',
    '0xf275e1ac303a4c9d987a2c48b8e555a77fec3f1c',
    '0x08a68ef8f28fbe9008adcb5bf4a7140cb1bbf23a',
    '0x57dbae4b73455bc0d3e892ae57779160961f0f03',
    '0x1f5f0bb26a25f1b5a000fd7bd3e71cb496775169',
    '0xd508372b83a0c9caf5ecfe38f8ad4aa835aebc8a',
    '0x575b0339a30d5f29dcbc444ab28f2b194a5e2fa7',
    '0x1f63034eeb573936265d6ecb65c4e50d334b6eef',
    '0x2c054444c8bfe5d8d5dece94d86508925b27368a',
    '0x9c08d8fc9347e55a5473ad74787715fca958d44d',
    '0x31e3da2728d51ce3977d80cec62fbe2bce7ab450',
    '0x0e156fc2355f5885b00433c7a673fd7e8c647166',
    '0x7d9c3bd1eb0b0a8921fab9c57e26e05518d87b4d',
    '0x5190b01965b6e3d786706fd4a999978626c19880',
    '0xd22202d23fe7de9e3dbe11a2a88f42f4cb9507cf',
    '0xb16600c510b0f323dee2cb212924d90e58864421',
    '0xbc6675de91e3da8eac51293ecb87c359019621cf',
    '0x373233a38ae21cf0c4f9de11570e7d5aa6824a1e',
    '0xc33fc11b55465045b3f1684bde4c0aa5c5f40124',
    '0x8ee3e98dcced9f5d3df5287272f0b2d301d97c57',
    '0x119e2ad8f0c85c6f61afdf0df69693028cdc10be',
    '0x4827405d992d4d42f9ff4bb9d13ec9b616a75278',
    '0x68d1569d1a6968f194b4d93f8d0b416c123a599f',
    '0xb0557906c617f0048a700758606f64b33d0c41a6',
    '0x5562bf29cd88b8ec3be455a9a3f7e762154d6242',
    '0x202ea1329665a7ed6082cc8ee30baed6fe23d81a',
    '0xa43d8b1f070b8e2fd2de4e01369369d5fd7d4784',
    '0x442b656f5a5c3dd09790951810c5a15ea5295b51',
    '0xd5e3bf9045cfb1e6ded4b35d1b9c34be16d6eec3',
    '0x58c10c8e2b80fdb5613778125ddd1c93f8cc8983',
    '0x0198be93b7cae38da7e2fd966946412cc36447bf',
    '0xb3ec4e17c1c0079d977354875ab924c088325306',
    '0x3D8AF100A516780D23A53ab5f0509103eb22b9C5',
    '0x5E48C354a5Da2B0A8C203518d0fc7B9c58Cc9329',
    '0x27b880865395da6cda9c407e5edfcc32184cf429',
    '0x5e48c354a5da2b0a8c203518d0fc7b9c58cc9329'
];

export const getBlackListedTokens = () => {
    let hideTokens = [];
    if (localStorage.getItem('hideTokens')) {
        hideTokens = JSON.parse(localStorage.getItem('hideTokens'));
    }
    hideTokens = hideTokens.concat(blacklistTokens);
    return hideTokens;
};

export const addToWishlist = (obj) => {
    let watchList = getWatchList();
    if (watchList.length) {
        watchList = JSON.parse(localStorage.getItem('watchlistTokens'));
        watchList.push(obj);
        localStorage.setItem('watchlistTokens', JSON.stringify(watchList));
    } else {
        watchList.push(obj);
        localStorage.setItem('watchlistTokens', JSON.stringify(watchList));
    }
};

export const getWatchList = () => JSON.parse(localStorage.getItem('watchlistTokens')) || [];

export const updateWatchList = (watchList) => {
    localStorage.setItem('watchlistTokens', JSON.stringify(watchList));
};
export const removeToWishlist = (tokenAddress) => {
    const watchList = getWatchList();
    const filteredTokens = watchList.filter((obj) => obj.address != tokenAddress);
    updateWatchList(filteredTokens);
};

export const setActivePairInDB = (pairs) => {
    localStorage.setItem('activePairs', JSON.stringify(pairs));
};

export const getActivePairFromDB = () => JSON.parse(localStorage.getItem('activePairs')) || [];

export const setFeesInDB = (fees) => {
    localStorage.setItem('fees', JSON.stringify(fees));
};

export const getFeesFromDB = () => JSON.parse(localStorage.getItem('fees')) || [];

export const setFeeLinesInDB = (feeLines) => {
    localStorage.setItem('feelines', JSON.stringify(feeLines));
};

export const getSettingsDataFromDB = () => {
    const settings = JSON.parse(localStorage.getItem('settings')) || null;
    if (settings) {
        return settings;
    }
    const data = {
        priceChange24: true,
        tradingVolumeTab: true,
        liquidityTab: true,
        marketCapTab: true,
        totalSupplyTab: true,
        bnbPriceTab: true,
        pdxPerBnbTab: true,
        buyFeeTab: true,
        sellFeeTab: true,
        balanceTab: true,
        layout: 'middle'
    };
    setSettingsDataToDB(data);
    return data;
};

export const setSettingsDataToDB = (data) => {
    localStorage.setItem('settings', JSON.stringify(data));
};
export const getFeeLinesFromDB = () => JSON.parse(localStorage.getItem('feelines')) || [];

export const setLastTransactionsPair = (pair) => {
    localStorage.setItem('lastTransactionsPair', JSON.stringify(pair));
};

export const getLastTransactionsPair = () => JSON.parse(localStorage.getItem('lastTransactionsPair')) || NULL_LP;

export const setLastActiveToken = (token) => {
    localStorage.setItem('lastActiveToken', token);
};

export const getLastActiveToken = () => localStorage.getItem('lastActiveToken') || null;

export const setLastTxFetchDatetime = (dt) => {
    localStorage.setItem('lastTxFetchDatetime', dt);
};

export const getLastTxFetchDatetime = () => localStorage.getItem('lastTxFetchDatetime') || 0;

export const setLiveTransactionsInDb = (liveTransactions) => {
    localStorage.setItem('liveTransactions', JSON.stringify(liveTransactions));
};

export const getliveTransactionsFromDB = () => JSON.parse(localStorage.getItem('liveTransactions')) || [];

export const BASE_TOKEN_ADDR = '0x43a0c5eb1763a211aa3c05849a617f2ee0452767';

export const getMainTokensPrice = async () => {
    const response = await fetch(`${PROXY_URL}/fetchapi?endpoint=BTC,BNB,ETH,PDX`, {
        method: 'GET',
        headers: {
            accept: '*/*'
        }
    })
        .then((res) => res.json())
        .then((data) => data)
        .catch((err) => console.error(err));
    return response;
};

export const getTokenBreakDownData = async (address) => {
    const response = await fetch(`${POKEDX_API_URL}?address=${address}&action=breakdown`)
        .then((res) => res.json())
        .then((data) => data)
        .catch((err) => console.error(err));
    return response;
};

export const getEthInstance = async () => {
    let instance;
    if (window.ethereum) {
        // set up a new provider
        try {
            instance = new Web3(window.ethereum);
        } catch (error) {
            console.error(error);
        }
    } else if (window.web3) {
        instance = new Web3(window.web3);
    } else {
        // fallback on localhost provider
        const provider = new Web3.providers.HttpProvider('https://restless-bold-violet.bsc.quiknode.pro/'); // replaced provider: http://127.0.0.1:8545
        instance = new Web3(provider);
    }
    return instance;
};

export const addTokenToRecentlyViewedList = (obj) => {
    const list = recentlyAddedTokenList();
    if (list.length > 0) {
        const checkExistList = list.filter((item) => item.id === obj.id);
        if (checkExistList.length < 1) {
            list.push(obj);
            localStorage.setItem('recentlyViewedList', JSON.stringify(list));
        }
    } else {
        list.push(obj);
        localStorage.setItem('recentlyViewedList', JSON.stringify(list));
    }
};

export const recentlyAddedTokenList = () => JSON.parse(localStorage.getItem('recentlyViewedList')) || [];

export const getToFixed = (lpResponse, value) => {
    if (lpResponse[value]) {
        const reseve = +lpResponse[value];
        return reseve.toFixed(3);
    }
    return 0;
};

export const minToMs = (min) => min * 60 * 1000;

export const getQuoteToken = (pairData, baseToken) => {
    const index = pairData.pair.tokens.indexOf(baseToken.toLowerCase()) === 0 ? 1 : 0;
    return pairData.pair.tokens[index];
};

export const fetchTradesimulationData = async (tokenAddress) => {
    const response = await fetch(`${PROXY_URL}/honeypot/rugcheck?type=pancakeswap&address2=default&tokenAddress=${tokenAddress}`)
        .then((res) => res.json())
        .then((data) => data.data)
        .catch((err) => console.log(err));
    return response;
};

// export const fetchTopTraders = async (pair, since, till) => {
//     const response = await fetch(`${DATA_SERVICE_URL}/top-traders?pair=${pair}&since=${since}&till=${till}`)
//         .then((res) => res.json())
//         .then((data) => data)
//         .catch((err) => console.log(err));
//     return response;
// };

export const getDecimalString = async (num) => {
    if (num === '1') return 'wei';
    if (num === '3') return 'babbage';
    if (num === '6') return 'mwei';
    if (num === '9') return 'shannon';
    if (num === '12') return 'szabo';
    if (num === '15') return 'milli';
    if (num === '18') return 'ether';
    return response;
};
