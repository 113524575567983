import React, { useEffect, useRef, useState } from 'react';
import { createChart, CrosshairMode } from 'lightweight-charts';
import moment from 'moment';
import TokenInfo from './TokenInfo';
import { useStoreApi } from '../../store/storeApi';
import { PROXY_URL, BNB_API_URL, BSCSCAN_API_KEY } from '../../shared/ApiURL';
import { getHistoryQuery } from '../../shared/historicQuery';
import TvChart from './Tvchart';
import LeftSection from './LeftSection';
import SocialStats from './SocialStats';
import CryptoOverview from './CryptoOverview';
import MiddleSection from './MiddleSection';

export default function Chart(props) {
    const chartContainerRef = useRef();
    const chart = useRef();
    const [bnbPrice, setBnbPrice] = useState(0);
    const resizeObserver = useRef();
    const { trade, tokenAddress, tvChart, tradeleft, token, layout } = useStoreApi();
    const [query, setQuery] = useState(getHistoryQuery(7, 60, tokenAddress));
    const [intervalSel, setIntervalSel] = useState(7);

    let divClass = 'col-8 graph';
    if (trade && tradeleft) {
        // both
        divClass = 'col-7 graph';
    } else if (trade) {
        divClass = 'col-8 graph';
    } else if (tradeleft) {
        divClass = 'col-10 pl-2 graph';
    } else {
        divClass = 'col-12 graph';
    }
    return (
        <div className="fullheight" style={{ marginLeft: '12px', marginRight: '12px', height: '100%' }}>
            <div className="row ccontainer">
                {tradeleft ? <LeftSection /> : null}
                {layout == 'left' && trade ? <TokenInfo /> : null}
                {layout == 'left' && trade ? <SocialStats /> : null}
                {layout == 'middle' && trade ? <TokenInfo /> : null}
                {tvChart ? <MiddleSection data={props.data} /> : null}
                {layout == 'right' && trade ? <TokenInfo /> : null}
                {layout == 'right' && trade ? <SocialStats /> : null}
                {layout == 'middle' && trade ? <SocialStats /> : null}
            </div>
        </div>
    );
}
