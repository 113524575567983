const bscChain = [
    {
        id: 56,
        name: 'Binance Smart Chain',
        nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18
        },
        rpcUrls: {
            default: 'https://bsc-dataseed.binance.org',
            default2: 'https://bsc-dataseed1.defibit.io/',
            default3: 'https://bsc-dataseed1.ninicoin.io/'
        },
        blockExplorers: {
            etherscan: {
                name: 'BNB Smart Chain Explorer',
                url: 'https://bscscan.com'
            },
            default: {
                name: 'BNB Smart Chain Explorer',
                url: 'https://bscscan.com'
            }
        }
    }
];

export default bscChain;
