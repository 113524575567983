import React from 'react';
import { Col } from 'react-bootstrap';
import { TradingWindow } from '../components/Token/Trade/TradingWindow';

function Trade() {
    return (
        <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: '95vh', borderTop: '1px solid var(--color-border-grey)', marginTop: '-3px' }}
        >
            <Col lg={3} md={5} sm={7}>
                <TradingWindow />
            </Col>
        </div>
    );
}

export default Trade;
