import React, { useState } from 'react';
import { useStoreApi } from '../../store/storeApi';
import { BSC_URL } from '../../shared/ApiURL';
import { numberWithCommas } from '../../shared/commonUtils';

function BiggestBuyers() {
    // const { activePair } = useStoreApi();

    return (
        <div className="table-responsive txtable">
            {/* <table className="table table-hover table-sm text-muted">
                <thead className="tp-head sticky-top botshadow">
                    <tr>
                        <th style={{ fontWeight: '100', fontSize: '14px' }}>Wallet </th>
                        <th style={{ fontWeight: '100', fontSize: '14px' }}>Total bought </th>
                    </tr>
                </thead>
                <tbody className="bg-darker">
                    Coming up
                    <RenderBody />
                </tbody>
            </table> */}
            <div className="fucking-center">
                <h3 className="subtlebigh3">Buyers overview next up</h3>
            </div>
        </div>
    );
}
export default React.memo(BiggestBuyers);

function RenderBody() {
    const [bigBuyers, setBigBuyers] = useState([]);
    const { activePair, tokenAddress } = useStoreApi();
    // useEffect(() => {
    //     if (activePair !== 'undefined') {
    //         const myHeaders = new Headers();
    //         myHeaders.append('Content-Type', 'application/json');
    //         fetchTopTraders(activePair.pair.address, '2022-09-01T23:59:59', '2022-10-21T23:59:59')
    //             .then((result) => setBigBuyers(result.topBuyers))
    //             .catch((error) => console.log('error', error));
    //     }
    // }, [activePair]);

    return bigBuyers?.map((buyers) => (
        <tr key={buyers.transaction.hash}>
            <td
                style={{
                    color: 'var(--text-base)',
                    fontWeight: '100',
                    fontFamily: 'monotalic',
                    textTransform: 'uppercase'
                }}
            >
                {/* <img
                    height="10px"
                    width="10px"
                    alt="avatar"
                    src={`https://avatars.dicebear.com/api/identicon/${JSON.stringify(buyers.transaction.txFrom.address)}.svg`}
                    className="mr-2"
                /> */}

                <a
                    href={`${BSC_URL}token/${tokenAddress}?a=${buyers.transaction.txFrom.address}`}
                    target="_blank"
                    rel="noreferrer"
                    className="blue-hover"
                    style={{ cursor: 'pointer' }}
                >
                    {buyers.transaction.txFrom.address}
                </a>
            </td>
            <td
                style={{
                    color: 'var(--text-base)',
                    fontWeight: '100',
                    fontFamily: 'monotalic',
                    textTransform: 'uppercase'
                }}
            >
                ${numberWithCommas(buyers.buyAmount.toFixed(2))}
            </td>
        </tr>
    ));
}
