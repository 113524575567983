import { useStoreApi } from '../../store/storeApi';
import React, { useEffect, useState } from 'react';
import { Modal, Form, Col, Image, ListGroup } from 'react-bootstrap';
import FULLSCREEN_LAYOUT from '../../assets/img/layout_fs_PDX.svg';
import SIDEBARLEFT_LAYOUT from '../../assets/img/layout_sl_PDX.svg';
import SIDEBARRIGHT_LAYOUT from '../../assets/img/layout_sr_PDX.svg';

export default function SettingModal(props) {
    const { tradeleft, setTrade, setTradeLeft, setLayout } = useStoreApi();
    const [settings, setSettings] = useState(null);
    // Tradeview swicher
    const enableDisableLeftTrade = () => {
        const checkbox = document.getElementById('tradeleft');
        setTradeLeft(checkbox.checked);
    };

    const changeLayout = (type) => {
        setLayout(type);
        props.changeSettings('layout', type);
        if (type == 'right' || type == 'left' || type == 'middle') {
            setTrade(true);
        } else {
            setTrade(false);
        }
    };

    useEffect(() => {
        if (props.settings) {
            setSettings(props.settings);
            setLayout(props.settings.layout);
        }
    }, [props.settings]);
    const changeSettings = (type) => {
        switch (type) {
            case 'priceChange24':
                setSettings({ ...settings, priceChange24: !settings.priceChange24 });
                break;
            case 'tradingVolumeTab':
                setSettings({ ...settings, tradingVolumeTab: !settings.tradingVolumeTab });
                break;
            case 'liquidityTab':
                setSettings({ ...settings, liquidityTab: !settings.liquidityTab });
                break;
            case 'marketCapTab':
                setSettings({ ...settings, marketCapTab: !settings.marketCapTab });
                break;
            case 'totalSupplyTab':
                setSettings({ ...settings, totalSupplyTab: !settings.totalSupplyTab });
                break;
            case 'bnbPriceTab':
                setSettings({ ...settings, bnbPriceTab: !settings.bnbPriceTab });
                break;
            case 'pdxPerBnbTab':
                setSettings({ ...settings, pdxPerBnbTab: !settings.pdxPerBnbTab });
                break;
            case 'buyFeeTab':
                setSettings({ ...settings, buyFeeTab: !settings.buyFeeTab });
                break;
            case 'sellFeeTab':
                setSettings({ ...settings, sellFeeTab: !settings.sellFeeTab });
                break;
            case 'balanceTab':
                setSettings({ ...settings, balanceTab: !settings.balanceTab });
                break;
            default:
                break;
        }
        props.changeSettings(type);
    };

    // Tradeview swicher
    const enableDisableTrade = () => {
        const checkbox = document.getElementById('trade');
        setTrade(checkbox.checked);
    };
    return (
        <Modal {...props} animation={false} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: '16px', color: 'var(--text-muted)', fontWeight: 'normal' }}>Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-3 py-0">
                <ListGroup variant="flush">
                    <ListGroup.Item>
                        <span>Price change (24h)</span>
                        <span>
                            <Form>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-1"
                                    checked={settings?.priceChange24}
                                    onChange={() => changeSettings('priceChange24')}
                                />
                            </Form>
                        </span>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <span>Trading volume (24h)</span>
                        <span>
                            <Form>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    checked={settings?.tradingVolumeTab}
                                    onChange={() => changeSettings('tradingVolumeTab')}
                                />
                            </Form>
                        </span>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <span>Liquidity</span>
                        <span>
                            <Form>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    checked={settings?.liquidityTab}
                                    onChange={() => changeSettings('liquidityTab')}
                                />
                            </Form>
                        </span>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <span>Marketcap</span>
                        <span>
                            <Form>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    checked={settings?.marketCapTab}
                                    onChange={() => changeSettings('marketCapTab')}
                                />
                            </Form>
                        </span>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <span>Total supply</span>
                        <span>
                            <Form>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    checked={settings?.totalSupplyTab}
                                    onChange={() => changeSettings('totalSupplyTab')}
                                />
                            </Form>
                        </span>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <span>BNB price</span>
                        <span>
                            <Form>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    checked={settings?.bnbPriceTab}
                                    onChange={() => changeSettings('bnbPriceTab')}
                                />
                            </Form>
                        </span>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <span>PDX per BNB</span>
                        <span>
                            <Form>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    checked={settings?.pdxPerBnbTab}
                                    onChange={() => changeSettings('pdxPerBnbTab')}
                                />
                            </Form>
                        </span>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <span>Buy fee</span>
                        <span>
                            <Form>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    checked={settings?.buyFeeTab}
                                    onChange={() => changeSettings('buyFeeTab')}
                                />
                            </Form>
                        </span>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <span>Sell fee</span>
                        <span>
                            <Form>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    checked={settings?.sellFeeTab}
                                    onChange={() => changeSettings('sellFeeTab')}
                                />
                            </Form>
                        </span>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <span>Balance</span>
                        <span>
                            <Form>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    checked={settings?.balanceTab}
                                    onChange={() => changeSettings('balanceTab')}
                                />
                            </Form>
                        </span>
                    </ListGroup.Item>
                </ListGroup>
                <Col className="px-0">
                    <h6 className="mt-2" style={{ fontWeight: '400', color: '#c3c2d4' }}>
                        <span>Change layout</span>
                    </h6>
                    <ListGroup variant="flush">
                        <ListGroup.Item style={{ color: '#959cb2' }}>
                            <Col className="m-0 p-0">
                                <Form onClick={() => changeLayout('full')}>
                                    <Form.Check
                                        type="radio"
                                        id="trade"
                                        defaultChecked={tradeleft}
                                        label="Full width"
                                        style={{ cursor: 'pointer' }}
                                    />
                                </Form>
                            </Col>
                            <Col className="d-flex justify-content-end">
                                <Image src={FULLSCREEN_LAYOUT} height="42px" onClick={() => changeLayout('full')} />
                            </Col>
                        </ListGroup.Item>
                        <ListGroup.Item style={{ color: '#959cb2' }}>
                            <Col className="p-0 m-0">
                                <Form onClick={() => changeLayout('right')}>
                                    <Form.Check
                                        type="radio"
                                        // id={`default-${type}`}
                                        label="Right sidebar"
                                        checked={tradeleft}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </Form>
                            </Col>
                            <Col className="d-flex justify-content-end">
                                <Image
                                    src={SIDEBARLEFT_LAYOUT}
                                    height="42px"
                                    onClick={() => changeLayout('right')}
                                    style={{ cursor: 'pointer' }}
                                />
                            </Col>
                        </ListGroup.Item>
                        <ListGroup.Item style={{ color: '#959cb2' }}>
                            <Col className="m-0 p-0">
                                <Form onClick={() => changeLayout('left')}>
                                    <Form.Check
                                        type="radio"
                                        // id={`default-${type}`}
                                        label="Left sidebar"
                                        checked={tradeleft}
                                        defaultChecked={tradeleft}
                                        onChange={enableDisableTrade}
                                    />
                                </Form>
                            </Col>
                            <Col className="d-flex justify-content-end">
                                <Image src={SIDEBARRIGHT_LAYOUT} height="42px" onClick={() => changeLayout('left')} />
                            </Col>
                        </ListGroup.Item>
                        <ListGroup.Item style={{ color: '#959cb2' }}>
                            <Col className="p-0 m-0">
                                <Form onClick={() => changeLayout('middle')}>
                                    <Form.Check
                                        type="radio"
                                        // id={`default-${type}`}
                                        label="Middle"
                                        checked={tradeleft}
                                    />
                                </Form>
                            </Col>
                            <Col className="d-flex justify-content-end">
                                <Image src={SIDEBARLEFT_LAYOUT} height="42px" onClick={() => changeLayout('middle')} />
                            </Col>
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
            </Modal.Body>
        </Modal>
    );
}
