// components
import { BiTransferAlt } from 'react-icons/bi';
import { IoIosMore, IoIosSwap } from 'react-icons/io';
import { VscTelescope } from 'react-icons/vsc';
import { CgViewList, CgUserList } from 'react-icons/cg';
import { Tab, Nav, Row, Col, Button, Dropdown } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { TradingWindow } from './Trade/TradingWindow';
import Transaction from './Transaction';
// icons
// dependencies
import TokenDetails from './TokenDetails';
import Vote from './Vote';
import DisplayVote from './DisplayVote';
import WalletTransactions from './WalletTransactions';
import { useStoreApi } from '../../store/storeApi';
import PriceCalculator from './PriceCalculator';

function CryptoOverviewMobile() {
    const { address, trackWallets } = useStoreApi();
    const [selectedTrackAddress, setSelectedTrackAddress] = useState(null);

    useEffect(() => {
        if (address) {
            // console.log('in mobile trackWallets===>', trackWallets);
            if (trackWallets?.length) {
                setSelectedTrackAddress(trackWallets[0]);
            } else {
                setSelectedTrackAddress(address);
            }
        }
    }, [trackWallets]);
    return (
        <div className="hideOnDesktop p-0 m-0">
            <Tab.Container defaultActiveKey="swap" id="mob-co">
                <div className="mobiletabs-wrapper">
                    <Nav className="mobiletabs mb-2 ml-0 mr-2">
                        <Col style={{ color: '#f7f7f7' }} className="d-flex row justify-content-center">
                            <Nav.Item className="active" style={{ maxWidth: '60px' }}>
                                <Nav.Link eventKey="swap" className="d-flex row justify-content-center">
                                    <IoIosSwap size={20} style={{ marginBottom: '3px', color: '#f7f7f7!important', padding: '0' }} />
                                    Swap
                                </Nav.Link>
                            </Nav.Item>
                        </Col>
                        <Col style={{ color: '#f7f7f7' }} className="d-flex row justify-content-center">
                            <Nav.Item className="active" style={{ maxWidth: '60px' }}>
                                <Nav.Link eventKey="trades" className="d-flex row justify-content-center">
                                    <CgViewList size={20} style={{ marginBottom: '3px', color: '#f7f7f7!important', padding: '0' }} />
                                    Trades
                                </Nav.Link>
                            </Nav.Item>
                        </Col>
                        <Col style={{ color: '#f7f7f7' }} className="d-flex row justify-content-center">
                            <Nav.Item className="active" style={{ maxWidth: '60px' }}>
                                <Nav.Link eventKey="mytrades" className="p-2 d-inline-flex row justify-content-center">
                                    {/* <VscTelescope size={20} style={{ marginBottom: '3px', color: '#f7f7f7!important', padding: '0' }} /> */}
                                    <CgUserList size={20} style={{ marginBottom: '3px', color: '#f7f7f7!important', padding: '0' }} />
                                    Position
                                </Nav.Link>
                            </Nav.Item>
                        </Col>
                        {/* <Col style={{ color: '#f7f7f7' }} className="d-flex row justify-content-center">
                            <Nav.Item className="active" style={{ maxWidth: '60px' }}>
                                <Nav.Link eventKey="more" className="d-flex row justify-content-center">
                                    <IoIosMore size={20} style={{ marginBottom: '3px', color: '#f7f7f7!important' }} />
                                    Whales
                                </Nav.Link>
                            </Nav.Item>
                        </Col> */}
                        <Col style={{ color: '#f7f7f7' }} className="d-flex row justify-content-center">
                            <Nav.Item className="active" style={{ maxWidth: '60px' }}>
                                <Nav.Link eventKey="more" className="d-flex row justify-content-center">
                                    <IoIosMore size={20} style={{ marginBottom: '3px', color: '#f7f7f7!important', padding: '0' }} />
                                    More
                                </Nav.Link>
                            </Nav.Item>
                        </Col>
                    </Nav>
                </div>
                <Tab.Content style={{ height: '(50%)', minHeight: '42vh', marginBottom: '42px' }}>
                    <Tab.Pane eventKey="swap" className="pl-2 pr-2">
                        <TradingWindow />
                    </Tab.Pane>
                    <Tab.Pane eventKey="trades">
                        <Transaction />
                    </Tab.Pane>
                    <Tab.Pane eventKey="mytrades">
                        <WalletTransactions trackAddress={selectedTrackAddress} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="more" className="pl-2 pr-2">
                        <PriceCalculator />
                        {Vote ? <Vote /> : <DisplayVote />}
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </div>
    );
}

export default CryptoOverviewMobile;
