import React, { useEffect, useState } from 'react';
import { Col, Row, Form, Button, Dropdown, Image, Offcanvas, CloseButton } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import '../../src/index.scss';

// pages

// assets
import * as BiIcons from 'react-icons/bi';
import { IconContext } from 'react-icons';
import { RiHistoryFill } from 'react-icons/ri';
import { BsLayoutSidebarReverse } from 'react-icons/bs';
import { HiHeart } from 'react-icons/hi';
import FeaturedTokens from './FeaturedTokens';
import FULLSCREEN_LAYOUT from '../assets/img/layout_fs_PDX.svg';
import SIDEBARLEFT_LAYOUT from '../assets/img/layout_sl_PDX.svg';
import SIDEBARRIGHT_LAYOUT from '../assets/img/layout_sr_PDX.svg';

//
import { addTokenToMoralis, getDecimals, searchToken } from '../shared/commonUtils';
import { useStoreApi } from '../store/storeApi';
import { Favourites } from './Favourites';
import AutoComplete from './AutoCompleateTextBox/AutoComplete';
import { RecentlyViewed } from './RecentlyViewed';

const SearchBar = (props) => {
    const history = useHistory();
    const [tokenResponse, setTokenResponse] = useState([]);
    const [userInput, setUserInput] = useState('');
    const { setTokenAddress, setToken, tradeleft, trade, setTrade, setTradeLeft, setLayout } = useStoreApi([]);

    const getSuggestions = async (word) => {
        const response = await searchToken(word);
        if (response && response.length) {
            setTokenResponse(response);
        } else {
            setTokenResponse(response);
            const pattern = /0x/i;
            if (word.match(pattern)) {
                const apiResponse = await getDecimals(word, true);
                if (apiResponse) {
                    const result = [
                        {
                            label: apiResponse.name,
                            value: apiResponse.address,
                            symbol: apiResponse.symbol,
                            desc: apiResponse.name,
                            isNew: true,
                            logo: 0
                        }
                    ];
                    setTokenResponse(result);
                    addTokenToMoralis(apiResponse, 1, 0);
                }
            }
        }
    };

    const selectToken = async (item) => {
        setTokenAddress(item.value);
        const { typeval } = item;
        const selectedToken = item.symbol;
        const selectedAddress = item.value;
        setTokenAddress(selectedAddress);
        setToken(selectedToken);
        setUserInput('');
        history.push(`/token/${selectedAddress}/${selectedToken}`);
    };

    // Tradeview swicher
    const enableDisableTrade = () => {
        const checkbox = document.getElementById('trade');
        setTrade(checkbox.checked);
    };

    // Tradeview swicher
    const enableDisableLeftTrade = () => {
        const checkbox = document.getElementById('tradeleft');
        setTradeLeft(checkbox.checked);
    };

    // Offcanvas for favourites
    const [showFavs, setShowFavs] = useState(false);
    const handleCloseFavs = () => setShowFavs(false);
    const handleShowFavs = () => setShowFavs(true);

    // Offcanvas for recently viewed tokens
    const [showRecents, setShowRecents] = useState(false);
    const handleCloseRecents = () => setShowRecents(false);
    const handleShowRecents = () => setShowRecents(true);

    const changeLayout = (type) => {
        setLayout(type);
        if (type == 'right' || type == 'left') {
            setTrade(true);
        } else {
            setTrade(false);
        }
    };

    const handleClose = () => {
        console.log('heresdasfdasf');
    };

    return (
        <div>
            <div className="d-flex align-items-center trendingbar">
                <Row className="form-group has-search m-0">
                    {/* <Col md={7} className="d-flex align-items-center pr-1 pl-2 hideOnMobile">
                        <FeaturedTokens />
                    </Col> */}
                    <Col
                        md={12}
                        className="p-0 m-0 d-flex"
                        style={{
                            backgroundColor: 'transparent',
                            color: '#6C727F'
                        }}
                    >
                        {!props.home ? (
                            <Dropdown>
                                <Dropdown.Toggle
                                    variant="fav"
                                    className="hideOnMobile searchbar-button"
                                    style={{
                                        width: 'auto',
                                        color: 'var(--text-base)',
                                        height: '25px',
                                        backgroundColor: 'var(--background-base)',
                                        borderRadius: '8px',
                                        fontSize: '12px',
                                        lineHeight: 1,
                                        marginTop: '1px',
                                        marginRight: '-3px',
                                        marginLeft: '4px'
                                    }}
                                >
                                    <BsLayoutSidebarReverse
                                        size={17}
                                        style={{
                                            marginTop: '-9px',
                                            marginLeft: '-5px',
                                            marginRight: '5px'
                                        }}
                                    />{' '}
                                    Layout
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                    style={{
                                        backgroundColor: 'rgb(22, 28, 41)',
                                        boxShadow: 'none',
                                        marginTop: '8px',
                                        borderRadius: '8px',
                                        border: 'none',
                                        width: '198px'
                                    }}
                                >
                                    <Dropdown.Item style={{ color: '#959cb2' }}>
                                        <Row className="d-flex align-items-center" onClick={() => changeLayout('full')}>
                                            <Col>
                                                <Form>
                                                    <Form.Check type="radio" id="trade" defaultChecked={tradeleft} label="Full width" />
                                                </Form>
                                            </Col>
                                            <Col className="d-flex justify-content-end">
                                                <Image src={FULLSCREEN_LAYOUT} height="42px" />
                                            </Col>
                                        </Row>
                                    </Dropdown.Item>
                                    <Dropdown.Item style={{ color: '#959cb2' }}>
                                        <Row className="d-flex align-items-center" onClick={() => changeLayout('right')}>
                                            <Col>
                                                <Form>
                                                    <Form.Check
                                                        type="radio"
                                                        // id={`default-${type}`}
                                                        label="Right sidebar"
                                                        checked={tradeleft}
                                                    />
                                                </Form>
                                            </Col>
                                            <Col className="d-flex justify-content-end">
                                                <Image src={SIDEBARLEFT_LAYOUT} height="42px" />
                                            </Col>
                                        </Row>
                                    </Dropdown.Item>
                                    <Dropdown.Item style={{ color: '#959cb2' }}>
                                        <Row className="d-flex align-items-center" onClick={() => changeLayout('middle')}>
                                            <Col>
                                                <Form>
                                                    <Form.Check
                                                        type="radio"
                                                        // id={`default-${type}`}
                                                        label="Middle"
                                                        checked={tradeleft}
                                                    />
                                                </Form>
                                            </Col>
                                            <Col className="d-flex justify-content-end">
                                                <Image src={SIDEBARLEFT_LAYOUT} height="42px" />
                                            </Col>
                                        </Row>
                                    </Dropdown.Item>
                                    <Dropdown.Item style={{ color: '#959cb2' }}>
                                        <Row className="d-flex align-items-center" onClick={() => changeLayout('left')}>
                                            <Col>
                                                <Form>
                                                    <Form.Check
                                                        type="radio"
                                                        // id={`default-${type}`}
                                                        label="Left sidebar"
                                                        // checked={tradeleft}
                                                        defaultChecked={tradeleft}
                                                        onChange={enableDisableTrade}
                                                    />
                                                </Form>
                                            </Col>
                                            <Col className="d-flex justify-content-end">
                                                <Image src={SIDEBARRIGHT_LAYOUT} height="42px" />
                                            </Col>
                                        </Row>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        ) : null}
                        <div className="input-group input-group-sm p-0 m-0">
                            <div className="input-group-prepend d-none d-md-block" />
                            <IconContext.Provider value={{ color: 'white', className: 'searchicon-token-bar' }}>
                                <BiIcons.BiSearch size={20} />
                            </IconContext.Provider>
                            <div className="col-md-12">
                                <AutoComplete
                                    suggestions={tokenResponse}
                                    placeholderText="Search by token name or paste address..."
                                    requests={getSuggestions}
                                    classProp="dex-search-bar"
                                    setToken={selectToken}
                                />
                            </div>
                        </div>

                        {!props.home ? (
                            <>
                                <Button
                                    variant="fav"
                                    className="searchbar-button"
                                    onClick={handleShowRecents}
                                    style={{
                                        width: 'auto',
                                        height: '25px',
                                        backgroundColor: 'var(--background-base)',
                                        borderRadius: '8px',
                                        fontSize: '12px',
                                        lineHeight: 1,
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 7,
                                        paddingRight: 7,
                                        marginTop: '1px',
                                        marginLeft: '-3px',
                                        marginRight: '5px'
                                    }}
                                >
                                    <IconContext.Provider value={{ color: 'white' }}>
                                        <HiHeart
                                            className="meow"
                                            size={20}
                                            color="var(--text-base)"
                                            style={{
                                                padding: 0,
                                                margin: 0
                                            }}
                                        />
                                    </IconContext.Provider>
                                </Button>
                                <Offcanvas
                                    show={showRecents}
                                    onHide={handleCloseRecents}
                                    placement="end"
                                    backdrop="true"
                                    style={{ width: '310px' }}
                                >
                                    <Offcanvas.Header>
                                        <Offcanvas.Title className="text-muted font-weight-normal">Favourites</Offcanvas.Title>
                                        <CloseButton variant="white" onClick={handleCloseRecents} />
                                    </Offcanvas.Header>
                                    <Offcanvas.Body>
                                        <Favourites />
                                    </Offcanvas.Body>
                                </Offcanvas>
                                <Button
                                    variant="fav"
                                    className="searchbar-button"
                                    onClick={handleShowFavs}
                                    style={{
                                        width: 'auto',
                                        // border: "1px solid #959cb250",
                                        height: '25px',
                                        backgroundColor: 'var(--background-base)',
                                        borderRadius: '8px',
                                        fontSize: '12px',
                                        lineHeight: 1,
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 7,
                                        paddingRight: 7,
                                        // textTransform: "uppercase",
                                        marginTop: '1px',
                                        marginRight: '3px',
                                        marginLeft: '0px'
                                    }}
                                >
                                    <IconContext.Provider value={{ color: 'white' }}>
                                        <RiHistoryFill
                                            className="meow"
                                            size={20}
                                            color="var(--text-base)"
                                            style={{
                                                padding: 0,
                                                margin: 0
                                            }}
                                        />
                                    </IconContext.Provider>
                                </Button>
                                <Offcanvas
                                    show={showFavs}
                                    onHide={handleCloseFavs}
                                    placement="end"
                                    style={{ width: '310px' }}
                                    scroll="true"
                                >
                                    <Offcanvas.Header>
                                        <Offcanvas.Title className="text-muted font-weight-normal">Recently viewed</Offcanvas.Title>
                                        <CloseButton variant="white" onClick={handleCloseFavs} />
                                    </Offcanvas.Header>
                                    <Offcanvas.Body>
                                        <RecentlyViewed />
                                    </Offcanvas.Body>
                                </Offcanvas>
                            </>
                        ) : null}
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default SearchBar;
