import React, { useEffect, useState } from 'react';
import { Col, Modal, OverlayTrigger, Tooltip, Tab, Nav } from 'react-bootstrap';
import Web3 from 'web3';
import { useStoreApi } from '../../store/storeApi';
import Autocomplete from '../AutoCompleateTextBox/AutoCompleateInput';
import '../../Styles/modal-popup.scss';
import { SWAP_TOKEN_BY_QUOTE } from '../../shared/ApiURL';
import { getDecimals, DisplayErrorMsg, getAllowance, searchToken, addTokenToMoralis, check24hVote } from '../../shared/commonUtils';
import IERC20 from '../../abis/IERC20.json';
import SearchToken from '../AutoCompleateTextBox/SearchToken';
import { TradingWindow } from './Trade/TradingWindow';
import WalletWidget from '../WalletWidget';

export default function TokenInfo() {
    // const [sellToken, setSellToken] = useState('BNB');
    // const [buyToken, setBuyToken] = useState('PDX');
    // const [quoteData, setQuoteData] = useState();
    // const [errorMsg, setErrorMsg] = useState('');
    // const [showModal, setShowModal] = useState(false);
    // const [btnString, setBtnString] = useState('CONNECT TO METAMASK');
    // const [tokenResponse, setTokenResponse] = useState([]);
    // const [fromSearch, setFromSearch] = useState(false);
    // const [toSearch, setToSearch] = useState(false);
    // const [swap, setSwap] = useState(false);
    // const [fromValue, setFromValue] = useState('');
    // const [toValue, setToValue] = useState('');
    // const [fromDecimal, setFromDecimal] = useState(18);
    // const [toDecimal, setToDecimal] = useState(9);
    // const [fromTokenBalance, setFromTokenBalance] = useState(0);
    // const [toTokenBalance, setToTokenBalance] = useState(0);
    // const [isLoading, setIsLoading] = useState(false);
    // const [allowance, setAllowance] = useState(0);
    // const [showInfo, setShowInfo] = useState(false);

    // const {
    //     allTokens,
    //     fromSearchTokenAddress,
    //     metamaskStatus,
    //     toSearchTokenAddress,
    //     setFromSearchTokenAddress,
    //     setToSearchTokenAddress,
    //     address,
    //     tokenAddress
    // } = useStoreApi();

    // useEffect(() => {
    //     if (metamaskStatus == 'CONNECTED') {
    //         if (sellToken === 'BNB') {
    //             setBtnString('SWAP');
    //         } else {
    //             setBtnString('APPROVE');
    //         }
    //         if (allTokens) {
    //             const fromFilter = allTokens.filter((ele) => ele.symbol == sellToken);
    //             const toFilter = allTokens.filter((ele) => ele.symbol == buyToken);
    //             if (fromFilter.length > 0) {
    //                 setFromTokenBalance(parseFloat(fromFilter[0].quantity).toFixed(2));
    //             } else {
    //                 setFromTokenBalance(0);
    //             }

    //             if (toFilter.length > 0) {
    //                 setToTokenBalance(parseFloat(toFilter[0].quantity).toFixed(2));
    //             } else {
    //                 setToTokenBalance(0);
    //             }
    //         }
    //     }
    // }, [metamaskStatus, allTokens, sellToken, buyToken]);

    // useEffect(() => {
    //     if (sellToken !== 'BNB') {
    //         checkAllowanceApprove();
    //     }
    // }, [quoteData]);

    // const importToken = () => {
    //     setShowInfo(true);
    // };

    // const getTokenPrice = async (swap, sellVal, fromAddress, toAddress, type) => {
    //     const gasSpend = '5000000000';
    //     let amount;
    //     let decimals;
    //     let toAddr;
    //     let fromAddr;
    //     let slippage_percentage = document.getElementById('slippage_percentage').value;
    //     slippage_percentage = slippage_percentage < 1 ? 2 : slippage_percentage;
    //     if (type == 'to') {
    //         toAddr = fromAddress;
    //         fromAddr = toAddress;
    //         amount = document.getElementById('buy_amount').value;
    //         // console.log('inside===', amount);
    //     } else {
    //         toAddr = toAddress;
    //         fromAddr = fromAddress;
    //         amount = document.getElementById('sell_amount').value;
    //         console.log('outside===', amount);
    //     }
    //     if (amount != 0) {
    //         decimals = await getDecimals(fromAddr);
    //         amount *= 10 ** decimals;
    //         // console.log("amount===", amount);
    //         fetch(
    //             `${SWAP_TOKEN_BY_QUOTE}quote?toTokenAddress=${toAddr}&fromTokenAddress=${fromAddr}&amount=${amount}&slippagePercentage=${slippage_percentage}&includedSources=PancakeSwap,PancakeSwap_V2&gasPrice=${gasSpend}`
    //         ).then((response) =>
    //             response.json().then((data) => {
    //                 console.log('data===', data);
    //                 setTokenData(type, data, sellVal, decimals);
    //                 return data;
    //             })
    //         );
    //     }
    // };

    // const setTokenData = (type, data, sellVal, decimal) => {
    //     setFromDecimal(decimal);
    //     let frValue;
    //     setQuoteData(data);
    //     if (type === 'to') {
    //         frValue = data.toTokenAmount / 10 ** data.toToken.decimals;
    //         // console.log("frValue", frValue);
    //         setFromValue(frValue.toFixed(3));
    //         setToValue(sellVal);
    //     } else {
    //         frValue = data.toTokenAmount / 10 ** data.toToken.decimals;
    //         // console.log("toValue", frValue);
    //         setFromValue(sellVal);
    //         setToValue(frValue.toFixed(3));
    //     }
    // };

    // const setToken = async (item) => {
    //     console.log('inside select===', item, fromSearch);
    //     const { typeval } = item;
    //     const value = item.symbol;
    //     if (fromSearch) {
    //         setSellToken(value);
    //         setFromSearchTokenAddress(item.value);
    //         console.log('inside  item.value, toSearchTokenAddress===', item.value, toSearchTokenAddress);
    //         await getTokenPrice(swap, 1, item.value, toSearchTokenAddress, 'from');
    //     } else {
    //         setBuyToken(value);
    //         setToSearchTokenAddress(item.value);
    //         await getTokenPrice(swap, 1, fromSearchTokenAddress, item.value, 'to');
    //     }
    //     setShowModal(false);
    // };

    // const handleOnSearch = async (string) => {
    //     const response = await searchToken(string);
    //     console.log('response', response);
    //     if (response && response.length) {
    //         setTokenResponse(response);
    //     } else {
    //         setTokenResponse(response);
    //         const pattern = /0x/i;
    //         if (string.match(pattern)) {
    //             const apiResponse = await getDecimals(string, true);
    //             if (apiResponse) {
    //                 const result = [
    //                     {
    //                         label: apiResponse.name,
    //                         value: apiResponse.address,
    //                         symbol: apiResponse.symbol,
    //                         desc: apiResponse.name,
    //                         isNew: true,
    //                         logo: 0
    //                     }
    //                 ];
    //                 setTokenResponse(result);
    //                 addTokenToMoralis(apiResponse, 1, 0);
    //             }
    //         }
    //         // setTokenResponse(response);
    //     }
    // };

    // const checkAllowanceApprove = async () => {
    //     if (quoteData && address) {
    //         setIsLoading(true);
    //         const instance = await getEthInstance();
    //         instance.eth.defaultAccount = address;
    //         const IERC20_ABI = IERC20;
    //         const allowanceTarget = await getAllowance();
    //         console.log('allowanceTarget===', allowanceTarget);
    //         const contract = new instance.eth.Contract(IERC20_ABI, quoteData.fromToken.address);
    //         contract.methods.allowance(address, allowanceTarget.address).call({}, (error, result) => {
    //             if (error) {
    //                 setIsLoading(false);
    //             } else {
    //                 console.log('result==', result);
    //                 const str = result == '0' ? 'APPROVE' : 'SWAP';
    //                 setBtnString(str);
    //                 setAllowance(result);
    //                 setIsLoading(false);
    //             }
    //         });
    //     }
    // };

    // const getEthInstance = async () => {
    //     let instance;
    //     if (window.ethereum) {
    //         // set up a new provider
    //         try {
    //             instance = new Web3(window.ethereum);
    //         } catch (error) {
    //             console.error(error);
    //         }
    //     } else if (window.web3) {
    //         instance = new Web3(window.web3);
    //     } else {
    //         // fallback on localhost provider
    //         const provider = new Web3.providers.HttpProvider('https://bsc-dataseed3.binance.org');
    //         instance = new Web3(provider);
    //     }
    //     return instance;
    // };

    // const [alreadyVoted, setAlreadyVoted] = useState(false);

    // useEffect(() => {
    //     checkVoteStatus();
    // }, [address, tokenAddress]);

    // const [vote, setVote] = useState(null);
    // const checkVoteStatus = async () => {
    //     if (address && tokenAddress) {
    //         const res = await check24hVote(address, tokenAddress);
    //         if (res && res.length) {
    //             setVote(res[0]);
    //             setAlreadyVoted(true);
    //         } else {
    //             setAlreadyVoted(false);
    //         }
    //     } else {
    //         setAlreadyVoted(false);
    //     }
    // };

    return (
        <div className="col-2 mt-0 p-0 bg-darker-trade hideOnMobile">
            <Tab.Container defaultActiveKey="swap">
                <Nav variant="pills" style={{ paddingTop: '5px' }} className="pl-1">
                    <Nav.Item>
                        <Nav.Link eventKey="swap" className="mediumpill mr-1">
                            Swap
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="limit" className="mediumpill">
                            Limit
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                <Col
                    style={{
                        backgroundColor: 'var(--background-base)',
                        margin: '4px',
                        borderRadius: '7px',
                        width: '290px',
                        height: 'calc(100vh - 443px)'
                    }}
                >
                    <div style={{ width: '100%' }} className="m-0 p-0">
                        <Tab.Content className="pl-0 pr-0">
                            <Tab.Pane eventKey="swap">
                                <TradingWindow />
                            </Tab.Pane>
                            <Tab.Pane eventKey="limit">
                                <div>Coming soon</div>
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </Col>
            </Tab.Container>
            <div className="m-0 p-0" style={{ borderBottom: '0px solid var(--color-border-grey)' }}>
                <WalletWidget />
            </div>
            <div className="mb-0" />
            {/* <SearchTokenPopup
                show={showModal}
                onHide={() => setShowModal(!showModal)}
                tokenResponse={tokenResponse}
                handleOnSearch={handleOnSearch}
                setToken={setToken}
                onimport={importToken}
            />
            <SearchToken show={showInfo} onHide={() => setShowInfo(false)} /> */}
        </div>
    );
}

// function SearchTokenPopup(props) {
//     return (
//         <Modal {...props} dialogClassName="modal-50w" aria-labelledby="contained-modal-title-vcenter" centered>
//             <Modal.Body>
//                 <Autocomplete
//                     suggestions={props.tokenResponse}
//                     placeholderText="Search by Token symbol or address"
//                     handleOnSearch={props.handleOnSearch}
//                     setToken={props.setToken}
//                     onimport={props.onimport}
//                     classProp="auto-c-input"
//                 />
//                 <strong>Suggestions</strong>
//                 <br />
//                 {/* <Table size="lr">
//           <tbody className="suggestions">
//             <tr><img className="ti-search" src="https://s2.coinmarketcap.com/static/img/coins/200x200/8966.png" width="32px"></img>PDX (PDX)<span><Badge variant="info">Sponsored</Badge></span></tr>
//             <tr><img className="ti-search" src="https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/1024/Binance-Coin-BNB-icon.png" width="32px"></img>Binance Coin (BNB)</tr>
//             <tr><img className="ti-search" src="https://cryptologos.cc/logos/binance-usd-busd-logo.png" width="32px"></img>BUSD</tr>
//             <tr><img className="ti-search" src="https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/1024/Tether-USDT-icon.png" width="32px"></img>USDT</tr>
//             <tr><img className="ti-search" src="https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/1024/Bitcoin-BTC-icon.png" width="32px"></img>BTCB</tr>
//           </tbody>
//         </Table> */}
//             </Modal.Body>
//         </Modal>
//     );
// }
