import { getActivePairFromDB } from './commonUtils';

export const getHistoryQuery = (days, tokenAddress) => {
    const till = new Date().toISOString();
    let since = new Date();
    since.setDate(since.getDate() - +days);
    since = since.toISOString();
    const count = {
        1: 1,
        7: 15,
        30: 90,
        90: 540
    };

    // console.log("getHistoryQuery", { days, since, till });

    return `{
    ethereum(network: bsc) {      
     dexTrades(
       options: {asc: "timeInterval.minute"}
       date: {since: "${since}", till: "${till}"}
       exchangeName: {in: ["Pancake", "Pancake v2"]}
       baseCurrency: {is: "${tokenAddress}"}
       quoteCurrency: {is: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c"} 
       # WBNB
       tradeAmountUsd: {gt: 1}
     ) {
       timeInterval {
         minute(count: ${count[days]}, format: "%Y-%m-%dT%H:%M:%SZ")
       }
       baseCurrency {
         name
         symbol
         address
         decimals
       }
       quoteCurrency {
         symbol
         address
       }
       trades: count
       quotePrice
       volume: quoteAmount
       maximum_price: quotePrice(calculate: maximum)
       minimum_price: quotePrice(calculate: minimum)
       open_price: minimum(of: block, get: quote_price)
       close_price: maximum(of: block, get: quote_price)
     }  
   }
  }`;
};

export const getCoinInfo = (tokenAddress) => `
{
  ethereum(network: bsc) {
    dexTrades(
      options: {desc: ["block.height", "transaction.index"], limit: 1}
      exchangeName: {in: ["Pancake", "Pancake v2"]}
      baseCurrency: {is: "${tokenAddress}"}
      quoteCurrency: {is: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c"} 
    ) 
    {
      block {
        height
        timestamp {
          time(format: "%Y-%m-%d %H:%M:%S") 
        }
      }
      transaction {
        index
      }
      baseCurrency {
        name
        symbol
        decimals
      }
      quotePrice
    }
  }
}
`;

// export const getTvChartQuery = (
//   till = new Date(),
//   tokenAddress,
//   interval,
//   since = new Date()
// ) => `
//   {
//     ethereum(network: bsc) {
//       dexTrades(
//         options: {limit: 600, asc: "timeInterval.minute"}
//         date: {since: "${since.toISOString()}", till: "${till.toISOString()}"}
//         exchangeName: {in: ["Pancake", "Pancake v2"]}
//         baseCurrency: {is: "${tokenAddress}"}
//         quoteCurrency: {is: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c"}
//         tradeAmountUsd: {gt:1}
//       )
//       {
//         timeInterval {
//           minute(count: ${interval}, format: "%Y-%m-%dT%H:%M:%SZ")
//         }
//       volume: quoteAmount
//       trades: count
//       quotePrice
//       maximum_price: quotePrice(calculate: maximum)
//       minimum_price: quotePrice(calculate: minimum)
//       open_price: minimum(of: block, get: quote_price)
//       close_price: maximum(of: block, get: quote_price)
//       }
//     }
//   }
//   `;

export const getTvChartQuery = (till = new Date(), tokenAddress, interval, since = new Date()) => {
    const activePair = getActivePairFromDB();
    console.log('activePair====>', activePair);
    return `{
    ethereum(network: bsc) {
      dexTrades(
        date: {since: "${since}", till: "${till}"}
        tradeAmountUsd: {gt: 1}
        exchangeAddress: {is: "0xca143ce32fe78f1f7019d7d551a6402fc5350c73"}
        any: [
          {
            baseCurrency: {is: "${activePair.token0}"}, 
            quoteCurrency: {is: "${activePair.token1}"}
          }]
      ) {
        timeInterval {
          minute(count: ${interval})
        }
        buyCurrency: baseCurrency {
          symbol
          address
        }
        sellCurrency: quoteCurrency {
          symbol
          address
        }
        
        volume: quoteAmount
        
        maximum: quotePrice(calculate: maximum)
        minimum: quotePrice(calculate: minimum)
        open: minimum(of: block, get: quote_price)
        close: maximum(of: block, get: quote_price)
      }
    }
  }`;
};
