import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, getDatasetAtEvent } from 'react-chartjs-2';
import { Card, Col, Row } from 'react-bootstrap';
import { useStoreApi } from '../../store/storeApi';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


export default function GainLossWallet() {
  const { walletTokenData } = useStoreApi();
  const [barData, setBarData] = useState(null);
  useEffect(() => {
    if (walletTokenData && walletTokenData.length > 0) {
      getData();
    }
  }, [walletTokenData]);

  const barOptions = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false
      },
    },
  };


  const getData = () => {
    const days = [];
    const result = walletTokenData.map(function (x, i) {
      if (walletTokenData[i + 1]) {
        days.push(walletTokenData[i + 1].daywithnum)
        return walletTokenData[i + 1].balance - x.balance 
      }
    }).filter(Number);
    const bgColor = result.map((value) => value < 0 ? 'rgb(237, 110, 113, .5)' : 'rgba(101, 208, 162, 0.5)');
    const borderColor = result.map((value) => value < 0 ? 'rgb(237, 110, 113, 1)' : 'rgb(101, 208, 162, 1)');

    setBarData({
      labels: days,
      datasets: [
        {
          label: 'Gain/Loss (USD)',
          data: result,
          borderColor: borderColor,
          backgroundColor: bgColor,
        },
      ],
    });
  }




  return (
    <div>
      <Card
        style={{ backgroundColor: 'rgb(31, 34, 48)', boxShadow: 'none', border: '1px solid rgb(41, 45, 64)', borderRadius: '20px' }}
        className="p-4"
      >
        <Row>
          <Col sm={12}>
            <h5 className="text-center font-weight-normal" >Daily PNL</h5>
            <p className="text-center text-muted font-weight-normal">Entire wallet</p>
            {
              barData ?
              <Bar options={barOptions} data={barData} />
              : null
            }
          </Col>
        </Row>
      </Card>
    </div>
  )
}
