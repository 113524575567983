import React, { useEffect, useState } from 'react';
import ContractCheckup from './ContractCheckup';
import ProjectDescription from '../ProjectDescription';
import TokenProfile from './TokenProfile';
import TokenStats from './TokenStats';
import TokenLinks from './TokenLinks';
import TokenDetails from './TokenDetails';
import MonthPerformance from './MonthPerformance';
import { useStoreApi } from '../../store/storeApi';

const Title = ({ title }) => (
    <h6 className="mt-3 pl-3 mb-1" style={{ color: 'var(--text-primary)' }}>
        {title}
    </h6>
);

function RightSidebar() {
    return (
        <div className="r-sidebar-div">
            <div style={{ overflow: 'scroll' }}>
                <Title title="Quick info" />
                <ProjectDescription />
                <TokenLinks />
                <Title title="Token metrics" />
                <TokenStats />
                <Title title="Pair metrics" />
                <TokenDetails />
                <Title title="Rugcheck" />
                <ContractCheckup />
            </div>
        </div>
    );
}

export default RightSidebar;

// 🖖 This stuff for later
// function ScoreIndicator({ value, maxValue }) {
//     const val = (value / maxValue) * 100;
//     const deg = (180 / 100) * val;
//     console.log(Math.round(val), 'val');
//     return (
//         <div className="d-flex justify-content-center">
//             <div className="indicator">
//                 <span className="bar" style={{ transform: `rotate(${deg}deg)` }} />
//                 <span className="result">
//                     <span>{`${Math.round(val)} %`}</span>
//                 </span>
//             </div>
//         </div>
//     );
// }
