import { Container, Row, Col, ListGroup, Nav, Tab } from 'react-bootstrap';
import useSWR from 'swr';
import * as BiIcons from 'react-icons/bi';
import { useEffect, useState } from 'react';
import { useStoreApi } from '../../store/storeApi';
import {
    addToWishlist,
    getWatchList,
    removeToWishlist,
    numberWithCommas,
    DisplayErrorMsg,
    getToFixed,
    numFormatter
} from '../../shared/commonUtils';
import MinMaxPrice from './RightSidebar/MinMaxPrice';
import { DATA_SERVICE_URL } from '../../shared/ApiURL';

export default function TokenDetails(props) {
    const { activePair, marketcap } = useStoreApi();

    // const addRemoveToWatchList = () => {
    //     if (metamaskStatus == 'CONNECTED') {
    //         const tokenObj = {
    //             symbol: token,
    //             address: tokenAddress,
    //             percentchange: '-'
    //         };
    //         if (isAdded) {
    //             removeToWishlist(tokenAddress);
    //             setIsAdded(false);
    //         } else {
    //             addToWishlist(tokenObj);
    //             setIsAdded(true);
    //         }
    //         const updateList = getWatchList();
    //         setWatchList(updateList);
    //     } else {
    //         DisplayErrorMsg('Please connect to use this feature');
    //     }
    // };

    // const copyTokenAddress = () => {
    //     setIsCopied(true);
    //     navigator.clipboard.writeText(tokenAddress);
    //     setTimeout(() => {
    //         setIsCopied(false);
    //     }, 1000);
    // };

    // useEffect(() => {
    //     const watchList = getWatchList();
    //     const alreadyAdded = watchList.filter((obj) => obj.address === tokenAddress);
    //     if (alreadyAdded.length) {
    //         setIsAdded(true);
    //     } else {
    //         setIsAdded(false);
    //     }
    // }, [props.show]);

    const getPooledToken = (currentToken) => {
        if (activePair.dex !== 'nullswap') {
            let pooledValue = activePair[[activePair[currentToken]]].liquidityInUsd?.toFixed(2);
            pooledValue = numFormatter(pooledValue);
            return (
                <div>
                    {numFormatter(parseFloat(activePair[[activePair[currentToken]]].liquidity).toFixed(2))}{' '}
                    <span style={{ color: 'var(--text-green)' }}>(${pooledValue})</span>
                </div>
            );
        }
        return 'Loading...';
    };

    return (
        <Container fluid className="py-0 tokendetails" style={{ paddingRight: '25px', overflowX: 'hidden' }}>
            <Row className="mt-0 pt-0 pb-1 tokendetailsrow">
                <Row className="p-0 m-0">
                    <MinMaxPrice />
                </Row>
                <ListGroup variant="dark" className="p-0">
                    <ListGroup.Item className="sidebar-listitem">
                        <div className="std-info">Exchange</div>
                        <div className="capitalize std-value" style={{ color: 'var(--text-base)' }}>
                            <img
                                alt="Project logo"
                                src={activePair.icon}
                                style={{ width: '17px', marginRight: '5px', marginTop: '-2px', marginLeft: '-3px' }}
                            />
                            {activePair.dex}
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="sidebar-listitem">
                        <div className="std-info">{activePair.symbol0} address</div>
                        <div
                            className="std-value"
                            onClick={() => navigator.clipboard.writeText(activePair.token0)}
                            style={{ cursor: 'pointer' }}
                        >
                            {activePair.token0 != null
                                ? `${(activePair.token0 || '').slice(0, 6)}...${(activePair.token0 || '').slice(-4)}`
                                : 'Meow'}
                            <BiIcons.BiCopy style={{ marginTop: '-9px', color: 'var(--text-base)', marginLeft: '3px' }} />
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="sidebar-listitem">
                        <div className="std-info">{activePair.symbol1} address</div>
                        <div
                            className="std-value"
                            onClick={() => navigator.clipboard.writeText(activePair.token1)}
                            style={{ cursor: 'pointer' }}
                        >
                            {activePair.token1 != null
                                ? `${(activePair.token1 || '').slice(0, 6)}...${(activePair.token1 || '').slice(-4)}`
                                : 'Meow'}
                            <BiIcons.BiCopy style={{ marginTop: '-9px', color: 'var(--text-base)', marginLeft: '3px' }} />
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="sidebar-listitem">
                        <div className="std-info">Pair address</div>
                        <div
                            className="std-value"
                            style={{ cursor: 'pointer' }}
                            onClick={() => navigator.clipboard.writeText(activePair.pair.address)}
                        >
                            {(activePair.pair.address || '').slice(0, 6)}...{(activePair.pair.address || '').slice(-4)}
                            <BiIcons.BiCopy style={{ marginTop: '-9px', color: 'var(--text-base)', marginLeft: '3px' }} />
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="sidebar-listitem">
                        <div className="std-info">Pooled {activePair.symbol1}</div>
                        <div className="std-value">{getPooledToken('token1')}</div>
                    </ListGroup.Item>
                    <ListGroup.Item className="sidebar-listitem">
                        <div className="std-info">Pooled {activePair.symbol0}</div>
                        <div className="d-flex std-value">{getPooledToken('token0')}</div>
                    </ListGroup.Item>
                    <ListGroup.Item className="sidebar-listitem">
                        <div className="std-info">LP/MC ratio</div>
                        <div style={{ color: 'var(--text-green)' }} className="d-flex std-value">
                            {activePair.dex !== 'nullswap'
                                ? `${((Math.round(activePair.totalLiquidity) / Math.round(marketcap)) * 100).toFixed(2)}%`
                                : 'Loading...'}
                        </div>
                    </ListGroup.Item>
                </ListGroup>
            </Row>
        </Container>
    );
}
