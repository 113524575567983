import { useEffect, useState } from 'react';
import { Button, Col, ListGroup, Row } from 'react-bootstrap';
import { FiTrash, FiEdit2 } from 'react-icons/fi';
import { getWatchList, getWatchListTokensDetails } from '../shared/commonUtils';
import { CDN_URL } from '../shared/ApiURL';
import { useStoreApi } from './../store/storeApi';

export const Favourites = () => {
    const [updatedList, setUpdatedList] = useState();
    const { metamaskConnected, metamaskStatus, getTokenPriceDetails, watchList } = useStoreApi();

    useEffect(() => {
        console.log('watchList==>', watchList);
        getWatchListTokensDetails();
    }, [watchList]);

    const getWatchListTokensDetails = async () => {
        const watchListTokens = getWatchList();
        setUpdatedList(watchListTokens);
        if (watchListTokens || [].length > 0) {
            const result = await getTokenPriceDetails(watchListTokens);
            setUpdatedList(result);
        }
    };

    const getHistorySpanClass = (value) => {
        if (value) {
            return Math.sign(value) == 1 ? 'historyspan cbuy' : 'historyspan csell';
        }
        return '';
    };

    const getSign = (value) => {
        if (value) {
            return Math.sign(value) == 1 ? '+' : '-';
        }
        return '';
    };

    const renderBody = () => {
        if (metamaskStatus != 'CONNECTED') {
            return (
                <div
                    className="pl-1"
                    key={1}
                    style={{
                        color: 'var(--text-base)',
                        marginBottom: '6px',
                        borderRadius: '12px'
                    }}
                    action
                >
                    Connect to add favourites
                </div>
            );
        }
        return (
            updatedList &&
            updatedList.map((item, index) => {
                return (
                    <ListGroup.Item
                        key={index}
                        style={{
                            backgroundColor: 'var(--background-lighter)',
                            color: 'var(--text-base)',
                            marginBottom: '10px',
                            borderRadius: '12px'
                        }}
                        action
                    >
                        <Row className="d-flex align-content-center">
                            <Col xs={6}>
                                {item.name}({item.symbol})
                            </Col>
                            <Col className="d-flex" xs={5}>
                                <span className="text-muted">${item.price}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <span className={getHistorySpanClass(item.percentchange)}>
                                    {getSign(item.percentchange)}
                                    {item.percentchange != '-' ? `${item.percentchange}%` : '-'}
                                </span>
                            </Col>
                            <Col xs={1}></Col>
                        </Row>
                    </ListGroup.Item>
                );
            })
        );
    };
    return (
        <div>
            <Col className="p-0">
                <ListGroup style={{ borderRadius: '12px', marginBottom: '20px' }}>{renderBody()}</ListGroup>
            </Col>
        </div>
    );
};
