import { Container, Row, ListGroup } from 'react-bootstrap';
import useSWR from 'swr';
import * as BiIcons from 'react-icons/bi';
import { useStoreApi } from '../../store/storeApi';
import { DATA_SERVICE_URL } from '../../shared/ApiURL';

const fetcher = (url) => fetch(url).then((r) => r.json());

export default function TokenStats() {
    const { tokenAddress, activePair, marketcap } = useStoreApi();
    const url = `${DATA_SERVICE_URL}/token-stats?token=${tokenAddress}`;
    const { data: stats, error } = useSWR(url, fetcher, { revalidateOnFocus: false });
    return (
        <Container fluid className="py-0 tokendetails" style={{ paddingRight: '25px' }}>
            <Row className="mt-0 pt-0 pb-1 tokendetailsrow">
                <ListGroup variant="dark" className="p-0">
                    <ListGroup.Item className="sidebar-listitem">
                        <div className="std-info">Token age</div>
                        <div className="std-value">{stats ? stats[0]?.age : 'Loading...'} days</div>
                    </ListGroup.Item>
                    <ListGroup.Item className="sidebar-listitem">
                        <div className="std-info">First transaction</div>
                        <div className="std-value">{stats ? stats[0]?.first_tx : 'Loading...'}</div>
                    </ListGroup.Item>
                    <ListGroup.Item className="sidebar-listitem">
                        <div className="std-info">Latest transaction</div>
                        <div className="std-value">{stats ? stats[0]?.last_tx : 'Loading...'}</div>
                    </ListGroup.Item>
                    <ListGroup.Item className="sidebar-listitem">
                        <div className="std-info">Average trade</div>
                        <div className="std-value">{stats ? `${stats[0]?.average.toFixed(2)} ${activePair.symbol0}` : 'Loading...'}</div>
                    </ListGroup.Item>
                    <ListGroup.Item className="sidebar-listitem">
                        <div className="std-info">Median trade</div>
                        <div className="std-value">{stats ? `${stats[0]?.median.toFixed(2)} ${activePair.symbol0}` : 'Loading...'}</div>
                    </ListGroup.Item>
                    <ListGroup.Item className="sidebar-listitem">
                        <div className="std-info">Total trades</div>
                        <div className="std-value">{stats ? `${stats[0]?.transaction} txs` : 'Loading...'}</div>
                    </ListGroup.Item>
                </ListGroup>
            </Row>
        </Container>
    );
}
