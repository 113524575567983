import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FaRegComment, FaRegHeart, FaRetweet } from 'react-icons/fa';
import { DATA_SERVICE_URL } from '../shared/ApiURL';
import { useStoreApi } from '../store/storeApi';

function TwitterTimeline() {
    const [timeline, setTimeline] = useState();
    const [cancel, setCancel] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const { tokenAddress } = useStoreApi();

    const getTwitterData = async () => {
        if (cancel) return;
        const url = `${DATA_SERVICE_URL}/get-tweets-by-id`;
        fetch(url)
            .then((res) => res.json())
            .then((data) => {
                setIsLoading(false);
                if (data) {
                    setTimeline(data);
                }
            })
            .catch((err) => {
                // setIsLoading(false);
                console.log(err);
            });
    };

    useEffect(() => {
        getTwitterData();
        return () => {
            setCancel(true);
        };
    }, [tokenAddress]);

    // this loops but when i wrap it in a useEffect things are left undefined...
    const tweetRenderer = timeline?.data.map((tweet) => {
        const tweetDate = new Date(tweet.created_at);

        // console.log(tweetDate);
        // str = str.replace(re, function(matched){
        //     return mapObj[matched.toLowerCase()];

        return (
            <Card
                key={tweet.id}
                className="tweet-card"
                style={{
                    width: '100%',
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    border: '0px solid var(--color-border-grey)'
                }}
            >
                <Card.Body>
                    <Row>
                        <Col sm={2}>
                            {timeline.includes?.users.map((k) => (
                                <span className="tweet-author-logo" key={k.id}>
                                    {k.id === tweet.author_id ? (
                                        <img
                                            alt="logo"
                                            width="38"
                                            height="38"
                                            style={{ borderRadius: '100px' }}
                                            src={k.profile_image_url}
                                        />
                                    ) : null}
                                </span>
                            ))}
                        </Col>
                        <Col sm={10}>
                            <Card.Title className="tweet-author-name">
                                {timeline?.includes.users.map((users) => (
                                    <span key={users.id}>{users.id === tweet.author_id ? users.name : null}</span>
                                ))}
                            </Card.Title>
                            <Card.Subtitle className="tweet-author-username" style={{ color: 'var(--text-base)' }}>
                                {tweetDate.toLocaleDateString()}, {tweetDate.toLocaleTimeString()}
                            </Card.Subtitle>
                        </Col>
                    </Row>
                    <Card.Text className="tweet-body pt-2 mb-2">
                        {/* {tweet.entities?.hashtags ||
                        tweet.entities?.mentions ||
                        tweet.entities?.urls ||
                        (tweet.entities?.cashtags && tweet.entities.length > 0)
                            ? Object.values(tweet.entities).map((values) => <pre>{JSON.stringify(values, null, 3)}</pre>)
                            : null} */}
                        {tweet.text}
                    </Card.Text>
                    {timeline?.includes?.tweets.map((referencedTweets) => (
                        <div className="p-0 m-0" key={referencedTweets.id}>
                            {tweet.referenced_tweets?.map((ref) => {
                                const refTweetDate = new Date(referencedTweets.created_at);
                                return (
                                    <div key={ref.id}>
                                        {ref.id === referencedTweets.id ? (
                                            <Card.Text className="tweet-reply-to-user mb-3 pb-2 pt-2">
                                                {/* <span style={{ fontSize: '10px' }}>REPLYING TO</span> */}
                                                {/* {timeline?.includes.users?.map((users) => (
                                                    <Col className="p-0 d-flex flex-row align-items-center" key={users.id}>
                                                        {users.id === referencedTweets.author_id ? (
                                                            <img
                                                                src={users.profile_image_url}
                                                                alt="logo"
                                                                className="tweet-reply-author-logo"
                                                            />
                                                        ) : null}
                                                        {users.id === referencedTweets.author_id ? (
                                                            <span className="tweet-reply-author-name mr-1">{users.name}</span>
                                                        ) : null}
                                                        {users.id === referencedTweets.author_id ? (
                                                            <small className="tweet-reply-author-username">
                                                                @{users.username} ·{' '}
                                                                {refTweetDate.toLocaleString('default', { month: 'long' })}{' '}
                                                                {refTweetDate.toLocaleString('default', { day: 'numeric' })}
                                                            </small>
                                                        ) : null}
                                                    </Col>
                                                ))} */}

                                                {referencedTweets.text}
                                            </Card.Text>
                                        ) : null}
                                    </div>
                                );
                            })}
                        </div>
                    ))}

                    {timeline.includes?.media?.map((referencedMedia) => (
                        <div key={referencedMedia.media_key}>
                            {tweet.attachments !== undefined && tweet.attachments.media_keys !== undefined ? (
                                <div>
                                    {tweet.attachments.media_keys[0] === referencedMedia.media_key ? (
                                        <img
                                            alt="Linked"
                                            src={referencedMedia.url}
                                            // height={referencedMedia.height}
                                            // width={referencedMedia.width}
                                            className="tweet-attachment-photo mb-3"
                                        />
                                    ) : null}
                                </div>
                            ) : null}
                        </div>
                    ))}

                    {/* {timeline?.includes.polls.map((poll) => (
                        <div key={poll.id}>
                            
                        </div>
                    )} */}
                    {/* {timeline.includes.polls} */}

                    <Card.Link className="twitter-card-engagement">
                        <FaRegComment size={15} style={{ marginTop: '-9px', marginRight: '6px' }} /> {tweet.public_metrics.reply_count}
                    </Card.Link>
                    <Card.Link className="twitter-card-engagement">
                        <FaRegHeart size={15} style={{ marginTop: '-9px', marginRight: '6px' }} /> {tweet.public_metrics.like_count}
                    </Card.Link>
                    <Card.Link className="twitter-card-engagement">
                        <FaRetweet size={15} style={{ marginTop: '-9px', marginRight: '6px' }} /> {tweet.public_metrics.retweet_count}
                    </Card.Link>
                </Card.Body>
            </Card>
        );
    });

    const tweets = isLoading ? <div className="">Loading...</div> : <div>{tweetRenderer}</div>;

    const TweetTimeline = (tweetRenderer) => {
        if (isLoading) {
            return <div className="">Loading...</div>;
        }
        return <div>{tweetRenderer}</div>;
    };

    return (
        <div>
            <div
                style={{
                    height: '261px',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    borderBottom: '1px solid var(--color-border-grey)',
                    backgroundColor: 'var(--background-base',
                    margin: '4px',
                    borderRadius: '7px'
                }}
            >
                {tweets}
            </div>
        </div>
    );
}

export default TwitterTimeline;
