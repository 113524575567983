import React, { useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { DisplayErrorMsg, submitYourVote } from '../../shared/commonUtils';
import { useStoreApi } from '../../store/storeApi';

export default function Vote(props) {
    const { address, tokenAddress, token } = useStoreApi();
    const [clicked, setClicked] = useState(false);

    const giveVote = async (voteType = 1) => {
        if (address) {
            setClicked(true);
            const voteTime = Math.floor(Date.now() / 1000);
            const obj = {
                voteType,
                address: address.toLowerCase(),
                tokenAddress: tokenAddress.toLowerCase(),
                voteTime
            };
            const result = await submitYourVote(obj);
            DisplayErrorMsg(result);
            props.checkstatus();
        } else {
            DisplayErrorMsg('Please connect your wallet to vote.');
        }
    };
    return (
        <Col xl={12}>
            <Row
                className="d-flex align-items-center votebar p-2"
                style={{
                    boxShadow: 'none',
                    borderRadius: '12px'
                    // marginTop: "12px",
                    // marginBottom: "12px",
                }}
            >
                <Col xl={12}>
                    <h5 className="display-6 text-center mt-1 mb-1">How do you feel about {token}?</h5>
                </Col>
                <Col xl={12} className="d-flex inline-block justify-content-center vote-scr">
                    <Button disabled={clicked} className="goodbad" onClick={() => giveVote(1)}>
                        👍
                    </Button>
                    <Button disabled={clicked} className="ml-2 goodbad" onClick={() => giveVote(0)}>
                        👎
                    </Button>
                </Col>
                <p className="text-muted text-center mt-1 mb-0">Vote to see community results</p>
            </Row>
        </Col>
    );
}
