import React, { useEffect, useState } from 'react';
import { useStoreApi } from '../store/storeApi';

const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <div className="text">
            {isReadMore ? text.slice(0, 65) : text}
            <span onClick={toggleReadMore} className="read-or-hide">
                {isReadMore ? ' Show more' : ' Show less'}
            </span>
        </div>
    );
};

const ProjectDescription = () => {
    const { productInfoResponse } = useStoreApi();

    return (
        <div className="container pl-3 pr-3">
            <div className="project-description" style={{ color: 'var(--text-base)', fontWeight: '400' }}>
                {productInfoResponse ? (
                    <ReadMore>{productInfoResponse.description}</ReadMore>
                ) : (
                    <div>
                        No description available for this project yet. <button className="update-info interactivehover">Update info</button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProjectDescription;
