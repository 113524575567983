import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Image, ListGroup, Table } from 'react-bootstrap';
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    defaults
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { DATA_SERVICE_URL } from '../shared/ApiURL';
import { useStoreApi } from '../store/storeApi';
import useSWR from 'swr';

const fetcher = (...args) => fetch(...args).then((res) => res.json());
let days = 30;

function VolumeChart() {
    ChartJS.register(LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, LineController, BarController);
    const { activePair } = useStoreApi();
    const [volumeHistory, setVolumeHistory] = useState(null);

    const getVolumeHistory = async () => {
        try {
            const currentDateTime = new Date();
            let sinceDay = await currentDateTime?.setDate(currentDateTime.getDate() - 30);
            sinceDay = new Date(sinceDay);
            let fromThisDate = sinceDay.toISOString();
            await activePair;
            const result = await fetch(
                `${DATA_SERVICE_URL}/volume-history?days=${days}&since=${fromThisDate}&token=${activePair.token0}`
            ).then((data) => data);
            const data = await result.json();
            setVolumeHistory(data);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getVolumeHistory();
    }, [days]);
    const dates = volumeHistory?.map((data) => new Date(data.timeInterval.day));
    const formatOptions = {
        weekday: 'narrow',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };
    const labels = dates?.map((data) => data.toLocaleString('en-US', formatOptions).split(' ').slice(1).join(' '));

    // Global settings
    defaults.font.size = 14;

    const data = {
        labels,
        datasets: [
            {
                tension: 0.42,
                type: 'line',
                yAxisID: 'yA',
                label: 'Trading volume',
                data: volumeHistory?.map((data) => data.volumeInUSD),
                pointRadius: 5,
                pointHoverRadius: 5,
                backgroundColor: '#171722',
                borderColor: 'rgb(63, 182, 139)'
            },
            {
                type: 'bar',
                yAxisID: 'yB',
                label: 'Transactions',
                data: volumeHistory?.map((data) => data.transactions),
                // backgroundColor: 'rgba(89, 115, 254, .20)'
                backgroundColor: 'rgba(89, 115, 254, .20)'
            }
        ]
    };

    const options = {
        animation: {
            duration: 700,
            easing: 'easeIn'
        },
        scales: {
            x: {
                display: true,
                ticks: {
                    align: 'inner',
                    type: 'time',
                    color: '#6f6e84',
                    autoSkip: true,
                    maxTicksLimit: 5,
                    maxRotation: 0
                    // callback: function (value, index, values) {
                    //     // labels.map((labels) => labels.toISOString());
                    //     return labels[value];
                    // },
                    // time: {
                    //     unit: 'day',
                    //     unitStepSize: 1,
                    //     displayFormats: {
                    //         day: 'MMM DD'
                    //     }
                    // }
                },
                grid: { display: false, borderColor: '#2d2d3c' }
            },
            yA: {
                type: 'linear',
                position: 'left',
                // grid: { display: false, borderColor: '#2d2d3c' },
                grid: { display: false, drawBorder: false },
                ticks: {
                    callback: function (value, index, values) {
                        return '$' + value;
                    },
                    color: '#6f6e84',
                    size: 14
                }
            },
            yB: {
                type: 'linear',
                position: 'right',
                grid: { color: '#2d2d3c', drawBorder: false, display: true },
                ticks: {
                    callback: function (label, index, labels) {
                        if (Math.floor(label) === label) {
                            return label;
                        }
                    },
                    color: '#6f6e84'
                }
            }
        },
        responsive: true,
        plugins: {
            tooltip: {
                usePointStyle: true,
                pointStyle: 'circle',
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.dataset.label === 'Trading volume') {
                            label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                        }
                        if (context.dataset.label === 'Transactions') {
                            label += context.parsed.y;
                        }
                        return label;
                    },
                    labelColor: function () {
                        return {
                            backgroundColor: 'violet',
                            borderWidth: 0,
                            borderDash: [2, 2],
                            borderRadius: 7
                        };
                    },
                    labelTextColor: function () {
                        return '#c3c2d4';
                    }
                }
            },
            legend: {
                position: 'top',
                labels: {
                    color: '#c3c2d4',
                    usePointStyle: true,
                    boxWidth: '12',
                    pointStyle: 'circle',
                    font: {
                        family: 'Ligurino',
                        size: 15
                    }
                }
            },
            title: {
                display: false,
                text: 'Volume history chart'
            }
        }
    };

    const [] = useState({
        labels: [],
        datasets: []
    });
    return <Line options={options} data={data} />;
}

export default function VolumeModal(props) {
    return (
        <Modal {...props} size={'xl'} animation={false} centered>
            <Modal.Header style={{}}>
                <Modal.Title style={{ fontSize: '16px', color: 'var(--text-muted)', fontWeight: 'normal' }}>
                    <Row className="d">
                        <Col>Volume history</Col>
                    </Row>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <VolumeChart />
            </Modal.Body>
        </Modal>
    );
}
